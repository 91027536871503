import React, { Component } from 'react'
import { withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'

import { Button, Typography, IconButton,
  FormControl, TextField, Grid, CircularProgress,
  Dialog, DialogTitle as MuiDialogTitle, DialogContent, MenuItem} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { API } from 'aws-amplify'

import ozzyOtter from '../../img/ozzy-01.png'
import ozzySadOtter from '../../img/ozzy-error-03.png'

import { AWSconfigure } from '../../common/Configure'

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "rgba(35,35,45,1)",
    paddingTop: 10,
    paddingBottom: 30
  },
  title: {
    color: "white"
  },
  subscribeSectionButton: {
    color: "white",
    borderColor: "white",
    padding:10,
    paddingRight: 40, paddingLeft: 40,
    borderRadius: 25,
    "&:hover": {
      color: "#ff8a65",
      borderColor: "#ff8a65",
    },
    transition: "color 0.5s ease, border-color 0.5s ease"
  },
  disabledSubscribeButton: {
    color: "#bdbdbd !important",
    borderColor: "#bdbdbd !important",
    background: "#424242 !important"
  },
  buttonProgress: {
    color: "#ffc050",
    position: 'relative',
    left: '-68px',
    marginLeft: -12,
    marginTop: 10,
  },

  // TextBoxes
  cssLabel: {
    color : '#eeeeee' // changes the lable color //#ff8a65
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': { // has something to do with the label when filling it out
      borderColor: `#ff8a65 !important`,
    }
  },
  cssFocused: {
    borderColor: '#ff8a65 !important'
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#ff8a65 !important'
  },

  // Select Hacks
  select: {
    "& .MuiOutlinedInput-input": {
      color: "green !important"
    },
    "& .MuiInputLabel-root": {
      color: "green !important"
    },
    "& .MuiSelect-root.MuiSelect-select .MuiOutlinedInput-notchedOutline": {
      borderColor: "green !important"
    },
  },
  selectNochedOutline: {
    borderWidth: '1px',
    borderColor: '#ff8a65 !important'
  },
  selectIcon: {
    fill: "white"
  },

  firstForTextGrid: {
    paddingRight: 10,
    paddingLeft: 10
  },

  // New Select
  selectRoot: {
    color: "white !important"
  }
})

const SM_SCREEN = 600
const ERR_MSG_TIMEOUT = 5000

const wrapperTheme = createMuiTheme({
  palette: {
    primary: { main: "#ff8a65" },
    secondary: { main: "#ff8a65" },
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class ContactUs extends Component {

  constructor(props) {
    super(props)
    this.state = {
      width: 0, height: 0,

      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      contactMethod: "",
      inquiryType: "",
      userMessage: "",

      errorMsgFirstName:"",
      errorMsgLastName:"",
      errorMsgEmail:"",
      errorMsgPhone:"",
      errorMsgContactMethod:"",
      errorMsgInquiryType:"",
      errorMsgUserMessage:"",
      errorMsgPopup:"",

      commentThankyouOpen: false,
      loading: false
    }

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({width: window.innerWidth, height: window.innerHeight})
  }

  closeCommentThankyou() { this.setState({commentThankyouOpen: false }) }
  openCommentThankyou() { this.setState({commentThankyouOpen: true}) }

  changeFirstName(event) { this.setState({firstName: event.target.value}) }
  changeLastName(event) { this.setState({lastName: event.target.value}) }
  changeEmail(event) { this.setState({email: event.target.value}) }
  changePhone(event) { this.setState({phone: event.target.value}) }
  changeContactMethod(event) { this.setState({contactMethod: event.target.value}) }
  changeInquiryType(event) { this.setState({inquiryType: event.target.value}) }
  changeMessage(event) { this.setState({userMessage: event.target.value}) }

  checkInputField() {
    let firstName = this.state.firstName
    let lastName = this.state.lastName
    let email = this.state.email
    let phone = this.state.phone

    // Check violations on firstName
    if (firstName === "" || firstName === null) {
      this.setState({errorMsgFirstName: "Name cannot be empty!"})
      setTimeout(() => { this.setState({errorMsgFirstName: ""}) }, ERR_MSG_TIMEOUT)
      return false
    } else {
      let format = /[`!@#$%^&*()_+=\[\]{};':"\\|,.<>\/?~]/;
      let containsSpecialChar = format.test(firstName)
      if (containsSpecialChar) {
        this.setState({errorMsgFirstName: "Please do not use special characters in your name!"})
        setTimeout(() => { this.setState({errorMsgFirstName: ""}) }, ERR_MSG_TIMEOUT)
        return false
      }
    }

    // Check violations on lastName
    if (lastName === "" || lastName === null) {
      this.setState({errorMsgLastName: "Last name cannot be empty!"})
      setTimeout(() => { this.setState({errorMsgLastName: ""}) }, ERR_MSG_TIMEOUT)
      return false
    } else {
      let format = /[`!@#$%^&*()_+=\[\]{};':"\\|,.<>\/?~]/;
      let containsSpecialChar = format.test(lastName)
      if (containsSpecialChar) {
        this.setState({errorMsgLastName: "Please do not use special characters in your name!"})
        setTimeout(() => { this.setState({errorMsgLastName: ""}) }, ERR_MSG_TIMEOUT)
        return false
      }
    }

    // Check violations on Email
    if (email === "" || email === null) {
      this.setState({errorMsgEmail: "Email cannot be empty!"})
      setTimeout(() => { this.setState({errorMsgEmail: ""}) }, ERR_MSG_TIMEOUT)
      return false
    } else {
      let format = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      let isEmailFormat = format.test(email)
      if (!isEmailFormat) {
        this.setState({errorMsgEmail: "Please use a valid Email!"})
        setTimeout(() => { this.setState({errorMsgEmail: ""}) }, ERR_MSG_TIMEOUT)
        return false
      }
    }

    if (phone === "" || phone === null) {
      this.setState({errorMsgPhone: "Email cannot be empty!"})
      setTimeout(() => { this.setState({errorMsgPhone: ""}) }, ERR_MSG_TIMEOUT)
      return false
    } else {
      let format = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
      let isPhoneNumberFormat = format.test(phone)
      if (!isPhoneNumberFormat){
        this.setState({errorMsgPhone: "Please use a valid Phone Number! ie. (xxx) xxx-xxxx"})
        setTimeout(() => { this.setState({errorMsgPhone: ""}) }, ERR_MSG_TIMEOUT)
        return false
      }
    }

    if (this.state.contactMethod === "" || this.state.contactMethod === null) {
      this.setState({errorMsgContactMethod: "Please choose one!"})
      setTimeout(() => { this.setState({errorMsgContactMethod: ""}) }, ERR_MSG_TIMEOUT)
      return false
    }

    if (this.state.inquiryType === "" || this.state.inquiryType === null) {
      this.setState({errorMsgInquiryType: "Please choose one!"})
      setTimeout(() => { this.setState({errorMsgInquiryType: ""}) }, ERR_MSG_TIMEOUT)
      return false
    }

    if (this.state.userMessage === "" || this.state.userMessage === null) {
      this.setState({errorMsgUserMessage: "You cannot submit an empty message!"})
      setTimeout(() => { this.setState({errorMsgUserMessage: ""}) }, ERR_MSG_TIMEOUT)
      return false
    }

    return true
  }

  async submitComment(){ 
    this.setState({loading: true})
    var isOK = this.checkInputField()

    if (isOK) {
      let params = {
        body: {
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          email: this.state.email,
          phone: this.state.phone,
          contactMethod: this.state.contactMethod,
          inquiryType: this.state.inquiryType,
          message: this.state.userMessage
        }
      }

      AWSconfigure("WEBAPP")
      let data = await API.post('oslynWebappRestV1', '/userinquiry', params)
      let hasError = false

      console.log(data)

      if ('error' in data) {
        if (typeof data.error === 'string' || data.error instanceof String) {
          this.setState({errorMsgPopup: data.error})
          hasError = true
        } else {
          let error = JSON.stringify(data.error).slice(1,-1)
          if (error) this.setState({errorMsgPopup: error})
          else this.setState({errorMsgPopup: "500: Unknown server error!"})
          hasError = TextTrackCue
        }
      } else { this.setState({errorMsgPopup:""}) }
      
      this.openCommentThankyou()
      if (!hasError)
        this.setState({
          firstName: "", lastName: "", email: "", phone: "", 
          contactMethod: "", inquiryType: "", userMessage: "",
          errorMsgFirstName: "", errorMsgLastName: "", errorMsgEmail: "", errorMsgPhone: "",
          errorMsgContactMethod: "", errorMsgInquiryType: "", errorMsgUserMessage: "",
          loading: false
        })
    } else {
      //this.setState({loading: false})
    }
  }

  render() {
    const { classes } = this.props

    return (<div className={classes.root}>
      <MuiThemeProvider theme={wrapperTheme}><div>
      <FormControl style={{width: "100%", paddingRight: this.state.width<SM_SCREEN?10:50, paddingLeft: this.state.width<SM_SCREEN?10:50}}>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6} className={classes.firstForTextGrid}>
            <TextField
              fullWidth
              id="firstName"
              label="First Name"
              color="secondary"
              className={classes.textField}
              value={this.state.firstName}
              onChange={this.changeFirstName.bind(this)}
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
                inputMode: "numeric",
                style: { color: "white" }
              }}
              error={this.state.errorMsgFirstName !== ""}
              helperText={this.state.errorMsgFirstName}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} className={classes.firstForTextGrid}>
          <TextField
              fullWidth
              id="lastName"
              label="Last Name"
              color="secondary"
              className={classes.textField}
              value={this.state.lastName}
              onChange={this.changeLastName.bind(this)}
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
                inputMode: "numeric",
                style: { color: "white" }
              }}
              error={this.state.errorMsgLastName !== ""}
              helperText={this.state.errorMsgLastName}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} className={classes.firstForTextGrid}>
            <TextField
              fullWidth
              id="ContactUsEmail"
              label="Email"
              color="secondary"
              className={classes.textField}
              value={this.state.email}
              onChange={this.changeEmail.bind(this)}
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
                inputMode: "numeric",
                style: { color: "white" }
              }}
              error={this.state.errorMsgEmail !== ""}
              helperText={this.state.errorMsgEmail}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} className={classes.firstForTextGrid}>
            <TextField
              fullWidth
              id="Phone"
              label="Phone Number"
              color="secondary"
              className={classes.textField}
              value={this.state.phone}
              onChange={this.changePhone.bind(this)}
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
                inputMode: "numeric",
                style: { color: "white" }
              }}
              error={this.state.errorMsgPhone !== ""}
              helperText={this.state.errorMsgPhone}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12} className={classes.firstForTextGrid}>
            <div style={{paddingTop:15}}>
              <TextField className={classes.select}
                id="demo-simple-select-outlined"
                value={this.state.contactMethod}
                onChange={this.changeContactMethod.bind(this)}
                label="Preferred Contact Method"
                variant="outlined"
                select
                fullWidth
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
                SelectProps={{
                  classes: {
                    icon: classes.selectIcon,
                    root: classes.selectRoot
                  }
                }}
                error={this.state.errorMsgContactMethod !== ""}
                helperText={this.state.errorMsgContactMethod}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={"email"}>Email</MenuItem>
                <MenuItem value={"phone"}>Phone</MenuItem>
              </TextField>
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={12} className={classes.firstForTextGrid}>
            <div style={{paddingTop:15}}>
              <TextField className={classes.select}
                id="demo-simple-select-outlined-2"
                value={this.state.inquiryType}
                onChange={this.changeInquiryType.bind(this)}
                label="Select the reason for your inquiry."
                variant="outlined"
                select
                fullWidth
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
                SelectProps={{
                  classes: {
                    icon: classes.selectIcon,
                    root: classes.selectRoot
                  }
                }}
                error={this.state.errorMsgInquiryType !== ""}
                helperText={this.state.errorMsgInquiryType}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={"technical"}>Technical Support</MenuItem>
                <MenuItem value={"feedback"}>Feedback</MenuItem>
                <MenuItem value={"other"}>Other</MenuItem>
              </TextField>
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={12} className={classes.firstForTextGrid}>
            <TextField
              fullWidth
              id="Comment"
              label="Message"
              color="secondary"
              className={classes.textField}
              value={this.state.userMessage}
              onChange={this.changeMessage.bind(this)}
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
                style: { color: "white" }
              }}
              rows="10"
              multiline={true}
              error={this.state.errorMsgUserMessage !== ""}
              helperText={this.state.errorMsgUserMessage}
            />
          </Grid>
        </Grid>
        </FormControl>
        <div style={{display:"flex", justifyContent:"center", paddingTop:50}}>
          <Button variant="outlined" color="primary" onClick={this.submitComment.bind(this)} 
          className={classes.subscribeSectionButton} disabled={this.state.loading}
          classes={{disabled: classes.disabledSubscribeButton}}>
            Submit
          </Button>
          {this.state.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </div>
      <Dialog onClose={this.closeCommentThankyou.bind(this)} aria-labelledby="customized-dialog-title" open={this.state.commentThankyouOpen} scroll="body">
        <DialogTitle id="customized-dialog-title" onClose={this.closeCommentThankyou.bind(this)}
          style={{display:"flex", justifyContent:"flex-end", backgroundColor: this.state.errorMsgPopup?"#ffc7a2":"#84f2e7"}}>
          {" "}
        </DialogTitle>
        <DialogContent style={{background: this.state.errorMsgPopup?"linear-gradient(#ffc7a2 0%, #fd7289 100%)":"linear-gradient(#84f2e7 0%, #3cd0c2 100%)", paddingBottom: 35}}> {/** #63e1ff */}
          <Typography gutterBottom style={{display: "flex", justifyContent: "center"}}>
            <img src={this.state.errorMsgPopup?ozzySadOtter:ozzyOtter} style={{width: this.state.width-200, maxWidth: 200, height: this.state.width-200, maxHeight: 200, position:"relative", 
                top: this.state.width<SM_SCREEN?0:-10}} alt="ozzy the otter" />
          </Typography>
          <Typography component="h1" variant="h2" align="center">
            {this.state.errorMsgPopup?"Opps, something's wrong!":"Submitted!"}
          </Typography>
          {!this.state.errorMsgPopup?
            <div>
              <Typography gutterBottom style={{textAlign:"center", paddingTop: 40}}>
                Thank you for your message! We will respond within 2-3 business days. 
              </Typography>
            </div>:
            <div>
              <Typography gutterBottom style={{textAlign:"center", paddingTop: 40}}>
                {this.state.errorMsgPopup}
              </Typography>
              <Typography gutterBottom style={{textAlign:"center"}}>
                Please contact us at <a href="mailto:support@oslyn.io">support@oslyn.io</a>
              </Typography>
            </div>
          }
        </DialogContent>
      </Dialog>
      </MuiThemeProvider>
    </div>)
  }
}

export default withStyles(styles, { withTheme: true })(ContactUs)