import React, { Component } from 'react'
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import { Grid, Button, IconButton, Container, Typography, CircularProgress,
  FormControl, RadioGroup, FormControlLabel, Radio, SvgIcon,
  DialogTitle as MuiDialogTitle, DialogContent, Dialog } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { API, graphqlOperation } from 'aws-amplify'

import ozzySad from '../../img/ozzy sad-02.png'
import oslynLogo from './../../img/logo-colour-dark.png'

import { AWSconfigure } from '../../common/Configure'

const styles = theme => ({
  rootBackground: {
    flexGrow: 1,
    background: "linear-gradient(0deg, rgba(35,35,45,1) 0%, #fff 5%, #fff 100%)",
    paddingTop: 200,
    paddingBottom: 200
  },
  root: {
    flexGrow: 1,
    backgroundColor: "rgba(101,31,255,1)"
  },
  title: {
    paddingTop: 30
  },
  checkSection: {

  },
  updatePreferenceButton: {
    border: "solid",
    borderWidth: 1,
    fontSize: 20,
    textTransform: "none",
    borderRadius: 30,
    
    paddingRight: 20,
    paddingLeft: 20,

    marginTop: 1,
    marginBottom: 1,
    transition: "color 0.2s ease, border-color 0.2s ease, background-color 0.2s ease",

    "&:hover": {
      borderColor: "#651fff",
      color: "#651fff",
      backgroundColor: "#f8f5ff",
    }
  },
  social: {
    marginRight: 15,
  },
  disabledUnsubscribeButton: {
    color: "#bdbdbd !important",
    borderColor: "#bdbdbd !important",
    background: "#424242 !important"
  },
  buttonProgress: {
    color: "#651fff",
    position: 'relative',
    left: '-78px',
    marginLeft: -12,
    marginTop: 10,
  },
})

const wrapperTheme = createMuiTheme({
  palette: {
    primary: { main: "#651fff" },
    secondary: { main: "#651fff" },
  }
});

function getUrlParameter(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(window.location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

function FacebookIcon (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 320 512">
      <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 
        0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/>
    </SvgIcon>
  )
}

function InstagramIcon (props) {
  return (
    <SvgIcon {...props}>
      <path d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 
        7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,
        4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,
        7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z" />
    </SvgIcon>
  )
}

function YoutubeIcon (props) {
  return (
    <SvgIcon {...props}>
      <path d="M10,15L15.19,12L10,9V15M21.56,7.17C21.69,7.64 21.78,8.27 21.84,9.07C21.91,9.87 21.94,10.56 21.94,11.16L22,12C22,
      14.19 21.84,15.8 21.56,16.83C21.31,17.73 20.73,18.31 19.83,18.56C19.36,18.69 18.5,18.78 17.18,18.84C15.88,18.91 14.69,
      18.94 13.59,18.94L12,19C7.81,19 5.2,18.84 4.17,18.56C3.27,18.31 2.69,17.73 2.44,16.83C2.31,16.36 2.22,15.73 2.16,14.93C2.09,
      14.13 2.06,13.44 2.06,12.84L2,12C2,9.81 2.16,8.2 2.44,7.17C2.69,6.27 3.27,5.69 4.17,5.44C4.64,5.31 5.5,5.22 6.82,5.16C8.12,
      5.09 9.31,5.06 10.41,5.06L12,5C16.19,5 18.8,5.16 19.83,5.44C20.73,5.69 21.31,6.27 21.56,7.17Z" />
    </SvgIcon>
  )
}

const SM_SCREEN = 600
const MD_SCREEN = 960

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class Unsubscribe extends Component {

  constructor(props) {
    super(props)
    this.state = {
      width: 0, height: 0,
      isOslynTeacher: props.isOslynTeacher || false,
      userChoice: null,

      loadingUser: true,
      userID: null,
      userName: "",

      errorMsg: "",
      successModal: false,
      successModalerrorMsg: "",
      loading: false
    }

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)

    console.log(window.location.search)
    let uid= getUrlParameter("u")
    if (uid) {
      console.log(window.location.pathname)
      let query = ""
      if (window.location.pathname === "/train/unsubscribe") {
        query = `
        query singleOslynTeacher {
          getOslynTeacher (
            id: "${uid}"
          ) {
            id name email isActive
          }
        }`
        this.setState({isOslynTeacher: true})
      } else if (window.location.pathname === "/privacy/unsubscribe") {
        query = `
        query singleUser {
          getBetaTester (
            id: "${uid}"
          ) {
            id name email subscribeProductUpdates subscribeProductOfferings
            android ios comment status accountHistory lastUpdate
          }      
        }`
        this.setState({isOslynTeacher: false})
      }

      async function callAWSApi(context) {
        AWSconfigure("WEBAPP")
        let data = await API.graphql(graphqlOperation(query))
        console.log(data)

        if (data){
          if (!context.state.isOslynTeacher)
            if (data.data.getBetaTester) {
              context.setState({userID: data.data.getBetaTester.id, userName: data.data.getBetaTester.name})
            } else context.setState({errorMsg: "We could not find your user ID in our system!"})
          else
          if (data.data.getOslynTeacher) {
            context.setState({userID: data.data.getOslynTeacher.id, userName: data.data.getOslynTeacher.name})
            console.log(data.data.getOslynTeacher.id)
            console.log(data.data.getOslynTeacher.name)
          } else context.setState({errorMsg: "We could not find your user ID in our system!"})
        } else context.setState({errorMsg: "No information was returned from the server!"})
      }

      if (query !== "") callAWSApi(this)
    } else {
      this.setState({ errorMsg: "No user ID was passed into Oslyn!" })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({width: window.innerWidth, height: window.innerHeight})
  }

  openSuccessModal() { this.setState({successModal: true }) }
  closeSuccessModal() { this.setState({successModal: false }) }
  handleUnsubscribeChange(event) { this.setState({userChoice: event.target.value}) }
  async submitNewUserPreferences() {
    this.setState({loading: true})
    console.log(this.state.userID)
    console.log(this.state.userChoice)

    let res = null

    if (this.state.userChoice === "removeProductUpdates") {
      AWSconfigure("WEBAPP")
      res = await API.post('oslynWebappRestV1', '/beta/unsubscribe', {
        body: {
          id: this.state.userID,
          subscribeProductOfferings: true,
          subscribeProductUpdates: false
        }
      })
      console.log(res)
    } else if (this.state.userChoice === "removeProductOfferings") {
      AWSconfigure("WEBAPP")
      res = await API.post('oslynWebappRestV1', '/beta/unsubscribe', {
        body: {
          id: this.state.userID,
          subscribeProductOfferings: false,
          subscribeProductUpdates: true
        }
      })
      console.log(res)
    } else if (this.state.userChoice === "unsubscribe") {
      AWSconfigure("WEBAPP")
      res = await API.post('oslynWebappRestV1', '/beta/unsubscribe', {
        body: {
          id: this.state.userID,
          subscribeProductOfferings: false,
          subscribeProductUpdates: false
        }
      })

      console.log(res)
    } else {
      res.error = "Email preference option unfound."
    }

    if (res) {
      if (res.error){
        if (res.error.message)
          this.setState({successModalerrorMsg: JSON.stringify(res.error.message).split('"').join('')})
        else
          this.setState({successModalerrorMsg: JSON.stringify(res.error).split('"').join('')})
      } else
        this.setState({successModalerrorMsg: ""})
      this.openSuccessModal()
    }
    this.setState({loading:false})
  }

  async submitOslynTeacherUnsubscribe() {
    this.setState({loading: true})
    console.log(this.state.userID)
    AWSconfigure("WEBAPP")
    let res = await API.post('oslynWebappRestV1', '/train/unsubscribe', {
      body: {
        id: this.state.userID
      }
    })

    if (res) {
      if (res.error){
        if (res.error.message)
          this.setState({successModalerrorMsg: JSON.stringify(res.error.message).split('"').join('')})
        else
          this.setState({successModalerrorMsg: JSON.stringify(res.error).split('"').join('')})
      } else
        this.setState({successModalerrorMsg: ""})
      this.openSuccessModal()
    }
    this.setState({loading:false})
  }

  render() {
    const { classes } = this.props

    return (<div className={classes.rootBackground}>
      <Grid container>
        <Grid item xs={12} md={6} xl={6} lg={6} style={{display:this.state.width>=MD_SCREEN?"flex":"none", justifyContent:"center"}}>
          <img src={ozzySad} alt="ozzy sad" style={{width: 500}}/>
        </Grid>
        <Grid item xs={12} md={6} xl={5} lg={5} style={{paddingRight:this.state.width>=MD_SCREEN?40:40, paddingLeft:this.state.width>=MD_SCREEN?0:40}}>
          <Container maxWidth="sm">
            {this.state.errorMsg?
            <div>
              <img src={oslynLogo} alt="oslyn logo" style={{width: (this.state.width-100)<300?this.state.width-100:300, display:this.state.width>=MD_SCREEN?"":"block", margin:this.state.width>=MD_SCREEN?"":"auto"}}/>
              <Typography component="h1" variant="h2" align={this.state.width>=MD_SCREEN?"left":"center"} color="textPrimary" gutterBottom 
                  style={{fontSize: this.state.width>SM_SCREEN?25:20}} className={classes.title}>
                Opps! Looks like there was an issue.
              </Typography>
              <Typography component="h1" variant="h2" align={this.state.width>=MD_SCREEN?"left":"center"} color="textPrimary" gutterBottom 
                  style={{fontSize: this.state.width>SM_SCREEN?20:17}} className={classes.title}>
                <b style={{fontWeight:700}}>{this.state.errorMsg}</b><br/> Please contact <a href="mailto: support@oslyn.io">support</a>.
              </Typography>
            </div>:
            <div>
              <img src={oslynLogo} alt="oslyn logo" style={{width: (this.state.width-100)<300?this.state.width-100:300, display:this.state.width>=MD_SCREEN?"":"block", margin:this.state.width>=MD_SCREEN?"":"auto"}}/>
              {this.state.isOslynTeacher?
              <Typography component="h1" variant="h2" align={this.state.width>=MD_SCREEN?"left":"center"} color="textPrimary" gutterBottom 
                  style={{fontSize: this.state.width>SM_SCREEN?25:20}} className={classes.title}>
                {this.state.userName}, thank you for all that you’ve done as an Oslyn trainer. We’re sad to see you leave.
              </Typography>:
              <Typography component="h1" variant="h2" align={this.state.width>=MD_SCREEN?"left":"center"} color="textPrimary" gutterBottom 
                  style={{fontSize: this.state.width>SM_SCREEN?25:20}} className={classes.title}>
                {this.state.userName}! How much Oslyn do you want in your life? We’ll be sad to see you leave.
              </Typography>}
              {this.state.isOslynTeacher?
              <div style={{paddingTop: 20}}>
                <Typography component="h6" variant="h6" align="left"
                  style={{fontSize:20, color: "black", fontWeight:700}}
                >Want to follow Oslyn on social media instead?</Typography>
                <div style={{textAlign:this.state.width>=MD_SCREEN?"left":"center", paddingTop:20}}>
                  <FacebookIcon className={classes.social} onClick={() => {window.open("https://www.facebook.com/oslynio","_blank")}}/>
                  <InstagramIcon className={classes.social} onClick={() => {window.open("https://www.instagram.com/oslyn.io/","_blank")}}/>
                  <YoutubeIcon className={classes.social}/>
              </div>
              </div>:
              <div style={{paddingTop: 20, paddingLeft:20}}>
                <Typography component="h6" variant="h6" align="left"
                  style={{fontSize:20, color: "black", fontWeight:700}}
                >Email Preferences</Typography>
                <MuiThemeProvider theme={wrapperTheme}>
                <FormControl component="fieldset" style={{}}>
                  <RadioGroup aria-label="unsubChoice" name="unsubChoice" value={this.state.userChoice} 
                      onChange={this.handleUnsubscribeChange.bind(this)}>
                    <FormControlLabel value="removeProductUpdates" control={<Radio size={this.state.width>=MD_SCREEN?"medium":"small"} />} 
                      label={<span style={{fontSize:this.state.width>=MD_SCREEN?20:17}}>I only want to receive product updates.</span>} />
                    <FormControlLabel value="removeProductOfferings" control={<Radio size={this.state.width>=MD_SCREEN?"medium":"small"} />} 
                      label={<span style={{fontSize:this.state.width>=MD_SCREEN?20:17}}>I only want to receive product offerings.</span>} />
                    <FormControlLabel value="unsubscribe" control={<Radio size={this.state.width>=MD_SCREEN?"medium":"small"} />} 
                      label={<span style={{fontSize:this.state.width>=MD_SCREEN?20:17}}>Unsubscribe. <em>*Sniff*</em>. It’s over, Oslyn.</span>} />
                  </RadioGroup>
                </FormControl>
                </MuiThemeProvider>
              </div>}
              <div style={{paddingTop: 30, display:this.state.width>=MD_SCREEN?"":"flex", justifyContent:this.state.width>=MD_SCREEN?"":"center"}}>
                <Button className={classes.updatePreferenceButton} style={{}}
                    disabled={this.state.loading || (this.state.userChoice === null && !this.state.isOslynTeacher)} 
                    onClick={this.state.isOslynTeacher?this.submitOslynTeacherUnsubscribe.bind(this):this.submitNewUserPreferences.bind(this)}>
                  {this.state.isOslynTeacher?"Unsubscribe":"Update Preference"}
                </Button>
                {this.state.loading && <CircularProgress size={24} className={classes.buttonProgress} style={{top: this.state.width>=MD_SCREEN?5:0}}/>}
              </div>
            </div>}
          </Container>
        </Grid>
      </Grid>
      <Dialog onClose={this.closeSuccessModal.bind(this)} aria-labelledby="customized-dialog-title" open={this.state.successModal} scroll="body">
        <DialogTitle id="customized-dialog-title" onClose={this.closeSuccessModal.bind(this)}
          style={{display:"flex", justifyContent:"flex-end", backgroundColor: "#84f2e7"}}>
          {" "}
        </DialogTitle>
        <DialogContent style={{background: "linear-gradient(#84f2e7 0%, #3cd0c2 100%)", paddingBottom: 35}}> {/** #63e1ff */}
          <Typography component="h1" variant="h2" align="center" style={{fontWeight:100}}>
            {this.state.successModalerrorMsg?"Opps, something's wrong!":"Success!"}
          </Typography>
          {!this.state.successModalerrorMsg?
            <Container maxWidth="xs">
              {this.state.isOslynTeacher?
              <div>
                <Typography gutterBottom style={{textAlign:"center", paddingTop:30, fontSize: 20}}>
                You have been removed from this subscriber list and won’t receive further emails from us about training Oslyn. 
                </Typography>
              </div>:
              <div>
                <Typography gutterBottom style={{textAlign:"center", paddingTop:30, fontSize: 20}}>
                  We have received your updated frequency preferences.
                </Typography>
                <Typography gutterBottom style={{textAlign:"center", paddingTop:30, fontSize: 20}}>
                  Want to follow Oslyn on social media instead?
                </Typography>
                <div style={{textAlign:"center", paddingTop:10}}>
                  <FacebookIcon className={classes.social} onClick={() => {window.open("https://www.facebook.com/oslynio","_blank")}}/>
                  <InstagramIcon className={classes.social} onClick={() => {window.open("https://www.instagram.com/oslyn.io/","_blank")}}/>
                  <YoutubeIcon className={classes.social}/>
                </div>
              </div>}
            </Container>:
            <div>
              <Typography gutterBottom style={{textAlign:"center", paddingTop: 40}}>
                {this.state.successModalerrorMsg}
              </Typography>
              <Typography gutterBottom style={{textAlign:"center"}}>
                Please contact us at <a href="mailto:support@oslyn.io">support@oslyn.io</a>
              </Typography>
            </div>
          }
        </DialogContent>
      </Dialog>
    </div>)
  }
}

export default withStyles(styles, { withTheme: true })(Unsubscribe)