import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

import { Grid, Button, IconButton, Container, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import GetAppIcon from '@material-ui/icons/GetApp'

// DO NOT REMOVE
import GooglePlayButton from '../img/google-play-badge.png'
import AppleAppStoreButton from '../img/apple-app-badge.svg'

import oslynPhoneLive from '../img/phone-mockup-start-screen.png'
import oslynHalfPhone from '../img/phone-mockup-sustain.png'
import guitarBackground from '../img/person-playing-brown-guitar.jpg'

import Subscribe from './Privacy/Subscribe'

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "rgba(101,31,255,1)"
  },
  heroButtons: {
    marginTop: theme.spacing(4)
  },
  heroButtons2:{
    marginTop: theme.spacing(7),
    display: "flex",
    justifyContent: "center"
  },
  downloadOslynBeta: {
    color: "white",
    borderColor: "white",
    border: "solid",
    borderWidth: 1,
    fontSize: 20,
    textTransform: "none",
    borderRadius: 30,
    
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 40,
    paddingLeft: 40,

    marginTop: 1,
    marginBottom: 1,
    transition: "border-width 0.05s ease, margin-bottom 0.05s ease, margin-top 0.05s ease",

    "&:hover": {
      borderColor: "white",
      color: "white",
      borderWidth: 2,
      marginTop: 0,
      marginBottom: 0,
    }
  },
  downloadBetaIcon: {
    fontSize: "24px !important"
  },
  googleDownloadButton: {
    "&:hover": {
      cursor: "pointer"
    },
  },
  appleDownloadButton: {
    padding: 10,
    height: "auto",
    "&:hover": {
      cursor: "pointer"
    },
  },
  version: {
    fontSize: 15, 
    color: "#bdbdbd", 
    display: "inline", 
    position: "relative", 
    bottom: 30,
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  headerSection: {
    background: "linear-gradient(0deg, rgba(35,35,45,1) 0%, rgba(28,0,90,1) 25%, rgba(101,31,255,1) 100%)"
  },
  headerLine1: {
    color: "white"
  },
  headerLine2: {
    color: "white"
  },
  headerScrollDownButton: {
    color: "white",
    borderColor: "white",
    border: "solid",
    borderWidth: 1,
    top: -200
  },
  featureSection: {
    background: "rgba(35,35,45,1)",
    color: "white",
  },
  featureSectionTitle: {
    color: "#ff8a65", //orange
    fontSize: 40
  },
  featureSectionParagraph: {
    color: "white",
    fontSize: 19,
    marginBottom: 20
  },
  demoSection: {
    background: "#3d3f4b"
  },
  demoSectionTitle: {
    color: "#47dcff", //light-blue
    fontSize: 40
  },
  demoSectionParagraph: {
    color: "white",
    fontSize: 19
  }, 
  demoSectionButton: {
    color: "white",
    borderColor: "white",
    paddingRight: 30, paddingLeft: 30,
    borderRadius: 25,
    "&:hover": {
      color: "#47dcff",
      borderColor: "#47dcff",
      //backgroundColor: "#47dcff"
    },
    transition: "color 0.5s ease, border-color 0.5s ease"
  },
  aiSection: {

  },
  aiSectionTitle: {
    color: "#ffc050", //yellow
    fontSize: 40
  },
  aiSectionParagraph: {

  },
  aiSectionButton: {
    color: "white",
    borderColor: "white",
    paddingRight: 30, paddingLeft: 30,
    borderRadius: 25,
    "&:hover": {
      color: "#ffc050",
      borderColor: "#ffc050",

    },
    transition: "color 0.5s ease, border-color 0.5s ease"
  },
  subscribeSection: {
    background: "rgba(35,35,45,1)",
    paddingTop: 0
  },
  subscribeSectionTitle: {
    color: "white",
    fontSize: 40
  },
  downloadBetaSecionParagraph: {
    color: "#eeeeee",
    fontSize: 10,
    marginBottom: 20,
    padding: 20
  },
})

const SM_SCREEN = 600
const MD_SCREEN = 960
const LG_SCREEN = 1280
const XL_SCREEN = 1920

class Front extends Component {
  constructor(props) {
    super(props)
    this.state = {
      width: 0, height: 0,
    }

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    this.featuresSectionRef = React.createRef()
    this.demoSectionRef = React.createRef()
    this.aiSectionRef = React.createRef()
    this.subscribeSectionRef = React.createRef()
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({width: window.innerWidth, height: window.innerHeight})
  }

  scrollToFeaturesSection = () => {
    console.log(this.state.width)
    if (this.state.width>SM_SCREEN) 
      window.scrollTo(0, this.featuresSectionRef.current.offsetTop-100)
    else
      window.scrollTo(0, this.featuresSectionRef.current.offsetTop+100)
  }
  scrollToDemoSection = () => {
    if (this.state.width>SM_SCREEN) 
      window.scrollTo(0, this.demoSectionRef.current.offsetTop-100)
    else
    window.scrollTo(0, this.demoSectionRef.current.offsetTop-100)
  }
  scrollToAISection = () => {
    if (this.state.width>SM_SCREEN) 
      window.scrollTo(0, this.aiSectionRef.current.offsetTop-50)
    else
      window.scrollTo(0, this.aiSectionRef.current.offsetTop)
  }
  scrollToSubscribeSection = () => {
    if (this.state.width>SM_SCREEN)
      window.scrollTo(0, this.subscribeSectionRef.current.offsetTop-50)
    else
    window.scrollTo(0, this.subscribeSectionRef.current.offsetTop)
  }

  render() {
    const { classes } = this.props

    return (<div className={classes.root}>
          <div className={classes.headerSection}>
            <Container maxWidth="sm" style={{paddingTop: 190, paddingBottom: 350}}>
                <Typography component="h1" variant="h2" align="left" color="textPrimary" gutterBottom 
                    style={{textAlign: "center",fontSize: this.state.width>SM_SCREEN?55:35}} className={classes.headerLine1}>
                  Oslyn is your digital bandmate.
                </Typography>
                <Typography varient="h5" align="center" color="textSecondary" 
                  style={{fontSize: this.state.width>SM_SCREEN?20:12}} className={classes.headerLine2}>
                  Music accompaniment you can count on anytime.
                </Typography>
                {/*<div className={classes.heroButtons}>
                  <Grid container spacing={2} justify="center">
                    <Grid item style={{overflow: "hidden"}} >
                      <img src={GooglePlayButton} onClick={this.props.toDemo} alt="Google Play Download"
                        style={{maxWidth: this.state.width > 480 ? 200: (this.state.width+18) / 2.7}}
                        className={ classes.googleDownloadButton }
                      />
                      <img src={AppleAppStoreButton} onClick={this.props.toDemo} alt="Apple App Download"
                        style={{
                          width: this.state.width > 480 ? 180: (this.state.width-12) / 2.7,
                          marginBottom: this.state.width > 480 ? 1 : -0.5 - this.state.width / 480
                        }}
                        className={ classes.appleDownloadButton }
                      />
                    </Grid>
                  </Grid>
                </div>*/}
                <div className={classes.heroButtons2}>
                  <Button variant="outlined" color="primary" className={classes.downloadOslynBeta}
                    onClick={this.scrollToSubscribeSection.bind(this)}
                    startIcon={
                      <GetAppIcon className={classes.downloadBetaIcon} 
                        style={{fontSize: this.state.width>SM_SCREEN?"24px !important":"19px !important"}}/>
                      }
                    style={{fontSize: this.state.width>SM_SCREEN?20:15}}
                  >Download Oslyn Alpha
                  </Button>
                </div>
            </Container>
            <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
              <IconButton variant="outlined" color="primary" className={classes.headerScrollDownButton}
                onClick={this.scrollToFeaturesSection.bind(this)}>
                <ExpandMoreIcon />
              </IconButton>
            </div>
          </div>
          <Grid container style={{width: "100%"}} className={classes.featureSection} ref={this.featuresSectionRef}>
            <Grid item xs={1} sm={2} md={1} lg={1} xl={1} style={{display: this.state.width<LG_SCREEN && this.state.width>MD_SCREEN?"none":"inherit"}}/>
            <Grid item xs={12} sm={8} md={6} lg={5} xl={5} style={{paddingRight: this.state.width<SM_SCREEN?10:"", paddingLeft: this.state.width<SM_SCREEN?10:""}}>
              <Container maxWidth="md" style={{paddingTop: 200, paddingBottom: 200, paddingLeft: this.state.width<LG_SCREEN && this.state.width>MD_SCREEN?60:""}}>
                  <Typography component="h1" variant="h2" align="left" color="textPrimary" className={classes.featureSectionTitle}
                    style={{textAlign: this.state.width<MD_SCREEN?"center":"left", fontSize:this.state.width<SM_SCREEN?30:""}} gutterBottom>
                    Enhance your musical live performances
                  </Typography>
                  <Typography varient="h5" align="left" color="textSecondary" className={classes.featureSectionParagraph}
                      style={{textAlign: this.state.width<MD_SCREEN?"center":"left", fontSize:this.state.width<SM_SCREEN?15:""}}>
                    Are you looking to enhance your worship practices and live performances, 
                    but don’t have a synthesizer or time to use a digital audio workstation? 
                    Then, meet Oslyn.
                  </Typography>
                  <Typography varient="h5" align="left" color="textSecondary" className={classes.featureSectionParagraph}
                      style={{textAlign: this.state.width<MD_SCREEN?"center":"left", fontSize:this.state.width<SM_SCREEN?15:""}}>
                    Oslyn listens to you play and accompanies you in realtime with a wide selection 
                    of pads, synths, and sounds. It responds dynamically as you adjust your speed and sound.
                  </Typography>
                  <Typography varient="h5" align="left" color="textSecondary" className={classes.featureSectionParagraph}
                      style={{textAlign: this.state.width<MD_SCREEN?"center":"left", fontSize:this.state.width<SM_SCREEN?15:""}}>
                    For Alpha, Oslyn accompanies guitar only. 
                    We’re working hard to train Oslyn to accompany voice and 
                    other instruments for the official launch.
                  </Typography>
              </Container>
            </Grid>
            <Grid item xs={false} lg={1} xl={1}/>
            <Grid item xs={12} sm={12} md={6} lg={5} xl={4} 
            style={{display: "flex", justifyContent: "center", background: this.state.width<MD_SCREEN?"#3d3f4b":"inherit"}}>
              <img src={oslynPhoneLive} alt="Oslyn Phone" style={{
                width: this.state.width<440?400-(440-this.state.width):400, 
                marginTop: 50, height: this.state.width<440?717-(440-this.state.width)*(717/440):717}}/>
            </Grid>
          </Grid>
          <Grid container style={{width: "100%"}} className={classes.demoSection} ref={this.demoSectionRef}>
            <Grid item xs={false} xl={1}/>
            <Grid item md={6} xl={4} style={{display:this.state.width<MD_SCREEN?"none":""}}>
              <img src={oslynHalfPhone} alt="oslyn half phone" style={{width: 600, height: 683, marginTop: 50, marginBottom: -5}}/> {/** use (marginBottom -5) to fix visual bug - Dom*/}
            </Grid>
            <Grid item xs={false} sm={2} xl={1} style={{display: this.state.width>MD_SCREEN?"none":"inherit", background: this.state.width<MD_SCREEN?"rgba(35,35,45,1)":""}}/>
            <Grid item xs={12} sm={8} md={6} lg={5} xl={5} style={{
                  background: this.state.width<MD_SCREEN?"rgba(35,35,45,1)":"", 
                  paddingRight: this.state.width<SM_SCREEN?10:"", 
                  paddingLeft: this.state.width<SM_SCREEN?10:""}}>
              <Container maxWidth="md" style={{paddingTop: 200, paddingBottom: 100, 
                paddingLeft:this.state.width>MD_SCREEN && this.state.width<LG_SCREEN?120-((this.state.width-MD_SCREEN)*0.5):"", 
                paddingRight: this.state.width<LG_SCREEN && this.state.width>MD_SCREEN?60:""}}>
                  <Typography component="h1" variant="h2" align="left" color="textPrimary" gutterBottom className={classes.demoSectionTitle}
                  style={{textAlign: this.state.width<MD_SCREEN?"center":"inherit", fontSize:this.state.width<SM_SCREEN?30:""}}>
                    See how Oslyn works
                  </Typography>
                  <Typography varient="h5" align="left" color="textSecondary" className={classes.demoSectionParagraph}
                    style={{textAlign: this.state.width<MD_SCREEN?"center":"inherit", fontSize:this.state.width<SM_SCREEN?15:""}}>
                    Before you choose Oslyn as your digital bandmate, you might want to give it an audition. 
                    We get it. So we’ll let you see how Oslyn can accompany you without downloading the app first. 
                    Click demo, then play your guitar to see how it responds.
                  </Typography>
                  <div className={classes.heroButtons}>
                    <Grid container spacing={2} justify={this.state.width<MD_SCREEN?"center":"flex-start"}>
                      <Grid item>
                        <Button variant="outlined" color="primary" onClick={this.props.toDemo} className={classes.demoSectionButton}>
                          Demo
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
              </Container>
            </Grid>
            <Grid item xs={false} sm={2} md={1} lg={1} xl={1} style={{background: this.state.width<MD_SCREEN?"rgba(35,35,45,1)":"", display: this.state.width<LG_SCREEN && this.state.width>MD_SCREEN?"none":"inherit"}}/>
            <Grid item xs={12} style={{display: this.state.width>MD_SCREEN?"none":"flex", justifyContent: "center"}}>
              <img src={oslynHalfPhone} alt="oslyn half phone" 
                style={{width: this.state.width<660?600-(660-this.state.width):660, height: this.state.width<660?(751-(750 - 751/660 * this.state.width)):751,
                  maxWidth:this.state.width-20, marginTop: 50}}/> {/** use (marginBottom -5) to fix visual bug - Dom*/}
            </Grid>
          </Grid>
          <Grid container style={{width: "100%"}} className={classes.featureSection} ref={this.aiSectionRef}>
            <Grid item xs={false} sm={2} md={1} lg={1} xl={1} style={{display: this.state.width<LG_SCREEN && this.state.width>MD_SCREEN?"none":"inherit"}}/>
            <Grid item xs={12} md={6} lg={5} xl={4} style={{paddingRight: this.state.width<SM_SCREEN?10:"", paddingLeft: this.state.width<SM_SCREEN?10:""}}>
              <Container maxWidth="md" style={{paddingTop: 200, paddingBottom: this.state.width>MD_SCREEN?300:150,
                      paddingLeft: this.state.width<LG_SCREEN && this.state.width>MD_SCREEN?60:"", paddingRight: this.state.width<XL_SCREEN && this.state.width>MD_SCREEN?60:""}}>
                  <Typography component="h1" variant="h2" align="left" color="textPrimary" className={classes.aiSectionTitle}
                      style={{textAlign: this.state.width<MD_SCREEN?"center":"inherit", fontSize:this.state.width<SM_SCREEN?30:""}} gutterBottom>
                    Oslyn is powered by AI
                  </Typography>
                  <Typography varient="h5" align="left" color="textSecondary" className={classes.featureSectionParagraph}
                  style={{textAlign: this.state.width<MD_SCREEN?"center":"inherit", fontSize:this.state.width<SM_SCREEN?15:""}}>
                    Oslyn uses artificial intelligence (AI) to learn and predict what it should play next for accompaniment while listening to you perform.
                  </Typography>
                  <Typography varient="h5" align="left" color="textSecondary" className={classes.featureSectionParagraph}
                  style={{textAlign: this.state.width<MD_SCREEN?"center":"inherit", fontSize:this.state.width<SM_SCREEN?15:""}}>
                    This means the more Oslyn listens, the more it learns to improve its accuracy in predicting chords and 
                    capability as your bandmate. 
                  </Typography>
                  <Typography varient="h5" align="left" color="textSecondary" className={classes.featureSectionParagraph}
                  style={{textAlign: this.state.width<MD_SCREEN?"center":"inherit", fontSize:this.state.width<SM_SCREEN?15:""}}>
                    You can help Oslyn grow during its alpha phase! Become a trainer for Oslyn by submitting a recording of yourself singing and playing a song.
                  </Typography>
                  <div className={classes.heroButtons}>
                    <Grid container spacing={2} justify={this.state.width<MD_SCREEN?"center":"flex-start"}>
                      <Grid item>
                        <Button variant="outlined" color="primary" onClick={this.props.toTrain} className={classes.aiSectionButton}>
                          Train Oslyn
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
              </Container>
            </Grid>
            <Grid item xs={false} xl={1}/>
            {/*<Grid item xs={12} md={6} xl={6} style={{
              backgroundImage: "url("+guitarBackground+")",
              backgroundSize: "auto 100%",
              minHeight: this.state.width>MD_SCREEN?20:this.state.width*(3264/4896),
              }}>*/}
              <Grid item xs={12} md={6} xl={6} style={{
                backgroundImage: "url("+guitarBackground+")",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                minHeight: this.state.width>MD_SCREEN?20:this.state.width*(3264/4896),
              }}>
            </Grid>
          </Grid>
          {/*<div style={{background: "rgba(35,35,45,1)", width: "100%", paddingTop:30}}>
            <Divider style={{color: "grey", background: "grey", height:2, borderRadius:1, marginLeft:this.state.width<MD_SCREEN?20:100, marginRight:this.state.width<MD_SCREEN?20:100}}/>
            </div>*/}
          <Grid container style={{width: "100%"}} className={classes.subscribeSection} ref={this.subscribeSectionRef}>
            <Grid item xs={12}>
              <Container maxWidth="sm" style={{paddingTop: 100, paddingBottom: 30}}>
                <Typography component="h1" variant="h2" align="left" color="textPrimary" className={classes.subscribeSectionTitle}
                  style={{textAlign: "center", fontSize:this.state.width<SM_SCREEN?30:"", paddingBottom:20}} gutterBottom>
                  Download Oslyn Alpha
                </Typography>
                <Typography varient="h5" align="left" color="textSecondary" className={classes.featureSectionParagraph}
                    style={{textAlign: "center", fontSize:this.state.width<SM_SCREEN?15:""}}>
                  We are alpha testing Oslyn. Sign up to download Oslyn and enroll in the testing programme for Android and/or iOS. 
                </Typography>
                <Typography varient="h5" align="left" color="textSecondary" className={classes.featureSectionParagraph}
                    style={{textAlign: "center", fontSize:this.state.width<SM_SCREEN?13:15, color: "#eeeeee"}}>
                  Note: A Gmail email address must be used to sign up for Android. 
                </Typography>
                <Subscribe />
                <Typography varient="h5" align="left" color="textSecondary" className={classes.downloadBetaSecionParagraph} 
                    style={{textAlign: "center", fontSize:this.state.width<SM_SCREEN?9:10}}>
                  By becoming a alpha tester, you agree to receive emails from Oslyn about updates and surveys to help us improve. 
                  You can unsubscribe anytime by updating your email preferences via a link in the footer of our emails.
                </Typography>
              </Container>
            </Grid>
          </Grid>
        </div>)
  }
}

export default withStyles(styles, { withTheme: true })(Front)