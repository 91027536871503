import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { Auth, API, graphqlOperation, Storage } from 'aws-amplify'

import { Box, Typography, Container, Grid, Paper, Link, Chip,
  TableContainer, Table, TableHead, TableRow, TableCell, TableBody, 
  Checkbox, Button, Fab, Zoom, TextField, IconButton, CircularProgress,
  Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText,
  FormControl, InputLabel, OutlinedInput, InputAdornment, FormControlLabel } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import ImageIcon from '@material-ui/icons/Image'
import AndroidIcon from '@material-ui/icons/Android'
import AppleIcon from '@material-ui/icons/Apple'
import MobileOffIcon from '@material-ui/icons/MobileOff'
import MobileFriendlyIcon from '@material-ui/icons/MobileFriendly'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import PublishIcon from '@material-ui/icons/Publish'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import CloudDoneIcon from '@material-ui/icons/CloudDone'
import ErrorIcon from '@material-ui/icons/Error'

import AudioPlayer from 'react-h5-audio-player'
import defaultBackground from '../../../img/default-sound-background.svg'
import defaultBackgroundBlack from '../../../img/default-sound-background.png'
import SoundTable from './SoundTable'
import { SketchPicker } from 'react-color'

import { AWSconfigure } from '../../../common/Configure'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://oslyn.io">
        Oslyn.io
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const CustomCheckbox = withStyles({
  root: {
    "&$checked":{
      color: "#651fff"
    }
  },
  checked: {}
})(Checkbox)

const styles = theme => ({
  appBarSpacer: theme.mixins.toolbar,
  root: {
    flexGrow: 1,
    backgroundColor: "rgba(101,31,255,1)",
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(4),
    right: theme.spacing(6),
  },
  fabColor: {
    color: "#23232d", // black
    backgroundColor: "#ff8a65", // orange
    transition: "color 0.8s ease, background-color 0.8s ease, transform 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important",
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: "#651fff", // purple
    },
  },
  firstForTextGrid: {
    paddingRight: 10,
    paddingLeft: 10
  },
  smallerPaddingForTextGrid: {
    paddingRight: 5,
    paddingLeft: 5,
    paddingBottom: 15
  },
  backgroundRoot: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  backgroundInput: {
    display: 'none',
  },
  backgroundImageButton: {
    color: theme.palette.common.white,
    backgroundColor: "#47dcff", // light blue
    transition: "color 0.8s ease, background-color 0.8s ease, transform 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important",
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: "#651fff", // purple
    },
  },
  playButton: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    transition: "color 0.2s ease, background-color 0.2s ease, transform 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important",
    '&:hover': {
      color: theme.palette.common.black,
      backgroundColor: "#eeeeee", // grey
    },
  },
  backButton: {
    position: "relative",
    top: 20, 
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  publishButton: {
    color: theme.palette.common.white,
    backgroundColor: "#651fff", // purple
    transition: "color 0.2s ease, background-color 0.2s ease, transform 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important",
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: "#421a99", // purple
    },
  },
  unpublishButton: {
    color: theme.palette.common.white,
    backgroundColor: "#d32f2f", // red
    transition: "color 0.2s ease, background-color 0.2s ease, transform 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important",
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: "#b71c1c", // red
    }
  },
  categoryRoot: {
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
    paddingRight: 5,
    paddingLeft: 5,
    paddingBottom: 10
  },
  audioContainer: {},
  audioRoot: {
    paddingBottom: 15,
    paddingLeft: 5
  },
  audioButtonAndStatusRoot: {
    '& > *': {
      margin: theme.spacing(1),
    },
    paddingBottom: 15
  },
  statusIcon: {
    fontSize: "1.8em"
  }
})

class Sound extends Component {
  constructor(props) {
    super(props)
    this.state = {
      height: 0,
      isChanged: false,
      isBackgroundChanged: false,
      isSoundTableChanged: false,
      currentUser: null,

      /** SOUND OBJECT */
      id: "",

      androidProductId: "",
      iosProductId: "",

      title: "",
      titleHexColor: "#fff",
      uploadedBackground: defaultBackground,

      price: "-",
      discountPrice: "-",

      bucketFolder: "",
      description: "",
      isActive: false,
      promotionOrder: "",

      audioOwner: "",

      categoryOpen: false,
      categoryOptions: [],
      categories: [],

      demoFile: null,
      isNewUpdateDemo: false
    }

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
    console.log(this.props.soundID)
    this.getSoundInfo(this.props.soundID)
    this.getUser()
    this.getBackgroundImage(this.props.soundID)
    this.getDemoFromCloud(this.props.soundID)
    this.getCategories()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({height: window.innerHeight})
  }

  setStopReload() {
    this.setState({isChanged: true})
    window.onbeforeunload = (e) => {
      console.log('Stop this')
      e.preventDefault()
      e.returnValue = ''
    }
  }
  removeStopReload() {
    this.setState({isChanged: false, isBackgroundChanged: false, isSoundTableChanged: false})
    window.onbeforeunload = (e) => {
      console.log('Dont stop anymore')
    }
  }
  setSoundTableChange(){
    this.setStopReload()
    this.setState({isSoundTableChanged: true})
  }

  async getUser() { 
    let user = await Auth.currentAuthenticatedUser()
    if (user)
      this.setState({ currentUser: user.attributes.email })
    console.log(user.attributes.email)
  }

  async getBackgroundImage(soundID) {
    try {
      let image = await Storage.get(`${soundID}/background.jpeg`)
      this.setState({uploadedBackground: image})
    } catch (error) {
      console.error(error)
    }
  }

  async getDemoFromCloud(soundID) {
    if (soundID) {
      let listData = await Storage.list(`${soundID}/`)
      for (let data of listData) {
        if (data.key === `${soundID}/demo.mp3`) {
          try {
            let demo = await Storage.get(`${soundID}/demo.mp3`)
            this.setState({demoFile: demo})
          } catch (error) {
            console.error(error)
          }
          break;
        }
      }
    }
  }

  async getSoundInfo(soundID) {
    let query =`
    query getSoundItem {
      getOslynSound(id: "${soundID}") {
        id androidProductId iosProductId title titleHexColor price discountPrice
        bucketFolder description isActive promotionOrder audioOwner
        categories { items { id category { id name isActive } } }
      }
    }`

    AWSconfigure("WEBAPP")
    let data = await API.graphql(graphqlOperation(query))
    console.log(data)
    if (data) {
      if (data.data.getOslynSound){
        console.log(data.data.getOslynSound)
        let soundObj = data.data.getOslynSound
        this.setState({
          id: soundObj.id || "",
          androidProductId: soundObj.androidProductId || "",
          iosProductId: soundObj.iosProductId || "",

          title: soundObj.title || "",
          titleHexColor: soundObj.titleHexColor || "#fff",

          price: soundObj.price || "-",
          discountPrice: soundObj.discountPrice || "-",

          bucketFolder: soundObj.bucketFolder || "/",
          description: soundObj.description || "Please enter a sound description.",
          isActive: soundObj.isActive || false,
          promotionOrder: soundObj.promotionOrder || 0,

          audioOwner: soundObj.audioOwner || "admin@oslyn.io",

          categories: soundObj.categories.items
        })
      }
    }
  }

  async getCategories() {
    AWSconfigure("WEBAPP")
    let rawCount = await API.get('oslynWebappRestV1', '/oslyn/category/count')
    let query = `
      query listCategory {
        listOslynCategorys(limit: ${rawCount.data.Count+1}){
          items {
            name id isActive
          }
        }
      }`
    
    AWSconfigure("WEBAPP")
    let data = await API.graphql(graphqlOperation(query))
    console.log(data)
    if (data) {
      if (data.data.listOslynCategorys) {
        let items = data.data.listOslynCategorys.items
        let tempRows = []
        for (let i in items) {
          tempRows.push({
            id: "_new_",
            category: {
              name: items[i].name,
              id: items[i].id,
              isActive: items[i].isActive
            }
          })
        }

        this.setState({categoryOptions: tempRows})
        console.log(tempRows)
      } else { alert("Not connected to AWS! Talk to Dom!") }
    } else { alert("Could not get OslynSoundCategory count! Talk to Dom!")}
  }

  changeAndroidProductId(event) { this.setState({androidProductId: event.target.value}); this.setStopReload() }
  changeIOSProductId(event) { this.setState({iosProductId: event.target.value}); this.setStopReload() }
  changeTitle(event) { this.setState({title: event.target.value}); this.setStopReload() }
  changeTitleHexColor(event) { this.setState({titleHexColor: event.target.value}); this.setStopReload() }
  changePrice(event) { this.setState({price: event.target.value}); this.setStopReload() }
  changeDiscountPrice(event) { this.setState({discountPrice: event.target.value}); this.setStopReload() }
  changeBucketFolder(event) { this.setState({bucketFolder: event.target.value}); this.setStopReload() }
  changeDescription(event) { this.setState({description: event.target.value }); this.setStopReload() }
  changePromotionOrder(event) { this.setState({ promotionOrder: event.target.value }); this.setStopReload() }
  changeAudioOwner(event) { this.setState({ audioOwner: event.target.value }); this.setStopReload() }
  uploadImage(event) {
    this.setState({uploadedBackground: URL.createObjectURL(event.target.files[0]), isBackgroundChanged: true})
    console.log(event.target.files[0])
    this.setStopReload()
  }
  handleChange(color, event) { 
    this.setState({titleHexColor: color.hex})
    this.setStopReload()
  }

  checkValid() {
    if (this.state.title === "" || this.state.title === null) return false

    return true
  }

  async saveSound(){
    if (this.checkValid()) {
      if (this.state.isBackgroundChanged) { 
        let blob = await fetch(this.state.uploadedBackground).then(r => r.blob())
        Storage.put(`${this.state.id}/background.jpeg`, blob, {
          progressCallback(progress) {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`)
            // Later we can put the progress into the state
          },
          contentType: 'image/jpeg'
        })
        this.setState({isBackgroundChanged: false})
      }

      if (this.state.demoFile && this.state.isNewUpdateDemo) {
        let blob = await fetch(this.state.demoFile).then(r => r.blob())
        Storage.put(`${this.state.id}/demo.mp3`, blob, {
          progressCallback(progress) {
            console.log(`Uploaded: ${progress.loaded}/${progress.total} for demo.mp3`)
          },
          contentType: 'audio/mpeg'
        })
        this.setState({isNewUpdateDemo: false})
      }

      if (this.state.isSoundTableChanged) {
        this.soundTable.uploadChordsToCloud()
        this.setState({isSoundTableChanged: false})
      }
  
      let price = this.state.price
      if (price === "-") price = null
  
      let discountPrice = this.state.discountPrice
      if (discountPrice === "-") discountPrice = null
  
      let params = {
        body: {
          id: this.state.id,
          androidProductId: this.state.androidProductId,
          iosProductId: this.state.iosProductId,
          title: this.state.title,
          titleHexColor: this.state.titleHexColor,
          price, discountPrice,
          description: this.state.description,
          promotionOrder: this.state.promotionOrder,
          audioOwner: this.state.audioOwner,
          lastUpdateBy: this.state.currentUser,
          categories: this.state.categories
        }
      }
  
      let data = await API.post('oslynWebappRestV1', '/oslyn/update-sound', params)
      console.log(data)
      if (!data.error) {
        this.removeStopReload()

        // remove all "_new_" category IDs
        this.setState({categories: data.output.categories.items})
      } else {/*TODO: show error*/}
    } else {/*show error this.state.error or something*/}
  }

  async publishSound() {
    if (this.checkValid()) { // CHECK publish valid instead
      let params = {
        body: {
          soundId: this.state.id,
          email: this.state.currentUser
        }
      }

      AWSconfigure("WEBAPP")
      let data = await API.post('oslynWebappRestV1', '/oslyn/release-sound', params)
      console.log(data)
      if (!data.error) {
        this.setState({isActive: true})
      } else { alert(data.error) }
    } else {
      // show error this.state.error or something
    }
  }

  async unpublishSound() {
    let params = {
      body: {
        soundId: this.state.id,
        email: this.state.currentUser
      }
    }

    AWSconfigure("WEBAPP")
    let data = await API.post('oslynWebappRestV1', '/oslyn/unpublish-sound', params)
    console.log(data)
    if (!data.error) {
      this.setState({isActive: false})
    } else { alert(data.error) }
  }

  uploadDemoLocal(event) {
    if (event.target.files[0]) {
      if (event.target.files[0].name.endsWith(".mp3")) {
        this.setStopReload()
        this.setState({demoFile: URL.createObjectURL(event.target.files[0]), isNewUpdateDemo: true, isChanged: true}, () => {
          setTimeout(_ => {
            this.audioPlayer.playAudioPromise()
          }, 800)
        })
      } else alert('Please submit an .mp3 file!')
    } else alert('No Audio File uploaded! Talk to Dom!')
  }

  render() {
    const { classes } = this.props

    return (<div style={{background:"#fafafa"}}>
      <div>
        <IconButton className={clsx(classes.backButton, classes.backgroundImageButton)}
          onClick={this.props.goBack} size="small"
        ><ArrowBackIosIcon /></IconButton>
      </div>
      <Grid container className={classes.container} style={{minHeight: this.state.height-128}}>
        <Grid item xs={12} sm={12} lg={7} xl={7}>
          <Container maxWidth="md">
            <Grid container>
              <Grid item xs={7} lg={8} className={classes.firstForTextGrid}>
                <Grid container>
                  <Grid item xs={12} className={classes.firstForTextGrid}>
                    <small>ID: {this.state.id}</small>
                    <TextField fullWidth id="title" label="Title" value={this.state.title}
                      onChange={this.changeTitle.bind(this)} className={classes.textField}
                      margin="normal" variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} style={{display:"flex", justifyContent:"center", paddingTop: 20}}>
                    <img src={this.state.uploadedBackground} alt="background" style={{height: 256, width: 256}} 
                      onError={(e)=>{e.target.onerror = null; e.target.src=defaultBackground}}/>
                  </Grid>
                  <Grid item xs={12} style={{display:"flex", justifyContent:"center"}}>
                    <div className={classes.backgroundRoot} style={{position: "relative", top: -35}}>
                      <input accept="image/*" className={classes.backgroundInput} id="icon-button-file" type="file" onChange={this.uploadImage.bind(this)} />
                      <label htmlFor="icon-button-file">
                        <IconButton color="primary" aria-label="upload picture" component="span" className={classes.backgroundImageButton}>
                          <ImageIcon />
                        </IconButton>
                      </label>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5} lg={4}>
                <Grid container>
                  <Grid item xs={12} style={{paddingBottom: 40}}>
                    <small>Title colour</small>
                    <SketchPicker color={this.state.titleHexColor} onChange={ this.handleChange.bind(this) } />
                  </Grid>
                  <Grid item xs={12} lg={6} className={classes.smallerPaddingForTextGrid}>
                    <FormControl fullWidth className={classes.textField} variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-price">Price</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-price"
                        value={this.state.price}
                        onChange={this.changePrice.bind(this)}
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        label="Price"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={4} className={classes.smallerPaddingForTextGrid}>
                    <FormControl fullWidth className={classes.textField} variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-discountprice">Discount</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-discountprice"
                        value={this.state.discountPrice}
                        onChange={this.changeDiscountPrice.bind(this)}
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        label="Discount" margin="dense"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{paddingBottom: 20}}><small>Google / Appple Product ID</small></Grid>
              <Grid item xs={6} className={classes.smallerPaddingForTextGrid}>
                <FormControl fullWidth className={clsx(classes.margin, classes.textField)} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-googleID">Play Store Product ID</InputLabel>
                  <OutlinedInput id="outlined-adornment-googleID" label="Play Store Product ID" value={this.state.androidProductId}
                    onChange={this.changeAndroidProductId.bind(this)} className={classes.textField}
                    variant="outlined"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton edge="end" onClick={() => { window.open(
                          "https://play.google.com/console/u/0/developers/4923401216173632682/app/4972429572572608851/managed-products",
                           "_blank") }} aria-label="toggle password visibility"
                        ><AndroidIcon /></IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} className={classes.smallerPaddingForTextGrid}>
                <FormControl fullWidth className={clsx(classes.margin, classes.textField)} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-appleID">App Store Product ID</InputLabel>
                  <OutlinedInput id="outlined-adornment-appleID" label="App Store Product ID" value={this.state.iosProductId}
                    onChange={this.changeIOSProductId.bind(this)} className={classes.textField}
                    variant="outlined"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={() => { window.open(
                          "https://appstoreconnect.apple.com/apps/1518422646/appstore/addons?m="
                          ,"_blank") }} aria-label="toggle password visibility" edge="end"
                        ><AppleIcon /></IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={8} md={9} lg={10} className={classes.audioRoot}>
                <AudioPlayer className={classes.audioContainer}
                  src={this.state.demoFile}
                  onPlay={e => console.log("onPlay")}

                  showDownloadProgress={false}
                  showJumpControls={false}
                  autoPlayAfterSrcChange={false}
                  ref={instance => this.audioPlayer = instance }
                />
              </Grid>
              <Grid item xs={4} md={3} lg={2} className={classes.audioButtonAndStatusRoot} style={{display: "flex", alignItems: "center"}}>
                <input accept="audio/*" className={classes.backgroundInput} 
                  id={"sound-demo-file"} 
                  type="file" onChange={this.uploadDemoLocal.bind(this)}
                />
                <label htmlFor={`sound-demo-file`}>
                  <Button color="primary" aria-label="upload sound" component="span" 
                    className={classes.backgroundImageButton} style={{paddingTop: 27, paddingBottom: 27}}>
                    <PublishIcon />
                  </Button>
                </label>
                <div style={{textAlign: "center"}}>
                    <Typography>Demo</Typography>
                      {this.state.demoFile? 
                        this.state.isNewUpdateDemo?
                          <CloudUploadIcon className={classes.statusIcon} style={{color: "#ffe082"}}/>:
                          <CloudDoneIcon className={classes.statusIcon} style={{color: "#81c784"}}/>:
                          <ErrorIcon className={classes.statusIcon} style={{color: "#e57373"}}/>
                    }
                </div>
              </Grid>
              <Grid item xs={12} className={classes.categoryRoot}>
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  open={this.state.categoryOpen}
                  onOpen={() => {
                    this.setState({categoryOpen: true})
                  }}
                  onClose={() => {
                    this.setState({categoryOpen: false})
                  }}
                  options={this.state.categoryOptions}
                  getOptionLabel={(option) => option.category.name}
                  value={this.state.categories}
                  onChange={(event, value, reason) => {
                    console.log(JSON.stringify(value)+" "+reason)
                    if (reason === "select-option" || reason === "remove-option") {
                      this.setState({categories: value, isChanged: true})
                      this.setStopReload()
                    } else {
                      alert(`Unknown onChange reason: ${reason}, value: ${JSON.stringify(value)}`)
                    }
                  }}
                  getOptionSelected={(option, value) => option.category.id === value.category.id}
                  loading={this.state.categoryOpen && this.state.categoryOptions.length === 0}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Categories"
                      placeholder="Categories"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            { this.state.categoryOpen && this.state.categoryOptions.length === 0 
                            ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        )
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={5} lg={6} className={classes.smallerPaddingForTextGrid}>
                <TextField fullWidth id="audioOwner" label="Audio Owner" value={this.state.audioOwner}
                  onChange={this.changeAudioOwner.bind(this)} className={classes.textField}
                  margin="dense" variant="outlined"
                />
              </Grid>
              <Grid item xs={4} lg={3} className={classes.smallerPaddingForTextGrid}>
                <Button style={{marginTop:10, marginRight: 10, backgroundColor: !this.state.isChanged?"transparent":""}} disabled={!this.state.isChanged}
                  onClick={this.saveSound.bind(this)} className={classes.publishButton}
                >Save</Button>
                <Button style={{marginTop:10, backgroundColor: this.state.isChanged?"transparent":""}} 
                  className={this.state.isActive?classes.unpublishButton:classes.publishButton} 
                  onClick={this.state.isActive?this.unpublishSound.bind(this):this.publishSound.bind(this)} disabled={this.state.isChanged}
                >{this.state.isActive?"Unpublish":"Publish"}</Button>
              </Grid>
              <Grid item xs={3} lg={3} className={classes.smallerPaddingForTextGrid} style={{display: "flex", alignContent:"center", paddingLeft: 30}}>
                <FormControlLabel disabled checked={this.state.isActive} style={{color: "green"}}
                  control={<CustomCheckbox icon={<MobileOffIcon />} checkedIcon={<MobileFriendlyIcon />} name="isActive"/> }
                  label={this.state.isActive?<span style={{color: "#651fff"}}>Published</span>:<span>Not Published</span>} 
                />
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <Grid item xs={12} sm={12} lg={4} xl={4}>
          <Container maxWidth="sm" style={{backgroundColor:"#23232d", paddingTop: 70, paddingBottom: 70}}>
            <div style={{width:"100%", display:"flex", alignContent:"center", justifyContent:"center"}}>
              <div style={{height: 256, width: 256, display:"block"}}>
                <img src={this.state.uploadedBackground} alt="background" style={{height: 256, width: 256}} 
                  onError={(e)=>{e.target.onerror = null; e.target.src=defaultBackgroundBlack}}
                />
                <div style={{display: "flex", justifyContent:"flex-end", paddingRight: 15}}>
                  <IconButton className={classes.playButton} style={{position: "relative", top: -66}} ><PlayArrowIcon /></IconButton>
                </div>
                <Typography variant="h4"
                  style={{
                    color: this.state.titleHexColor, position: "relative", zIndex: 100 , fontWeight: 500,
                    paddingTop:15, paddingRight: 15, paddingLeft: 15, top: -300
                  }}
                >{this.state.title}</Typography>
              </div>
            </div>
            <div style={{width:"100%", display:"flex", alignContent:"center", justifyContent:"center"}}>
              <div style={{width: 256}}>
                <Typography variant="h6" style={{color: "white", paddingTop: 2, paddingLeft: 5}}>{this.state.title}</Typography>
                <small style={{color: "#eeeeee", position: "relative", top: -5, paddingLeft: 8}}>${this.state.price}</small>
              </div>
            </div>
          </Container>
        </Grid>
        <Grid item xs={12} style={{paddingRight: 20, paddingLeft: 20, display:"flex", justifyContent:"center"}}>
          <div style={{overflowX: "auto"}}>
            <SoundTable soundLibID={this.state.id} 
              setChanged={this.setSoundTableChange.bind(this)}
              ref={instance => { this.soundTable = instance; }}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <Container maxWidth="md">
            <Box pt={4} style={{paddingTop: 10, paddingBottom: 20}}>
              <Copyright />
            </Box>
          </Container>
        </Grid>
      </Grid>
    </div>)
  }
}

export default withStyles(styles, { withTheme: true })(Sound)