import React, {useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Typography, Container, Grid, Paper, Link,
      TableContainer, Table, TableHead, TableRow, TableCell, 
      TableBody, Checkbox} from '@material-ui/core'

import { API, graphqlOperation } from 'aws-amplify'
import { AWSconfigure } from '../../common/Configure'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://oslyn.io">
        Oslyn.io
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

export default function Teachers() {
  const classes = useStyles()
  const [rows, setRows] = React.useState([])
  const [windowHeight, setWindowHeight] = React.useState(500)

  useEffect(() => {
    async function awaitData() {
      AWSconfigure("WEBAPP")
      let rawCount = await API.get('oslynWebappRestV1', '/train/count')
      if (rawCount.data){
        console.log(rawCount.data)
        let query = `
        query list2 {
          listOslynTeachers (limit: ${rawCount.data.Count+1}) {
            items {
              id name email isActive storageLocation
            }
          }
        }`

        AWSconfigure("WEBAPP")
        let data = await API.graphql(graphqlOperation(query))
        console.log(data)
        if (data){
          if (data.data.listOslynTeachers) {
            let items = data.data.listOslynTeachers.items
            let tempRows = []
            for (let i in items) {
              tempRows.push({
                id: items[i].id,
                name: items[i].name,
                email: items[i].email,
                isActive: items[i].isActive,
                storageLocation: items[i].storageLocation
              })
            }
            setRows(tempRows)
            console.log(tempRows)
          } else {
            alert("Not connected to AWS! Talk to Dom!")
          }
        }
        return data
      } else {
        alert("Could not get Beta tester Count! Talk to Dom!")
      }
    }

    awaitData()
    window.addEventListener('resize', setWindowHeight(window.innerHeight-64))

  }, [])

  return (<div style={{background:"#fafafa"}}>
    <div className={classes.appBarSpacer} />
    <Container maxWidth="lg" className={classes.container} style={{minHeight: windowHeight}}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="a dense table">{/*size="small"*/}
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell align="right">Name</TableCell>
                      <TableCell align="right">Email</TableCell>
                      <TableCell align="right">Subscribed</TableCell>
                      <TableCell align="right">Storage location (Folder)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {row.id}
                        </TableCell>
                        <TableCell align="right">{row.name}</TableCell>
                        <TableCell align="right">{row.email}</TableCell>
                        <TableCell align="right" padding="checkbox">
                          <Checkbox disabled checked={row.isActive} />
                        </TableCell>
                        <TableCell align="right">{row.storageLocation}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </div>
  );
}