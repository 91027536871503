import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

import { Grid, Button, IconButton, Container, Typography } from '@material-ui/core'

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "rgba(101,31,255,1)",
  },
  highlight :{
    color: "#ff8a65",
    

  },
  title: {
    color: "white"
  },
  headerSection: {
    background: "linear-gradient(0deg, rgba(35,35,45,1) 0%, rgba(28,0,90,1) 25%, rgba(101,31,255,1) 100%)",
    
  },
  privacySection: {
    background: "rgba(35,35,45,1)",
    paddingTop: 0
  },
  privacySectionTitle: {
    color: "white",
    fontSize: 40
  },
  
  Paragraph: {
    color: "white",
  
    marginBottom: 20
  },
})

const SM_SCREEN = 600
const MD_SCREEN = 960
const LG_SCREEN = 1280
const XL_SCREEN = 1920

class Privacy extends Component {

  constructor(props) {
    super(props)
    this.state = {
      width: 0, height: 0
    }

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({width: window.innerWidth, height: window.innerHeight})
  }

  render() {
    const { classes } = this.props

    return (<div className={classes.root}>
      <div className = {classes.headerSection}>
      <Container maxWidth="sm" style={{paddingTop: 190, paddingBottom: 350}}>
      <Typography component="h1" variant="h2" align="left" color="textPrimary" gutterBottom 
          style={{textAlign: "center",verticalAlign: "center", fontSize: this.state.width>SM_SCREEN?55:35}} className={classes.title}>
        Oslyn’s Privacy Policy
      </Typography>
      </Container>
      </div>
      <Grid container style={{width: "100%"}} className={classes.privacySection}>
            <Grid item xs={12}>
              <Container maxWidth="sm" style={{paddingTop: 100, paddingBottom: 30}}>
              <Typography varient="h5" align="left" color="textSecondary" className={classes.Paragraph}
                    style={{textAlign: "left", fontSize:this.state.width<SM_SCREEN?13:16, color: "#eeeeee"}}>
                  Effective: 07/15/20
                </Typography>
                <Typography varient="h5" align="left" color="textSecondary" className={classes.Paragraph}
                    style={{textAlign: "left", fontSize:this.state.width<SM_SCREEN?13:16, color: "#eeeeee"}}>
                  Dominic Fung built the Oslyn app as a Free app. This Service is provided by
                  Dominic Fung at no cost and is intended for use as is.
                </Typography>
                <Typography varient="h5" align="left" color="textSecondary" className={classes.Paragraph}
                    style={{textAlign: "left", fontSize:this.state.width<SM_SCREEN?13:16, color: "#eeeeee"}}>
                  This page is used to inform visitors regarding our policies with the collection,
                  use, and disclosure of Personal Information if anyone decided to use our
                  Service.
                </Typography>
                <Typography varient="h5" align="left" color="textSecondary" className={classes.Paragraph}
                    style={{textAlign: "left", fontSize:this.state.width<SM_SCREEN?13:16, color: "#eeeeee"}}>
                  If you choose to use our Service, then you agree to the collection and use of 
                  information in relation to this policy. The Personal Information that we collect
                  is used for providing and improving the Service. We will not use or share your
                  information with anyone except as described in this Privacy Policy.
                </Typography>
                <Typography component="h2" variant="h3" align="left" color="textPrimary" className={classes.privacySectionTitle}
                  style={{textAlign: "left", fontSize:this.state.width<SM_SCREEN?25:"", paddingBottom:20, paddingTop:40}} gutterBottom>
                  Information Collection and Use
                </Typography>
                <Typography varient="h5" align="left" color="textSecondary" className={classes.Paragraph}
                    style={{textAlign: "left", fontSize:this.state.width<SM_SCREEN?13:16, color: "#eeeeee"}}>
                  For a better experience, while using our Service, we may require you to provide
                  us with certain personally identifiable information, including but not limited to
                  name and email address. The information that we request will be retained by us
                  and used as described in this privacy policy.
                </Typography>
                <Typography varient="h5" align="left" color="textSecondary" className={classes.Paragraph}
                    style={{textAlign: "left", fontSize:this.state.width<SM_SCREEN?13:16, color: "#eeeeee"}}>
                 The app does use third party services that may collect information used to
                 identify you. Link to privacy policy of third party service providers used by the
                 app
                </Typography>
                <ul className={classes.Paragraph} style={{paddingLeft:16, textAlign: "left", alignItems:"left", alignContent:"left", fontSize:this.state.width<SM_SCREEN?13:16, color: "#eeeeee"}}>
                  <li><span className= {classes.highlight}>Google Play Services</span></li>
                  <li><span className= {classes.highlight}>Facebook</span></li>
                </ul>
                <Typography component="h2" variant="h3" align="left" color="textPrimary" className={classes.privacySectionTitle}
                  style={{textAlign: "left", fontSize:this.state.width<SM_SCREEN?25:"", paddingBottom:20, paddingTop:40}} gutterBottom>
                  Log Data
                </Typography>
                <Typography varient="h5" align="left" color="textSecondary" className={classes.Paragraph}
                    style={{textAlign: "left", fontSize:this.state.width<SM_SCREEN?13:16, color: "#eeeeee"}}>
                 We want to inform you that whenever you use our Service, in a case of an error
                 in the app we collect data and information (through third party products) on your
                 phone called Log Data. This Log Data may include information such as your
                 device Internet Protocol (“IP”) address, device name, operating system version,
                 the configuration of the app when utilizing our Service, the time and date of your
                 use of the Service, and other statistics.
                </Typography>
                <Typography component="h2" variant="h3" align="left" color="textPrimary" className={classes.privacySectionTitle}
                  style={{textAlign: "left", fontSize:this.state.width<SM_SCREEN?25:"", paddingBottom:20, paddingTop:40}} gutterBottom>
                  Cookies
                </Typography>
                <Typography varient="h5" align="left" color="textSecondary" className={classes.Paragraph}
                    style={{textAlign: "left", fontSize:this.state.width<SM_SCREEN?13:16, color: "#eeeeee"}}>
                 Cookies are files with a small amount of data that are commonly used as
                 anonymous unique identifiers. These are sent to your browser from the websites
                 that you visit and are stored on your device’s internal memory.
                 This Service does not use these “cookies” explicitly. However, the app may
                 use third party code and libraries that use “cookies” to collect information and
                 improve their services. You have the option to either accept or refuse these
                 cookies and know when a cookie is being sent to your device. If you choose to
                 refuse our cookies, you may not be able to use some portions of this Service.
                </Typography>
                <Typography component="h2" variant="h3" align="left" color="textPrimary" className={classes.privacySectionTitle}
                  style={{textAlign: "left", fontSize:this.state.width<SM_SCREEN?30:"", paddingBottom:20, paddingTop:40}} gutterBottom>
                 Service Providers
                </Typography>
                <Typography varient="h5" align="left" color="textSecondary" className={classes.Paragraph}
                    style={{textAlign: "left", fontSize:this.state.width<SM_SCREEN?13:16, color: "#eeeeee"}}>
                 We may employ third-party companies and individuals due to the following
                 reasons:
                </Typography>
                <ul style={{paddingLeft:16, textAlign: "left", alignItems:"left", alignContent:"left", fontSize:this.state.width<SM_SCREEN?13:16, color: "#eeeeee"}} className={classes.Paragraph}>
                  <li>To facilitate our Service;</li>
                  <li>To provide the Service on our behalf;</li>
                  <li>To perform Service-related services; or</li>
                  <li>To assist us in analyzing how our Service is used.</li>
                </ul>
                <Typography varient="h5" align="left" color="textSecondary" className={classes.Paragraph}
                    style={{textAlign: "left", fontSize:this.state.width<SM_SCREEN?13:16, color: "#eeeeee"}}>
                 We want to inform users of this Service that these third parties have access to
                 your Personal Information. The reason is to perform the tasks assigned to them
                 on our behalf. However, they are obligated not to disclose or use the information
                 for any other purpose.
                </Typography>
                <Typography component="h2" variant="h3" align="left" color="textPrimary" className={classes.privacySectionTitle}
                  style={{textAlign: "left", fontSize:this.state.width<SM_SCREEN?30:"", paddingBottom:20, paddingTop:40}} gutterBottom>
                 Security
                </Typography>
                <Typography varient="h5" align="left" color="textSecondary" className={classes.Paragraph}
                    style={{textAlign: "left", fontSize:this.state.width<SM_SCREEN?13:16, color: "#eeeeee"}}>
                We value your trust in providing us your Personal Information, thus we are
                striving to use commercially acceptable means of protecting it. But remember
                that no method of transmission over the internet, or method of electronic storage
                is 100% secure and reliable, and we cannot guarantee its absolute security.
                </Typography>
                <Typography component="h2" variant="h3" align="left" color="textPrimary" className={classes.privacySectionTitle}
                  style={{textAlign: "left", fontSize:this.state.width<SM_SCREEN?30:"", paddingBottom:20, paddingTop:40}} gutterBottom>
                Links to Other Sites
                </Typography>
                <Typography varient="h5" align="left" color="textSecondary" className={classes.Paragraph}
                    style={{textAlign: "left", fontSize:this.state.width<SM_SCREEN?13:16, color: "#eeeeee"}}>
               This Service may contain links to other sites. If you click on a third-party link, you
               will be directed to that site. Note that these external sites are not operated by us.
               Therefore, we strongly advise you to review the Privacy Policy of these websites.
               We have no control over and assume no responsibility for the content, privacy
               policies, or practices of any third-party sites or services.
                </Typography>
                <Typography component="h2" variant="h3" align="left" color="textPrimary" className={classes.privacySectionTitle}
                  style={{textAlign: "left", fontSize:this.state.width<SM_SCREEN?30:"", paddingBottom:20, paddingTop:40}} gutterBottom>
                 Changes to This Privacy Policy
                </Typography>
                <Typography varient="h5" align="left" color="textSecondary" className={classes.Paragraph}
                    style={{textAlign: "left", fontSize:this.state.width<SM_SCREEN?13:16, color: "#eeeeee"}}>
                We may update our Privacy Policy from time to time. Thus, you are advised to
                review this page periodically for any changes. We will notify you of any changes
                by posting the new Privacy Policy on this page.
                </Typography>
                <Typography component="h2" variant="h3" align="left" color="textPrimary" className={classes.privacySectionTitle}
                  style={{textAlign: "left", fontSize:this.state.width<SM_SCREEN?30:"", paddingBottom:20, paddingTop:40}} gutterBottom>
                 Contact Us
                </Typography>
                <Typography varient="h5" align="left" color="textSecondary" className={classes.Paragraph}
                    style={{textAlign: "left", fontSize:this.state.width<SM_SCREEN?13:16, color: "#eeeeee"}}>
            For more information about our privacy practices, if you have questions, or if you
            would like to make a complaint, please contact us by email at <span className= {classes.highlight}>admin@oslyn.io</span> or
            by mail using the details provided below:
                </Typography>
                <Typography varient="h5" align="left" color="textSecondary" className={classes.Paragraph}
                    style={{textAlign: "left", fontSize:this.state.width<SM_SCREEN?13:16, color: "#eeeeee"}}>
                      PO BOX 99900 XH 270 734 <br/>
                      RPO STREETSVILLE<br/>
                      MISSISSAUGA ON L5M 0T4
                </Typography>

              </Container>
            </Grid>
          </Grid>
    </div>)
  }
}

export default withStyles(styles, { withTheme: true })(Privacy)