/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://5bzsu4hugrakbof4m5safiedda.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-7q4v43alpvgg5iu5y3eqh77qdy",
    "aws_cloud_logic_custom": [
        {
            "name": "oslynWebappRestV1",
            "endpoint": "https://9hxlw25y66.execute-api.us-east-2.amazonaws.com/dev",
            "region": "us-east-2"
        }
    ]
};


export default awsmobile;
