const awsmobile = {
  "aws_project_region": "us-east-2",
  "aws_appsync_graphqlEndpoint": "https://ehxpvje23ngrliq4o52yzk7syi.appsync-api.us-east-2.amazonaws.com/graphql",
  "aws_appsync_region": "us-east-2",
  "aws_appsync_authenticationType": "API_KEY",
  "aws_appsync_apiKey": "da2-5vkycz6dv5flpedjufei7m4wy4",
  "aws_cloud_logic_custom": [
      {
          "name": "oslynstudiov1",
          "endpoint": "https://n4ax0z53s3.execute-api.us-east-2.amazonaws.com/dev",
          "region": "us-east-2"
      },
      {
        "name": "oslynWebappRestV1",
        "endpoint": "https://9hxlw25y66.execute-api.us-east-2.amazonaws.com/dev",
        "region": "us-east-2"
      }
  ]
};


export default awsmobile;
