import React, {useState, useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { Auth } from 'aws-amplify'
import queryString from 'query-string'
import oslynIcon from '../../img/logo192.png'

import config from '../../config.json'

const ERROR_WAIT_TIME = 10000

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://oslyn.io">
        Oslyn
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  error: {
    display: "block",
    color: "#f44336",
    transition: "opacity 0.3s ease-in-out"
  }
}));

async function signIn(username, password) {
  try {
    console.log(username)
    console.log(password)
    let user = await Auth.signIn(username, password)
    console.log(user)
    return user
  } catch (error) {
    console.log('error signing in', error)
    return { error: error }
  }
}

async function changePassword(currentUser, newPass1, newPass2) {
  if (newPass1 === newPass2) {
    try {
      return await Auth.completeNewPassword(
        currentUser,
        newPass1
      )
    } catch (error) {
      console.log('error in change password', error)
      return {error: error}
    }
  } else {
    return  {error: { message: "miss-match passwords."}}
  }
}

export default function AdminSignin(props) {
  const [user, setUser] = useState()

  const [email, setEmail] = useState('')
  const [pass, setPass] = useState('')
  const [stage, setStage] = useState('') // NEW_PASSWORD_REQUIRED

  const [loginErrorMsg, setLoginErrorMsg] = useState('1')
  const [showLoginErr, setShowLoginErr] = useState(false)

  const [newPass1, setNewPass1] = useState('')
  const [newPass2, setNewPass2] = useState('')


  const classes = useStyles();

  useEffect(() => {
    const params = queryString.parse(window.location.search)
    console.log(window.location.search)
    console.log(params)
    setEmail(params.username || '')
  }, [])

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper} style={{paddingTop: 50}}>
          <div style={{display:"flex"}}>
            <Avatar className={classes.avatar}>
              <img src={oslynIcon} alt="Oslyn Logo" style={{width: 40}}/>
            </Avatar>
            <Typography component="h1" variant="h5" style={{paddingTop: 13}}>
              Sign in
            </Typography>
          </div>
          {
            stage === 'NEW_PASSWORD_REQUIRED'? 
              <form className={classes.form} noValidate>

                <TextField disabled className="admin-input"
                  value={pass} variant="outlined"
                  margin="normal" required fullWidth name="temp_password" label="Temp Password" type="password"
                  id="temp_password"
                />

                <TextField className="admin-input"
                  value={newPass1} onChange={(e) => setNewPass1(e.target.value)} variant="outlined"
                  margin="normal" required fullWidth name="new_password" label="New Password" type="password"
                  id="new_password" autoComplete="current-password"
                />
                <TextField className="admin-input"
                  value={newPass2} onChange={(e) => setNewPass2(e.target.value)} variant="outlined"
                  margin="normal" required fullWidth name="retype_password" label="Retype Password" type="password"
                  id="retype_password" autoComplete="current-password"
                />
                <Typography className={classes.error}
                  variant="caption" style={{opacity:showLoginErr?1:0}}>
                  {loginErrorMsg}
                </Typography>
                <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}
                  onClick={(event) => {
                      event.preventDefault()
                      console.log("change password clicked!")
                      changePassword(user, newPass1, newPass2)
                        .then( data => {
                          if (data) {
                            if (data.error) {
                              console.log(data.error)
                              setLoginErrorMsg(data.error.message)
                              setShowLoginErr(true)
                              setTimeout(() => {
                                setShowLoginErr(false)
                              }, ERROR_WAIT_TIME)
                            } else {
                              let user = data
                              console.log(user)
                              props.login()
                            }
                          } else {
                            let user = data
                            console.log(user)
                            props.login()
                          }
                        })
                    }
                  }
                >
                  Change Password
                </Button>
                <Box mt={5}>
                  <Copyright />
                </Box>
              </form>:
              <form className={classes.form} noValidate>
                <TextField className="admin-input"
                  value={email} onChange={(e) => setEmail(e.target.value)} variant="outlined"
                  margin="normal" required fullWidth id="email" label="Email or Username" name="email"
                  autoComplete="email" autoFocus
                />
                <TextField className="admin-input"
                  value={pass} onChange={(e) => setPass(e.target.value)} variant="outlined"
                  margin="normal" required fullWidth name="password" label="Password" type="password"
                  id="password" autoComplete="current-password"
                />
                <Typography className={classes.error}
                  variant="caption" style={{opacity:showLoginErr?1:0}}>
                  {loginErrorMsg}
                </Typography>
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />
                <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}
                  onClick={(event) => {
                      event.preventDefault()
                      signIn(email, pass).then( data => {
                        if (data.error) {
                          console.log(data.error)
                          setLoginErrorMsg(data.error.message)
                          setShowLoginErr(true)
                          setTimeout(() => {
                            setShowLoginErr(false)
                          }, ERROR_WAIT_TIME)
                        } else {
                          let user = data
                          localStorage.setItem("session", user.Session)
                          setUser(user)
                          setStage(user.challengeName)

                          if (user.challengeName !== "NEW_PASSWORD_REQUIRED") props.login()
                        }
                      })
                    }
                  }
                >
                  Sign In
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link href="#" variant="body2">
                      Forgot password?
                    </Link>
                  </Grid>
                </Grid>
                <Box mt={5}>
                  <Copyright />
                </Box>
              </form>
          }
        </div>
      </Grid>
    </Grid>
  );
}