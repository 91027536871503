import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination,
        TableRow, TableSortLabel, Toolbar, Typography, Paper, Checkbox, IconButton,
        Tooltip, FormControlLabel, Switch } from '@material-ui/core'

import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';

import { API, graphqlOperation } from "aws-amplify"
import { AWSconfigure } from '../../../common/Configure';

function createData(id, name, email, subscribeProductUpdates, subscribeProductOfferings, ios, android, status) {
  let statusLevel = 0
  switch(status) {
    case "inProgress":  statusLevel = 1; break;
    case "Complete":    statusLevel = 2; break;
    default:            statusLevel = 0; break;
  }

  return { id, name, email, subscribeProductUpdates, subscribeProductOfferings, ios, android, statusLevel };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'id', numeric: false, disablePadding: true, label: 'ID' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
  { id: 'subscribeProductUpdates', numeric: true, disablePadding: false, label: 'Updates' },
  { id: 'subscribeProductOfferings', numeric: true, disablePadding: false, label: 'Offerings' },
  { id: 'ios', numeric: true, disablePadding: false, label: 'iOS' },
  { id: 'android', numeric: true, disablePadding: false, label: 'Android' },
  { id: 'status', numeric: true, disablePadding: false, label: 'Status'}
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Beta Subscribers
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  approveButton: {
    background: "#d32f2f",
    "&:hover":{
      background: "#c62828"
    },
    color: "white",
    marginRight: 5,
    marginLeft: 5
  }
}));

const wrapperTheme = createMuiTheme({
  palette: {
    primary: { main: "#651fff" },
    secondary: { main: "#651fff" },
  }
});

export default function BetaSubscribers() {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('id');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setData] = React.useState([])

  useEffect(() => {
    async function awaitData() {
      AWSconfigure("WEBAPP")
      let rawCount = await API.get('oslynWebappRestV1', '/beta/count')
      if (rawCount.data){
        let query = `
        query {
          listBetaTesters (limit: ${rawCount.data.Count+1}) {
            items {
              id name email android ios status
              subscribeProductUpdates subscribeProductOfferings
            }
          }
        }`

        AWSconfigure("WEBAPP")
        let data = await API.graphql(graphqlOperation(query))
        console.log(data)
        if (data){
          if (data.data.listBetaTesters) {
            let items = data.data.listBetaTesters.items
            let tempRows = []
            for (let i in items) {
              tempRows.push(createData(
                items[i].id,
                items[i].name,
                items[i].email,
                items[i].subscribeProductUpdates?1:0,
                items[i].subscribeProductOfferings?1:0,
                items[i].ios?1:0,
                items[i].android?1:0,
                items[i].status
              ))
            }
            setData(tempRows)
            console.log(tempRows)
          } else {
            alert("Not connected to AWS! Talk to Dom!")
          }
        }
        return data
      } else {
        alert("Could not get Beta tester Count! Talk to Dom!")
      }
    }

    awaitData()
  }, [])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleApproval = async (id, name, email, ios, android) => {
    console.log(id)
    
    let params = {
      body: {
        id, email, name, android, ios
      }
    }

    try {
      AWSconfigure("WEBAPP")
      let data = await API.post('oslynWebappRestV1', '/beta/register', params)
      console.log(data)

      if (data.error) {alert(data.error)}
      else {
        let tempRows = rows
        for (let i in tempRows) {
          if (tempRows[i].id === id) {
            tempRows[i].statusLevel = 2
            break
          }
        }

        console.log(tempRows)
        setData([...tempRows])
      }
    } catch(err) { alert(err) }
    
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <MuiThemeProvider theme={wrapperTheme}>
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          onClick={(event) => handleClick(event, row.id)}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.id}
                      </TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="right" padding="checkbox">
                        <Checkbox checked={row.subscribeProductUpdates?true:false} disabled/>
                      </TableCell>
                      <TableCell align="right" padding="checkbox">
                        <Checkbox checked={row.subscribeProductOfferings} disabled/>
                      </TableCell>
                      <TableCell align="right" padding="checkbox">
                        <Checkbox checked={row.ios} disabled/>
                      </TableCell>
                      <TableCell align="right"padding="checkbox">
                        <Checkbox checked={row.android} disabled/>
                      </TableCell>
                      <TableCell align="right"padding="checkbox">
                        <Button className={row.statusLevel === 2?"":classes.approveButton} disabled={row.statusLevel === 2} onClick={() => {
                          handleApproval(row.id, row.name, row.email, row.ios, row.android)}}>
                          {row.statusLevel === 1? "Approve":row.statusLevel === 2?"Done":"Unknown"}
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={9} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </div>
    </MuiThemeProvider>
  );
}