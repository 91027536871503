import React, { Component, useEffect } from 'react'
import { 
  MuiThemeProvider, createMuiTheme, makeStyles, createStyles, Theme 
} from '@material-ui/core/styles'

import { Fade, Slide, IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { Alert } from '@material-ui/lab'

import Drawer from './components/App/Drawer'
import Footer from './components/App/Footer'

import './css/App.css'

import Front from './components/Front'
import Error from './components/Error'
import Demo from './components//Demo/Demo'
import Train from './components/Train/Train'
import Features from './components/Features'
import About from './components/About'
import Admin from './components/Admin'
import Privacy from './components/Privacy/Privacy'
import Unsubscribe from './components/Privacy/Unsubscribe'

import CardGiftcardIcon from '@material-ui/icons/CardGiftcard'
import CloseIcon from '@material-ui/icons/Close'

import ReactGA from 'react-ga'
import { v4 as uuidv4 } from 'uuid'
import env from './env'

ReactGA.initialize(env.GOOGL_ANALYTICS_ID)

const setGAPageView = () => {
  ReactGA.set({ page: window.location.pathname }) // Update the user's current page
  ReactGA.pageview(window.location.pathname)      // Record a pageview for the given page
  console.log(`google analytics - pageview: ${window.location.pathname}`)
}

const mainTheme = createMuiTheme({
  palette: {
    primary: { main: "#212121" },
    secondary: { main: "#ede7f6" }
  }
})

const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    alertStyle: {
      marginTop: 5,
      fontSize: 20,
      backgroundColor: "#ff8a65",
      color: "#23232d"
    },
    alertText: {
      marginTop: 5,
      cursor: "pointer",
      "&:hover": {
        cursor: "pointer"
      }
    }
  })
)

const _FIREFOX_SCROLL_WAIT = 300
const SM_SCREEN = 600

export default function App(){
  const classes = useStyles()

  const [width, setWidth] = React.useState(0)
  const [height, setHeight] = React.useState(0)
  useEffect(() => {
    updateWindowDimensions()
    window.addEventListener('resize', updateWindowDimensions)
    return () => window.removeEventListener('resize', updateWindowDimensions)
  }, [])

  const updateWindowDimensions = () => { setWidth(window.innerWidth); setHeight(window.innerHeight) }

  // page = enum( "Front", "Demo", "Train", "Features", "About", Admin, Privacy, Unsubscribe )
  const getPage = () => {
    console.log(window.location.pathname)
    setGAPageView()
    if (window.location.pathname === "/") return "Front"
    if (window.location.pathname === "/train/unsubscribe") return "UnsubscribeTeacher"
    else if (window.location.pathname === "/train") return "Train"
    else if (window.location.pathname === "/admin") return "Admin"
    else if (window.location.pathname === "/demo") return "Demo"
    else if (window.location.pathname === "/features") return "Features"
    else if (window.location.pathname === "/about") return "About"
    else if (window.location.pathname === "/privacy/unsubscribe") return "Unsubscribe"
    else if (window.location.pathname === "/privacy") return "Privacy"
    else return "Error"
  }

  const [page, setPage] = React.useState(getPage())
  const [showAlert, setShowAlert] = React.useState(true)

  const drawer = React.useRef(null)
  const front = React.useRef(null)
  const features = React.useRef(null)
  const train = React.useRef(null)
  const about = React.useRef(null)
  const unsubscribe = React.useRef(null)
  const privacy = React.useRef(null)
  const footer = React.useRef(null)

  useEffect(() => {
    setPageTitle()
  }, [])

  const goToDemo = () => {
    if (page !== "Admin") {
      setPage("Demo");
      (drawer.current as any)?.highlightDemo()
      window.history.pushState("", "Oslyn | Demo", "/demo")
      window.scrollTo(1, 1)
      setTimeout(() => { // FIX FIREFOX
        console.log(`try scroll: ${window.scrollY}`)
        if (window.scrollY > 1) window.scrollTo(0, 0)
      }, _FIREFOX_SCROLL_WAIT)
    } else {
      window.history.pushState("", "Oslyn | Demo", "/demo")
      window.location.reload()
    }
    setGAPageView()
  }
  const goToFront = () => { 
    setPage("Front");
    (drawer.current as any)?.highlightNone()
    let prevState = getPage()
    window.history.pushState("", "Oslyn | Musician at your fingertips", "/")
    window.scrollTo(0, 0)
    if (prevState === "Admin") window.location.reload()
    setGAPageView()
  }
  const goToTrain = () => { 
    if (page !== "Admin") {
      setPage("Train");
      //this.train.startPageOpen()
      (drawer.current as any)?.highlightTrain()
      window.history.pushState("", "Oslyn | Training", "/train")
      window.scrollTo(0, 0)
      setTimeout(() => { // FIX FIREFOX
        console.log(`try scroll: ${window.scrollY}`)
        if (window.scrollY > 1) window.scrollTo(0, 0)
      }, _FIREFOX_SCROLL_WAIT)
    } else {
      window.history.pushState("", "Oslyn | Training", "/train")
      window.location.reload()
    }
    setGAPageView()
  }

  // NOTE: only used during give away -- custom for Train
  const goToTrainGiveAway = () => { 
    if (page !== "Admin" && page !== "Train") {
      setPage("Train");
      (drawer.current as any)?.highlightTrain()
      window.history.pushState("", "Oslyn | Training", "/train")
      setTimeout(() => {
        console.log(`try scroll: ${window.scrollY}`);
        (train.current as any)?.goToGiveAway()
      }, _FIREFOX_SCROLL_WAIT)
    } else if (page === "Train") {
      (train.current as any)?.goToGiveAway()
    } else {
      window.history.pushState("", "Oslyn | Training", "/train")
      window.location.reload()
    }
    setGAPageView()
  }
  const goToFeatures = () => {
    if (page !== "Admin") {
      setPage("Features");
      (drawer.current as any)?.highlightFeatures()
      window.history.pushState("", "Oslyn | Features", "/features")
      window.scrollTo(0, 0)
      setTimeout(() => { // FIX FIREFOX
        console.log(`try scroll: ${window.scrollY}`)
        if (window.scrollY > 1) window.scrollTo(0, 0)
      }, _FIREFOX_SCROLL_WAIT)
    } else {
      window.history.pushState("", "Oslyn | Features", "/features")
      window.location.reload()
    }
    setGAPageView()
  }
  const goToAbout = () => {
    if (page !== "Admin") {
      setPage("About");
      (drawer.current as any)?.highlightAbout()
      window.history.pushState("", "Oslyn | About", "/about")
      window.scrollTo(0, 0)
      setTimeout(() => { // FIX FIREFOX
        console.log(`try scroll: ${window.scrollY}`)
        if (window.scrollY > 1) window.scrollTo(0, 0)
      }, _FIREFOX_SCROLL_WAIT)
    } else {
      window.history.pushState("", "Oslyn | About", "/about")
      window.location.reload()
    }
    setGAPageView()
  }
  const goToPrivacy = () => {
    if (page !== "Admin") {
      setPage("Privacy");
      (drawer.current as any)?.highlightNone()
      window.history.pushState("", "Oslyn | Privacy", "/privacy")
      window.scrollTo(0, 0)
      setTimeout(() => { // FIX FIREFOX
        console.log(`try scroll: ${window.scrollY}`)
        if (window.scrollY > 1) window.scrollTo(0, 0)
      }, _FIREFOX_SCROLL_WAIT)
    } else {
      window.history.pushState("", "Oslyn | Privacy", "/privacy")
      window.location.reload()
    }
    setGAPageView()
  }
  const gotToContactUs = () => {
    console.log("Go To About")
    if (page !== "About") {
      if (page !== "Admin") {
        setPage("About")
        window.history.pushState("", "Oslyn | About", "/about")
        setTimeout(() => {
          console.log(`try scroll: ${window.scrollY}`)
          if (window.scrollY > 1) { (about.current as any)?.scrollToContactUs() }
        }, _FIREFOX_SCROLL_WAIT)
        // this.setState({ page: "About" }, () => {
        //   //this.drawer.highlightAbout()
        //   window.history.pushState("", "Oslyn | About", "/about")
        //   setTimeout(() => {
        //     console.log(`try scroll: ${window.scrollY}`)
        //     if (window.scrollY > 1) { }//this.about.scrollToContactUs()
        //   }, _FIREFOX_SCROLL_WAIT)
        // })
      } else {
        window.history.pushState("", "Oslyn | About", "/about")
        window.location.reload()
      }
    } else {
      setTimeout(() => {
        console.log(`try scroll: ${window.scrollY}`)
        if (window.scrollY > 1) { (about.current as any)?.scrollToContactUs() } //this.about.scrollToContactUs()
      }, _FIREFOX_SCROLL_WAIT)
    }
    setGAPageView()
  }

  const scrollToDemo = () => { (front.current as any)?.scrollToDemoSection() }
  const scrollToAI = () => { (front.current as any)?.scrollToAISection() }

  const setPageTitle = () => {
    console.log(window.location.pathname)
    if (window.location.pathname === "/") document.title = "Oslyn | Your Digital Bandmate"
    else if (window.location.pathname === "/privacy")  document.title = "Oslyn | Privacy"
    else if (window.location.pathname === "/privacy/unsubscribe")  document.title = "Oslyn | Unsubscribe"
    else if (window.location.pathname === "/train")  document.title = "Oslyn | Train Oslyn"
    else if (window.location.pathname === "/train/unsubscribe") document.title = "Oslyn | Unsubscribe"
    else if (window.location.pathname === "/admin")  document.title = "Oslyn | Admin"
    else if (window.location.pathname === "/demo")  document.title = "Oslyn | Demo"
    else if (window.location.pathname === "/features")  document.title = "Oslyn | Features"
    else if (window.location.pathname === "/about")  document.title = "Oslyn | About"
    else  document.title = "Oslyn | Error"
  }

  const setLogin = () => {
    (drawer.current as any)?.setUser()
  }

  return (
    <MuiThemeProvider theme={mainTheme}>
      <BrowserRouter>
        {page !== "Admin"?
          <Drawer toFront={goToFront} 
          toDemo={goToDemo} scrollToDemo={scrollToDemo}
          toTrain={goToTrain} scrollToTrain={scrollToAI}
          toFeatures={goToFeatures} toAbout={goToAbout}
          page={page} />:
          ""}
        {/*<Slide direction="down" in={showAlert} mountOnEnter unmountOnExit 
          style={{position:"absolute", width:"100%"}} timeout={{enter: 800, exit: 1500}}>
          <Alert variant="filled" severity="success" className={classes.alertStyle} style={{
              marginTop: width <= SM_SCREEN ? 15 : 5, flexDirection: "row"
            }} 
            action={
              <IconButton style={{marginRight: 10}} onClick={() => {
                setShowAlert(false)
              }}><CloseIcon /></IconButton>
            }
            icon={<CardGiftcardIcon fontSize="inherit" style={{marginTop: 7, 
              marginLeft: width <= SM_SCREEN? 10 : 40}}/>}
              ref={instance => { }} //this.giveawayalert = instance;
          > 
            <div className={classes.alertText} onClick={() => {
              console.log("going to Give Away!")
              goToTrainGiveAway()
            }}>
              {width <= SM_SCREEN ? 
              "Check out our GIVEAWAY!" : 
              "Click here and check out our GIVEAWAY!" }
            </div>
          </Alert>
        </Slide>*/}
        <Switch>
          <Route path="/admin" exact component={() => {
            return <Admin login={ setLogin }/> }} />
          <Route path="/">
            <Fade in={page==="Front"}>
              <div style={{
                  visibility: page==="Front"?"visible":"hidden",
                  display: page==="Front"?"":"None",
                  height: page!=="Front"?0:"auto"}}>
                <Front toDemo={goToDemo} toTrain={goToTrain} ref={front}/>
              </div>
            </Fade>
            <Fade in={page==="Demo"}>
              <div style={{
                  visibility: page==="Demo"?"visible":"hidden",
                  display: page==="Demo"?"":"None",
                  height: page!=="Demo"?0:"auto"}}>
                <Demo />
              </div>
            </Fade>
            <Fade in={page==="Train"}>
              <div style={{
                  visibility: page==="Train"?"visible":"hidden",
                  display: page==="Train"?"":"None",
                  height: page!=="Train"?0:"auto"}}>
                <Train page={page} />
              </div>
            </Fade>
            <Fade in={page==="Features"}>
              <div style={{
                visibility: page==="Features"?"visible":"hidden",
                display: page==="Features"?"":"None",
                height: page!=="Features"?0:"auto"}}>
                  <Features currentPage={page} ref={features}/>
              </div>
            </Fade>
            <Fade in={page==="About"}>
              <div style={{
                visibility: page==="About"?"visible":"hidden",
                display: page==="About"?"":"None",
                height: page!=="About"?0:"auto"}}>
                  <About currentPage={page} ref={about}/>
              </div>
            </Fade>
            <Fade in={page==="Unsubscribe"}>
              <div style={{
                visibility: page==="Unsubscribe"?"visible":"hidden",
                display: page==="Unsubscribe"?"":"None",
                height: page!=="Unsubscribe"?0:"auto"}}>
                  <Unsubscribe currentPage={page} ref={unsubscribe}/>
              </div>
            </Fade>
            <Fade in={page==="UnsubscribeTeacher"}>
              <div style={{
                visibility: page==="UnsubscribeTeacher"?"visible":"hidden",
                display: page==="UnsubscribeTeacher"?"":"None",
                height: page!=="UnsubscribeTeacher"?0:"auto"}}>
                  <Unsubscribe currentPage={page} isOslynTeacher={true} ref={unsubscribe}/>
              </div>
            </Fade>
            <Fade in={page==="Privacy"}>
              <div style={{
                visibility: page==="Privacy"?"visible":"hidden",
                display: page==="Privacy"?"":"None",
                height: page!=="Privacy"?0:"auto"}}>
                  <Privacy currentPage={page} ref={privacy}/>
              </div>
            </Fade>
            <Fade in={page==="Error"} >
              <div style={{
                  visibility: page==="Error"?"visible":"hidden",
                  display: page==="Error"?"":"None",
                  height: page!=="Error"?0:"auto"}}>
                <Error />
              </div>
            </Fade>
          </Route>
        </Switch>
        {page !== "Admin"?
          <Footer toFront={goToFront} 
          toDemo={goToDemo} scrollToDemo={scrollToDemo}
          toTrain={goToTrain} scrollToTrain={scrollToAI}
          toFeatures={goToFeatures} toPrivacy={goToPrivacy}
          toAbout={goToAbout} startColor={page==="Admin"?"white":null}
          toContactUs={gotToContactUs}
          currentPage={page} ref={footer}/>:""}
      </BrowserRouter>
    </MuiThemeProvider>
  )
}
