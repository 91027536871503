import React, { useEffect } from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Title from './Title';

import { API } from 'aws-amplify'
import { AWSconfigure } from '../../../common/Configure';

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
});

export default function Deposits() {
  const classes = useStyles();
  const [count, setCount] = React.useState(0);
  let date = new Date()

  function getMonthString(date) {
    switch (date.getMonth()) {
      case 0: return "Janurary";
      case 1: return "Feburary";
      case 2: return "March";
      case 3: return "April";
      case 4: return "May";
      case 5: return "June";
      case 6: return "July";
      case 7: return "August";
      case 8: return "September";
      case 9: return "October";
      case 10: return "November";
      case 11: return "December";
      default: return "Month Not Found";
    }
  }

  useEffect(() => {
    async function awaitData() {
      AWSconfigure("WEBAPP")
      let data = await API.get('oslynWebappRestV1', '/beta/count')
      console.log(data)
      if (data){
        if (data.data.Count) {
          setCount(data.data.Count)
        } else {
          alert("Not connected to AWS! Talk to Dom!")
        }
      }
      return data
    }

    awaitData()
  }, [])

  return (
    <React.Fragment>
      <Title>Beta Tester Count</Title>
      <Typography component="p" variant="h4">
        { count }
      </Typography>
      <Typography color="textSecondary" className={classes.depositContext}>
        as of {date.getDate()} {getMonthString(date)}, {date.getFullYear()}
      </Typography>
      <div>
        <Link color="primary" href="#" onClick={preventDefault}>
          View Users
        </Link>
      </div>
    </React.Fragment>
  );
}