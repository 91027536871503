import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

import { Grid, Button, Typography, Link, SvgIcon, Divider } from '@material-ui/core'
//import SvgIcon from '@material-ui/core/svgIcon'

import oslynLogo from './../../img/logo-colour-white2.png'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="left" style={{color: "grey", paddingLeft: 5}}>
      {'© '}
      {new Date().getFullYear()}{' '}
      <span>
        Oslyn.io
      </span>
    </Typography>
  );
}

function FacebookIcon (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 320 512">
      <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 
        0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/>
    </SvgIcon>
  )
}

function InstagramIcon (props) {
  return (
    <SvgIcon {...props}>
      <path d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 
        7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,
        4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,
        7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z" />
    </SvgIcon>
  )
}

function YoutubeIcon (props) {
  return (
    <SvgIcon {...props}>
      <path d="M10,15L15.19,12L10,9V15M21.56,7.17C21.69,7.64 21.78,8.27 21.84,9.07C21.91,9.87 21.94,10.56 21.94,11.16L22,12C22,
      14.19 21.84,15.8 21.56,16.83C21.31,17.73 20.73,18.31 19.83,18.56C19.36,18.69 18.5,18.78 17.18,18.84C15.88,18.91 14.69,
      18.94 13.59,18.94L12,19C7.81,19 5.2,18.84 4.17,18.56C3.27,18.31 2.69,17.73 2.44,16.83C2.31,16.36 2.22,15.73 2.16,14.93C2.09,
      14.13 2.06,13.44 2.06,12.84L2,12C2,9.81 2.16,8.2 2.44,7.17C2.69,6.27 3.27,5.69 4.17,5.44C4.64,5.31 5.5,5.22 6.82,5.16C8.12,
      5.09 9.31,5.06 10.41,5.06L12,5C16.19,5 18.8,5.16 19.83,5.44C20.73,5.69 21.31,6.27 21.56,7.17Z" />
    </SvgIcon>
  )
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    background: "rgb(2,0,36)",
    paddingTop: 40,
    paddingBottom: 100
  },
  title: {
    color: "white"
  },
  featuresButton: {
    textTransform: "capitalize",
    color: "white",
    marginRight: 5,
  },
  demoButton: {
    textTransform: "capitalize",
    color: "white",
    marginRight: 5,
  }, 
  trainButton: {
    textTransform: "capitalize",
    color: "white",
    marginRight: 5,
  },
  aboutButton: {
    textTransform: "capitalize",
    color: "white",
    marginRight: 5,
  },
  social: {
    color: "white",
    marginRight: 15,
    "&:hover": {
      cursor: "pointer"
    }
  },
  privacyLink: {
    color: "inherit",
    "&:hover": {
      cursor: "pointer"
    }
  }
})

const SM_SCREEN = 600
const MD_SCREEN = 960
//const LG_SCREEN = 1280
//const XL_SCREEN = 1920

class Footer extends Component {

  constructor(props) {
    super(props)
    this.state = {
      width: 0, height: 0
    }

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({width: window.innerWidth, height: window.innerHeight})
  }

  render() {
    const { classes } = this.props
    // rgba(35,35,45,1)
    return (<div className={classes.root} style={{
      background: `linear-gradient(0deg, black 0%, black ${this.props.startColor?75:10}%, ${this.props.startColor||"rgba(35,35,45,1)"} 95%, ${this.props.startColor||"rgba(35,35,45,1)"} 100%)`
    }}>
      <Divider style={{color: "grey", background: "grey", height:2, borderRadius:1, marginLeft:this.state.width<MD_SCREEN?20:100, marginRight:this.state.width<MD_SCREEN?20:100}}/>
      <Grid container style={{paddingTop: 20}}>
        <Grid item xs={12} sm={4} md={4} xl={4} style={{paddingLeft: this.state.width<MD_SCREEN?20:100, 
              display:this.state.width<SM_SCREEN?"flex":"", justifyContent: this.state.width<SM_SCREEN?"center":""}}>
          <Typography component="h1" variant="h2" align="left" color="textPrimary" gutterBottom className={classes.title}>
            <img style={{height: 70, paddingTop: 20, paddingRight: 20}} src={oslynLogo} alt="Oslyn.IO" />
          </Typography>
        </Grid>
        <div style={{width: "100%", display: this.state.width<SM_SCREEN?"":"none", paddingBottom: 15}}>
          <Grid item xs={12} style={{display:"flex", justifyContent: "center", paddingBottom: 5}}>
            <Button color="inherit" className={classes.featuresButton}
                style={{
                  color: this.state.highlight === "Features"?"#ff8a65":"", 
                  fontWeight: this.state.highlight === "Features"?700:"normal",
                  fontSize: this.state.highlight === "Features"? "0.95rem":"inherit",
                  marginRight: 0
                }} onClick={this.props.toFeatures}>
              Features
            </Button>
          </Grid>
          <Grid item xs={12} style={{display:"flex", justifyContent: "center", paddingBottom: 5}}>
            <Button color="inherit" className={classes.demoButton}
                style={{
                  color: this.state.highlight === "Demo"?"#47dcff":"",
                  fontWeight: this.state.highlight === "Demo"?700:"normal",
                  fontSize: this.state.highlight === "Demo"? "0.95rem":"inherit",
                  marginRight: 0
                }} onClick={this.props.toDemo}>
              Demo
            </Button>
          </Grid>
          <Grid item xs={12} style={{display:"flex", justifyContent: "center", paddingBottom: 5}}>
            <Button color="inherit" className={classes.trainButton}
                style={{color: this.state.highlight === "Train"?"#ffc050":"",
                fontWeight: this.state.highlight === "Train"?700:"normal",
                fontSize: this.state.highlight === "Train"? "0.95rem":"inherit",
                marginRight: 0
              }} onClick={this.props.toTrain}> 
              Train Oslyn
            </Button>
          </Grid>
          <Grid item xs={12} style={{display:"flex", justifyContent: "center", paddingBottom: 5}}>
            <Button color="inherit" className={classes.aboutButton}
                style={{
                  color: this.state.highlight === "About"?"white":"",
                  fontWeight: this.state.highlight === "About"?700:"normal",
                  fontSize: this.state.highlight === "About"? "0.95rem":"inherit",
                  marginRight: 0
                }} onClick={this.props.toAbout}> 
              About
            </Button>
          </Grid>
        </div>
        <Grid item xs={12} sm={8} md={8} xl={8} style={{display: this.state.width<SM_SCREEN?"none":"flex", justifyContent: "flex-end", paddingRight: this.state.width<MD_SCREEN?20:100, paddingBottom: 20}}>
          <Button color="inherit" className={classes.featuresButton}
              style={{
                color: this.state.highlight === "Features"?"#ff8a65":"", 
                fontWeight: this.state.highlight === "Features"?700:"normal",
                fontSize: this.state.highlight === "Features"? "0.95rem":"inherit"
              }} onClick={this.props.toFeatures}>
            Features
          </Button>
          <Button color="inherit" className={classes.demoButton}
              style={{
                color: this.state.highlight === "Demo"?"#47dcff":"",
                fontWeight: this.state.highlight === "Demo"?700:"normal",
                fontSize: this.state.highlight === "Demo"? "0.95rem":"inherit"
              }} onClick={this.props.toDemo}>
            Demo
          </Button>
          <Button color="inherit" className={classes.trainButton}
              style={{color: this.state.highlight === "Train"?"#ffc050":"",
              fontWeight: this.state.highlight === "Train"?700:"normal",
              fontSize: this.state.highlight === "Train"? "0.95rem":"inherit"
            }} onClick={this.props.toTrain}> 
            Train Oslyn
          </Button>
          <Button color="inherit" className={classes.aboutButton}
              style={{
                color: this.state.highlight === "About"?"white":"",
                fontWeight: this.state.highlight === "About"?700:"normal",
                fontSize: this.state.highlight === "About"? "0.95rem":"inherit"
              }} onClick={this.props.toAbout}> 
            About
          </Button>
        </Grid>
        <Grid item xs={12} style={{paddingTop: 0, display: this.state.width<SM_SCREEN?"flex":"none", justifyContent: "center", paddingBottom:20}}>
          <FacebookIcon className={classes.social} onClick={() => {
            window.open("https://www.facebook.com/oslynio", "_blank")
          }}/>
          <InstagramIcon className={classes.social} onClick={() => {
            window.open("https://www.instagram.com/oslyn.io/", "_blank")
          }}/>
          <YoutubeIcon className={classes.social} onClick={() => {
            window.open("https://www.youtube.com/channel/UC4Tw6vjt-iCG8Ke90KO8lJw", "_blank")
          }}/>
        </Grid>
        <Grid item xs={12} sm={4} xl={4} style={{display: this.state.width<SM_SCREEN?"flex":"", justifyContent:"center", 
              paddingLeft: this.state.width<MD_SCREEN?this.state.width<SM_SCREEN?0:20:100, paddingTop: 0}}>
          <div>
            <Copyright />
            <Typography style={{color: "grey"}}>
              <Link onClick={this.props.toPrivacy} className={classes.privacyLink}>
                Privacy
              </Link>&nbsp;|&nbsp;
              <Link onClick={this.props.toContactUs} className={classes.privacyLink}>
                Contact
              </Link>
            </Typography>
          </div>
        </Grid>
        <Grid item sm={8} xl={8} style={{paddingRight: this.state.width<MD_SCREEN?20:100, paddingTop: 0, 
          display: this.state.width<SM_SCREEN?"none":"flex", justifyContent: "flex-end", alignItems: "flex-start"}}>
          <FacebookIcon className={classes.social} onClick={() => {
            window.open("https://www.facebook.com/oslynio", "_blank")
          }}/>
          <InstagramIcon className={classes.social} onClick={() => {
            window.open("https://www.instagram.com/oslyn.io/", "_blank")
          }}/>
          <YoutubeIcon className={classes.social} onClick={() => {
            window.open("https://www.youtube.com/channel/UC4Tw6vjt-iCG8Ke90KO8lJw", "_blank")
          }}/>
        </Grid>
      </Grid>
    </div>)
  }
}

export default withStyles(styles, { withTheme: true })(Footer)