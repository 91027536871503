import React, { useEffect } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import phoneinactive from '../../img/Oslynphonemockup_soundwave_inactive.png'
import phoneactive from '../../img/Oslynphonemockup_soundwave_active.png'
import * as tf from '@tensorflow/tfjs'

import { 
  Grid, Button,Container, Typography, FormControl, InputLabel,
  Select, MenuItem, TextField
} from '@material-ui/core'
import ReactPlayer from "react-player"

import Amplify, { API, graphqlOperation } from 'aws-amplify'
import { GraphQLResult } from "@aws-amplify/api"
import AWS from 'aws-sdk'

import StopIcon from '@material-ui/icons/Stop'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'

import Subscribe from '../Privacy/Subscribe'
import env from '../../env'
import awsExports from '../../aws-exports-studio'
import { AWSconfigure } from '../../common/Configure'

const SM_SCREEN = 600
const MD_SCREEN = 960
const LG_SCREEN = 1280
const XL_SCREEN = 1920

const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    root: {
      flexGrow: 1,
    },
    heroButtons2:{
      marginTop: theme.spacing(7),
      display: "flex",
      justifyContent: "center",
    }, 
    icondemo: {
  //   fontSize: "35px !important"
    },
    ButtonSM: {
      color: "white",
      borderColor: "white",
      border: "solid",
      borderWidth: 1,
      
      textTransform: "none",
      borderRadius: 50,
      display: "relative",
      paddingBottom:10,
      paddingTop: 10,
      "&:hover": {
        borderColor: "rgba(101,31,255,1) !important",
        color: "rgba(101,31,255,1)!important"
      }
    },
    activeSM:{
      color: "white",
      textTransform: "none",
      display: "relative",
    },
    heroButtons: {
      marginTop: theme.spacing(4),
      align: "center",
      justifyContent:"center",
    },
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6)
    },
    demoSection: {
      background: "#3d3f4b"
    },
    demoSectionTitle: {
      color: "white", //light-blue
      fontSize: 40
    },
    demoSectionParagraph: {
      color: "white",
      fontSize: 19
    }, 
    demophonebutton:{
      color: "white",
      borderColor: "white",
      testAlignVertical: "middle",
      borderRadius: 50,
      position: 'relative',
      margin:2,
      paddingBottom:10,
      paddingTop: 10,
      "&:hover": {
        borderColor: "rgba(101,31,255,1) !important",
        color: "rgba(101,31,255,1)!important"
      }
    },
    chord:{
      color: "white",
      border: "none",
      position: 'relative',
    },
    subscribeSection: {
      background: "rgba(35,35,45,1)",
      paddingTop: 0
    },
    videoSection: {
      background: "rgba(35,35,45,1)", //rgb(89,91,103)
      paddingTop: 0
    },
    featureSectionParagraph: {
      color: "white",
      fontSize: 19,
      marginBottom: 20
    },
    subscribeSectionTitle: {
      color: "white",
      fontSize: 40
    },
    downloadBetaSecionParagraph: {
      color: "#eeeeee",
      fontSize: 10,
      marginBottom: 20,
      padding: 20
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    
    
    selectEmpty: {
      marginTop: theme.spacing(2),
    },

    // Select Hacks
    select: {
      "& .MuiOutlinedInput-input": {
        color: "white !important"
      },
      "& .MuiOutlinedInput-input.Mui-disabled": {
        color: "grey !important"
      },
      "& .MuiInputLabel-root": {
        color: "white !important"
      },
      "& .MuiSelect-root.MuiSelect-select .MuiOutlinedInput-notchedOutline": {
        borderColor: "white !important"
      },
    },
    selectNochedOutline: {
      borderWidth: '1px',
      borderColor: '#ff8a65 !important'
    },
    selectIcon: {
      fill: "white"
    },
    selectIconDisabled: {
      fill: "grey"
    },

    firstForTextGrid: {
      paddingRight: 10,
      paddingLeft: 10
    },

    // New Select
    selectRoot: {
      color: "white !important"
    },

    // TextBoxes
    cssLabel: {
      color : '#eeeeee' // changes the lable color //#ff8a65
    },
    cssOutlinedInput: {
      '&$cssFocused $notchedOutline': { // has something to do with the label when filling it out
        borderColor: `#ff8a65 !important`,
      }
    },
    cssFocused: {
      borderColor: '#ff8a65 !important'
    },
    notchedOutline: {
      borderWidth: '1px',
      borderColor: '#ff8a65 !important'
    },

  })
)

const estimation_stickiness = 5
const confidence_cuttoff = 0.8
// const cmap = { 
//   0: "None", 1:"A", 2:"Am", 3:"A#/Bb", 4:"A#m/Bbm", 5:"B", 
//   6:"Bm", 7:"C", 8:"Cm", 9:"C#/Db", 10:"C#m/Dbm", 11:"D", 
//   12:"Dm", 13:"D#/Eb", 14:"D#m/Ebm", 15: "E", 16: "Em",
//   17:"F", 18:"Fm", 19:"F#/Gb", 20:"F#m/Gbm", 21:"G", 22:"Gm",
//   23:"G#/Ab", 24:"G#m/Abm"
// }

const cmap = { 
  0:"C", 1:"Cm", 2:"C#/Db", 3:"C#m/Dbm", 4:"D", 
  5:"Dm", 6:"D#/Eb", 7:"D#m/Ebm", 8: "E", 9: "Em",
  10:"F", 11:"Fm", 12:"F#/Gb", 13:"F#m/Gbm", 14:"G", 15:"Gm",
  16:"G#/Ab", 17:"G#m/Abm", 18:"A", 19:"Am", 20:"A#/Bb", 
  21:"A#m/Bbm", 22:"B", 23:"Bm", 24: "None"
} as { [key: number]: string }

// Should this be provided dynamically?
const modelChordPositionMap = {
  0 : { m: "C",   s: "C"  },
  1 : { m: "Cm",  s: "Cm" },
  2 : { m: "C#",  s: "Cs" },
  3 : { m: "C#m", s: "Csm" },
  4 : { m: "D",   s: "D" },
  5 : { m: "Dm",  s: "Dm" },
  6 : { m: "D#",  s: "Ds" },
  7 : { m: "D#m", s: "Dsm" },
  8 : { m: "E",   s: "E" },
  9 : { m: "Em",  s: "Em" },
  10: { m: "F",   s: "F" },
  11: { m: "Fm",  s: "Fm" },
  12: { m: "F#",  s: "Fs" },
  13: { m: "F#m", s: "Fsm" },
  14: { m: "G",   s: "G" },
  15: { m: "Gm",  s: "Gm" },
  16: { m: "G#",  s: "Gs" },
  17: { m: "G#m", s: "Gsm" },
  18: { m: "A",   s: "A" },
  19: { m: "Am",  s: "Am" },
  20: { m: "Bb",  s: "Bb" },
  21: { m: "Bbm", s: "Bbm" },
  22: { m: "B",   s: "B" },
  23: { m: "Bm",  s: "Bm" },
  24: { m: "None", s: null },
  25: { m: "Has_Next", s: null },
  26: { m: "C",   s: "C" },
  27: { m: "Cm",  s: "Cm" },
  28: { m: "C#",  s: "Cs" },
  29: { m: "C#m", s: "Csm" },
  30: { m: "D",   s: "D" },
  31: { m: "Dm",  s: "Dm" },
  32: { m: "D#",  s: "Ds" },
  33: { m: "D#m", s: "Dsm" },
  34 : { m: "E",  s: "E" },
  35 : { m: "Em", s: "Em" },
  36: { m: "F",   s: "F" },
  37: { m: "Fm",  s: "Fm" },
  38: { m: "F#",  s: "Fs" },
  39: { m: "F#m", s: "Fsm" },
  40: { m: "G",   s: "G" },
  41: { m: "Gm",  s: "Gm" },
  42: { m: "G#",  s: "Gs" },
  43: { m: "G#m", s: "Gsm" },
  44: { m: "A",   s: "A" },
  45: { m: "Am",  s: "Am" },
  46: { m: "Bb",  s: "Bb" },
  47: { m: "Bbm", s: "Bbm" },
  48: { m: "B",   s: "B" },
  49: { m: "Bm",  s: "Bm" },
  50: { m: "None", s: null },
  51: { m: "Time_To_Next", s: null },
}

//const modelIterationId = "35c19959-a651-4e28-a975-cda3def322ac"
//const modelIterationId = "df9246a0-1456-4877-bcc5-af759f3a1a85"
//const modelIterationId = "305e1dd6-42ec-41b0-86d9-9ab61021f922"

interface ModelType {
  visibleName: string,
  modelName: string,
  modelIterationId: string
}

const testableModels = [
  {
    visibleName: "Feed Forward Net v0.1b",
    modelName: "cnn-chord-detector",
    modelIterationId: "1c2ae6f0-7433-4ce3-b396-55fa8c632ba0",
    description: "Trained for 17hrs on 340 songs, with a sample density per chord of 3. 10 epochs per song with a final loss of 0.0241."
  },
  {
    visibleName: "Feed Forward Net v0.2b",
    modelName: "cnn-chord-detector",
    modelIterationId: "3d683573-8832-4ed8-995e-de94c64f302d",
    description: "Trained for 8hrs on 360 songs, with a sample density per chord of 1. 10 epochs / 3 batchs per song with a final loss of 0.0241. Balanced "
  },
  {
    visibleName: "Recurrent Neural Net v0.0.1",
    modelName: "chord-detector",
    modelIterationId: "305e1dd6-42ec-41b0-86d9-9ab61021f922"
  },
] as ModelType[]

interface AudioProps {
  audioCtx: AudioContext|null,
  source: MediaStreamAudioSourceNode|null,
  scriptNode: ScriptProcessorNode|null,
}

export default function Demo () {
  const classes = useStyles()

  const [width, setWidth] = React.useState(0)
  const [height, setHeight] = React.useState(0)

  const [audio, setAudio] = React.useState<MediaStream|null>(null)
  const [audioProps, setAudioProps] = React.useState<AudioProps>({
    audioCtx: null, source: null, scriptNode: null
  })

  //const [audioCtx, setAudioCtx] = React.useState<AudioContext|null>(null)
  
  const [chord, setChord] = React.useState("None")

  const [model, setModel] = React.useState<tf.LayersModel|null>(null)
  const [modelInfo, setModelInfo] = React.useState<any>(null)
  
  //const [cellStates, setCellStates] = React.useState<tf.Tensor2D> (tf.tensor2d([new Array(400).fill(0)], [1, 400]))
  //const [hiddenStates, setHiddenStates] = React.useState<tf.Tensor2D> (tf.tensor2d([new Array(400).fill(0)], [1, 400]))

  const [soundLibrary, setSoundLibrary] = React.useState({})
  const [modelChoice, setModelChoice] = React.useState(0)

  useEffect(() => {
    updateWindowDimensions()
    window.addEventListener('resize', updateWindowDimensions)

    return () => window.removeEventListener('resize', updateWindowDimensions)
  }, [])

  useEffect(() => {
    console.log("Fetching Model ..")
    getModel(testableModels[modelChoice].modelName , testableModels[modelChoice].modelIterationId).then( model => {
      console.log(`SETTING MODEL`)
      setModel(model)
    })

    setModelInfo( getModelParams(testableModels[modelChoice].modelIterationId) )
  }, [modelChoice])

  const updateWindowDimensions = () => { setWidth(window.innerWidth); setHeight(window.innerHeight) }

  const getModel = async (modelType: string, modelIterationId: string) => {
    setModel(null)
    try {
      // FOR NOW, lets hard code a model from S3
      let objectURL = `https://oslyn-studio-pipeline.s3.ca-central-1.amazonaws.com/public/_models/${modelType}/${modelIterationId}/tensorflowjs/model.json`
      let model = await tf.loadLayersModel(objectURL, {fetchFunc: s3ObjGetter})
      console.log(model)

      return model
    } catch (e) {
      console.log(e)
      return null
    }
  }

  const s3ObjGetter = (URL: string):Promise<Response> => {
    return new Promise( async resolve => {
      const s3 = new AWS.S3({
        accessKeyId: env.s3.accessKeyId,
        secretAccessKey: env.s3.secretAccessKey,
        region: 'ca-central-1',
        signatureVersion: 'v4'
      })

      console.log(`S3 URL: ${URL}`)
      
      let bucket =  URL.split("https://")[1].split(".s3.")[0]
      let key = URL.split('.com/')[1]

      let signedurl = s3.getSignedUrl('getObject', {
        Bucket: bucket,
        Key: key
      })

      resolve( await fetch(signedurl) )
    })
  }

  const getModelParams = (id: string): Promise<any> => {
    return new Promise<any> (async resolve => {
      
      let query = `query getModelIteration{
        getModelIteration(
          id: "${id}"
        ) {
          id creationDate outputLabels 
          percentComplete status version
        }
      }`
  
      try {
        AWSconfigure("STUDIO")
        let data = ( await API.graphql(graphqlOperation(query))) as GraphQLResult<any>
        let item = data ? data.data ? data.data.getModelIteration : null : null

        console.log(data)
        if (item) { resolve(item) } 
        else resolve(null)
      } catch (e) { 
        console.log(e)
        resolve(null)
      }
    })
  }

  const getMicrophone = async (modelName: string, model: tf.LayersModel) => {
    // needs to be SSL to work on other devices other than localhost
    navigator.mediaDevices.getUserMedia({
      audio: true, video: false
    }).then((stream: MediaStream) => {
      setAudio(stream)
      console.log(stream)

      let audioContext = new (window.AudioContext || (window as any).webkitAudioContext)({
         sampleRate: 44100 // https://developer.mozilla.org/en-US/docs/Web/API/AudioContext/AudioContext
      })

      let source = audioContext.createMediaStreamSource( stream )
      let scriptNode = audioContext.createScriptProcessor(8192, 1, 1)
      
      source.connect(scriptNode)
      setAudioProps({ source, scriptNode, audioCtx: audioContext })

      if (modelName === 'chord-detector') {
        var hiddenState = tf.tensor2d([new Array(400).fill(0)], [1, 400])
        var cellState = tf.tensor2d([new Array(400).fill(0)], [1, 400])

        // NOTE: This is still OK in 2021 (Audio Worklet is still not well supported by React)
        scriptNode.onaudioprocess = async (e) => {
          if (model) {
            let rawAudio = e.inputBuffer.getChannelData(0) 

            try {
              let shape = [1, 1, 8192] as [number, number, number]
              let input = tf.tensor3d([[rawAudio as any]], shape, 'float32')

              hiddenState.array().then((x) => console.log(x))
              let output = await setChordDataRNN(model.predict([input, hiddenState, cellState ]))
              hiddenState = output.hiddenState
              cellState = output.cellState
            } catch (e) {
              console.error(e); alert(e)
            }
            
          } else { alert("Model is not available/loaded properly.") }
        }

        // https://github.com/WebAudio/web-audio-api/issues/345 - set up a dummy destination node?
        scriptNode.connect(audioContext.destination)
        console.log("connected.")
      } else if (modelName === 'cnn-chord-detector') {
        scriptNode.onaudioprocess = async (e) => {
          if (model) {
            let rawAudio = e.inputBuffer.getChannelData(0)

            try {
              let input = tf.tensor2d([rawAudio as any])
              setChordDataCNN(model.predict([input]))
            } catch (e) {
              console.log(e); alert(e)
            }
          }
        }

        scriptNode.connect(audioContext.destination)
        console.log("connected.")
      } else alert(`No such model: ${modelName}`)
    })
  }

  const setChordDataRNN = async (tensor: tf.Tensor|tf.Tensor[]): Promise<{ hiddenState: tf.Tensor2D, cellState: tf.Tensor2D }> => {
    return new Promise(async (resolve) => {
      let out = (tensor as tf.Tensor[])[0].squeeze() // dim 152

      let cellState = (tensor as tf.Tensor[])[1] as tf.Tensor2D
      let hiddenState = (tensor as tf.Tensor[])[2] as tf.Tensor2D

      let currentChord = out.slice(0, 25) // start, size
      let ccIndex = (await (tf.topk(currentChord).indices as tf.Tensor).array() as number[])[0]

      if (ccIndex >= 0 && ccIndex <= 24) setChord(cmap[ccIndex])
      else console.warn(`ccIndex is not 0-24; ccIndex = ${ccIndex}`)
      resolve({ hiddenState, cellState })
    })
  }

  const setChordDataCNN = async (tensor: tf.Tensor|tf.Tensor[]): Promise<boolean> => {
    return new Promise(async (resolve) => {
      let out = (tensor as tf.Tensor).squeeze()
      //let currentChord = out.slice(0, 25)
      let ccIndex =  (await (tf.topk(out).indices as tf.Tensor).array() as number[])[0]
      console.log(`ccIndex = ${ccIndex}`)

      if (ccIndex >= 0 && ccIndex <= 24) setChord(cmap[ccIndex])
      else console.warn(`ccIndex is not 0-24; ccIndex = ${ccIndex}`)
      resolve(true)
    })
  }

  const stopMicrophone = () => {
    if (audio && audioProps.audioCtx && audioProps.scriptNode && audioProps.source) {
      audio.getTracks().forEach(track => track.stop())
      audioProps.source.disconnect(audioProps.scriptNode)
      audioProps.scriptNode.disconnect(audioProps.audioCtx.destination)
      //if (currentGainNode) currentGainNode.gain.exponentialRampToValueAtTime(0.0000001, this.audioContext.currentTime+30)
      setAudio(null)
      setAudioProps({audioCtx: null, scriptNode: null, source: null})
    } else { alert("Unable to Stop Audio.") }
  }

  const togglePlayStop = ( modelName: string, model: tf.LayersModel|null ) => {
    console.log(audio? "Audio available": "Audio NOT available")
    if (!audio && model) getMicrophone(modelName, model)
    else stopMicrophone()
  }

  return (
    <div className={classes.root}>
      <Grid container style={{ width: "100%" }} className={classes.demoSection} > {/** ref={this.demoSectionRef} */}
        <Grid item xs={false} sm={2} xl={1} style={{ display: width < 40000 && width > MD_SCREEN ? "none" : "inherit", background: width < MD_SCREEN ? "rgba(35,35,45,1)" : "" }} />
        <Grid item xs={12} sm={8} md={6} lg={6} xl={5} style={{
          background: width < MD_SCREEN ? "rgba(35,35,45,1)" : "",
          paddingRight: width < SM_SCREEN ? 10 : "",
          paddingLeft: width < SM_SCREEN ? 10 : "",
        }}>
          <Grid item xs={false} xl={1} />
          <Container maxWidth="md" style={{
            paddingTop: 200, paddingBottom: 100,
           paddingLeft: width>MD_SCREEN?120:""
          }}>
            <Typography component="h1" variant="h2" align="left" color="textPrimary" gutterBottom className={classes.demoSectionTitle}
              style={{ textAlign: width < MD_SCREEN ? "center" : "left", fontSize: width < SM_SCREEN ? 30 : "" }}>
              Give Oslyn <br />
                an audition.
            </Typography>
            { audio == null ?
              <Typography component="h5" align="left" color="textSecondary" className={classes.demoSectionParagraph}
                style={{ textAlign: width < MD_SCREEN ? "center" : "left", fontSize: width < SM_SCREEN ? 15 : "" }}>
                  Press “Start” and play your guitar <br />
                  to see how Oslyn responds.
              </Typography>: 
              <Typography component="h5" align="left" color="textSecondary" className={classes.demoSectionParagraph}
                style={{ textAlign: width < MD_SCREEN ? "center" : "left", fontSize: width < SM_SCREEN ? 15 : "" }}>
                  Press the chord in the phone <br />
                  screen to stop the demo.
              </Typography>
            }
            <Typography component="h5" align="left" color="textSecondary" className={classes.demoSectionParagraph}
              style={{ 
                textAlign: width < MD_SCREEN ? "center" : "left", fontSize: width < SM_SCREEN ? 12 : 15, 
                paddingTop: 40, paddingBottom: 5 
              }}>
                Choose an AI model:
            </Typography>
            <div style={{display: 'flex', width:"100%", justifyContent: width < MD_SCREEN ? "center": "left"}}>
              <TextField className={classes.select}
                id="demo-simple-select-outlined"
                value={modelChoice}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  setModelChoice( parseInt(event.target.value as string) )
                }}
                label=""
                variant="outlined"
                select
                disabled={model?false:true}
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
                SelectProps={{
                  classes: {
                    icon: model ? classes.selectIcon : classes.selectIconDisabled,
                    root: classes.selectRoot
                  }
                }}
              >
                { testableModels.map((model, index) => {
                  return <MenuItem key={index} value={index}>{model.visibleName}</MenuItem>
                }) }
              </TextField>
            </div>
          </Container>
        </Grid>
        <Grid item xs={false} xl={1}/>
        <Grid item md={6} xl={4} style={{ display: width < MD_SCREEN ? "none" : "inherit" }}>
          <img src={audio==null? phoneinactive: phoneactive} alt="oslyn half phone" style={{ width: (width < 1224)?470:600,height: (width < 1224)?600:730, marginTop: 50, marginRight: -210 }} /> {/** use (marginBottom -5) to fix visual bug - Dom*/}
          <div className={classes.heroButtons}>{ 
            audio == null ?
                  <Button
                    className={classes.demophonebutton}
                    variant="outlined" size= "large" color="primary"
                    onClick={() => togglePlayStop(testableModels[modelChoice].modelName, model)} 
                    style={{
                      left:(width < 1224)?-130 :-215,
                      top:(width < 1224)? 390:480, 
                      height: (width < 1224)?60:70, 
                      width:(width < 1224)?180: 210, 
                      fontSize:(width < 1224)?32: 38, 
                      lineHeight:'100%',
                      textAlign:'center',
                    }} disabled={model ? false : true}
                    startIcon={
                      <PlayCircleFilledIcon 
                        style ={{width:(width < 1224)?28: 35,marginRight:5,height:(width < 1224)? 28:35}}
                      />
                    }>Start&nbsp;</Button>
                    :
                    <Button color="primary" size= {"large"}  onClick={() => togglePlayStop(testableModels[modelChoice].modelName, model)} 
                    style={{left:(width < 1224)?-122 :-210,top:(width < 1224)? 390:480, height: (width < 1224)?60:70, width:(width < 1224)?170: 210, fontSize:(width < 1224)?50: 70}} 
                      className={classes.chord}>
                    {chord} </Button>
            }
          </div>
        </Grid>
        <Grid item xs={false} sm={2} lg={false} style={{ background: width < MD_SCREEN ? "rgba(35,35,45,1)" : "" }} />
        <Grid item xs={12} style={{ display: width > MD_SCREEN ? "none" : "flex", justifyContent: "center" }}>
          <div>
          <div className={classes.heroButtons} style ={{ marginRight: 30,padding: 0, display:"flex", marginTop:-50, justifyContent:"center" }}>
            <Grid item>{ audio == null ?
                <div className={classes.heroButtons2}>
                  <Button variant="outlined" color="primary" className={classes.ButtonSM}
                    onClick={() => togglePlayStop(testableModels[modelChoice].modelName, model)}
                    disabled={model ? false : true}
                    startIcon={
                      <PlayCircleFilledIcon className={classes.icondemo} 
                        style ={{fontSize:(width < 676)?width/19.5:35}}/>
                      }
                    style = {{ margin:50,width:(width < 676)? width/3.1:220, height:(width < 676)? width/9.7:70,
                      fontSize:(width < 676)?width/17: 40 , top:width<660?width - 90:600, lineHeight:'100%',textAlign:'center' }} 
                  >START&nbsp;</Button>
                </div>:
                     <div className={classes.heroButtons2}>
                     <Button  color="primary" className={classes.activeSM}
                       onClick={() => togglePlayStop(testableModels[modelChoice].modelName, model)}
                       style = {{ width:(width < 676)? width/3.1:220,            
                    margin:50,lineHeight:'100%',textAlign:'center', height:(width < 676)? width/9.7:70,fontSize:(width < 676)? width/13:61, 
                    top:width<660?width - 90:600,padding:0, borderRadius:50 }}
                     >{chord}
                     </Button>
                   </div>
                  }
              </Grid>
            </div>
             <img src={audio==null? phoneinactive: phoneactive} alt="oslyn half phone" style={{width: width<660?600-(660-width):660, maxWidth:width-20}} /> {/** use (marginBottom -5) to fix visual bug - Dom*/}
            </div>
        </Grid>
        <Grid container style={{width: "100%"}} className={classes.videoSection}>
          <Grid item xs={12}>
            <Container maxWidth="md" style={{paddingTop: 140, paddingBottom: 30}}>
              <div>
                <ReactPlayer width={width>912? 912 : width-60}
                  url="https://www.youtube.com/watch?v=xIanjNvcBSs"
                />
              </div>
            </Container>
          </Grid>
        </Grid>
        <Grid container style={{width: "100%"}} className={classes.subscribeSection}> {/** ref={this.subscribeSectionRef} */}
          <Grid item xs={12}>
            <Container maxWidth="sm" style={{paddingTop: 140, paddingBottom: 30}}>
              <Typography component="h1" variant="h2" align="left" color="textPrimary" className={classes.subscribeSectionTitle}
                style={{textAlign: "center", fontSize:width<SM_SCREEN?30:"", paddingBottom:20}} gutterBottom>
                Download Oslyn Alpha
              </Typography>
              <Typography component="h5" align="left" color="textSecondary" className={classes.featureSectionParagraph}
                  style={{textAlign: "center", fontSize:width<SM_SCREEN?15:""}}>
                We are alpha testing Oslyn. Sign up to download Oslyn and enroll in the testing programme for Android and/or iOS. 
              </Typography>
              <Typography component="h5" align="left" color="textSecondary" className={classes.featureSectionParagraph}
                  style={{textAlign: "center", fontSize:width<SM_SCREEN?13:15, color: "#eeeeee"}}>
                Note: A Gmail email address must be used to sign up for Android. 
              </Typography>
              <Subscribe />
              <Typography component="h5" align="left" color="textSecondary" className={classes.downloadBetaSecionParagraph} 
                  style={{textAlign: "center", fontSize:width<SM_SCREEN?9:10}}>
                By becoming a alpha tester, you agree to receive emails from Oslyn about updates and surveys to help us improve. 
                You can unsubscribe anytime by updating your email preferences via a link in the footer of our emails.
              </Typography>
            </Container>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}