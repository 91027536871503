import Amplify from "@aws-amplify/core"

import studioBEconfig from "../aws-exports-studio"
import webappBEconfig from "../aws-exports"

export const AWSconfigure = (be: string) => {
  switch(be) {
    case "WEBAPP": Amplify.configure(webappBEconfig); break;
    case "STUDIO": Amplify.configure(studioBEconfig); break;
    default: console.warn("NO AWS CONFIGURATION"); break;
  }
}