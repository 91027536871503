import React, { useEffect } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { loadCSS } from 'fg-loadcss';

import { CssBaseline, AppBar, Toolbar, Typography, useScrollTrigger, Slide,
          Button, IconButton, Icon, SwipeableDrawer, Divider,
          List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'

import oslynLogo from './../../img/logo-colour-white2.png'
import InfoIcon from '@material-ui/icons/Info'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import MenuIcon from '@material-ui/icons/Menu'
import StarIcon from '@material-ui/icons/Star'

import { Auth } from 'aws-amplify'

loadCSS(
  'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
  document.querySelector('#font-awesome-css') as HTMLElement,
);

const anchor = 'right'
const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    titleLogo: {
      height: 60, paddingTop: 20, paddingLeft: 20,
      "&:hover": {
        cursor: "pointer"
      },
    },
    featuresButton: {
      textTransform: "capitalize",
      marginRight: 5,
    },
    demoButton: {
      textTransform: "capitalize",
      marginRight: 5,
    }, 
    trainButton: {
      textTransform: "capitalize",
      marginRight: 5,
    },
    aboutButton: {
      textTransform: "capitalize",
      marginRight: 5,
    },
    list: {
      width: 250,
    },
    drawerPaper: {
      background: "linear-gradient(90deg, black 0%, rgba(35,35,45,1) 100%)"
    },
    drawerButton: {
      color: "white"
    }
  })
)

const _SM = 768

interface Props {window?: () => Window; children: React.ReactElement}

function HideOnScroll(props: Props) {
  const { children, window } = props
  const trigger = useScrollTrigger({ target: window ? window() : undefined})

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

export interface DrawerProps {
  page: string

  toFront: () => void
  toFeatures: () => void
  toDemo: () => void
  toTrain: () => void
  toAbout: () => void

  scrollToDemo: () => void
  scrollToTrain: () => void
}

export default function Drawer(props: DrawerProps) {
  const classes = useStyles()
  const { 
    page, scrollToDemo, scrollToTrain,
    toFront, toFeatures, toDemo, toTrain, toAbout
  } = props

  const [width, setWidth] = React.useState(0)
  const [height, setHeight] = React.useState(0)
  const [adminUser, setAdminUser] = React.useState<{username: string}|null>(null)
  useEffect(() => {
    checkAdminUser()
    updateWindowDimensions()
    window.addEventListener('resize', updateWindowDimensions)
    return () => window.removeEventListener('resize', updateWindowDimensions)
  }, [page])

  const updateWindowDimensions = () => { setWidth(window.innerWidth); setHeight(window.innerHeight) }
  const checkAdminUser = async () => {
    if (page === "Admin") {
      try {
        const user = await Auth.currentAuthenticatedUser()
        setAdminUser(user)
        console.log(user)
      } catch(error) {
        console.log(error)
      }
    }
  }

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false)
  const openDrawer = () => { setIsDrawerOpen(true) }
  const closeDrawer = () => { setIsDrawerOpen(false) }

  return (<div>
    <React.Fragment>
      <CssBaseline />
      <HideOnScroll {...props} >
        <AppBar>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              <img className={classes.titleLogo} onClick={toFront} src={oslynLogo} alt="Oslyn.IO" />
            </Typography>
            <div className={classes.root} />
            {
              page !== "Admin" ? 
                width>_SM?                
                  <div >
                    <Button color="inherit" className={classes.featuresButton}
                        style={{
                          color: page === "Features"?"#ff8a65":"", 
                          fontWeight: page === "Features"?700:"normal",
                          fontSize: page === "Features"? "0.95rem":"inherit"
                        }} onClick={toFeatures}>
                      Features
                    </Button>
                    <Button color="inherit" className={classes.demoButton}
                        style={{
                          color: page === "Demo"?"#47dcff":"",
                          fontWeight: page === "Demo"?700:"normal",
                          fontSize: page === "Demo"? "0.95rem":"inherit"
                        }} onClick={page==="Front"?scrollToDemo:toDemo}>
                      Demo
                    </Button>
                    <Button color="inherit" className={classes.trainButton}
                        style={{color: page === "Train"?"#ffc050":"",
                        fontWeight: page === "Train"?700:"normal",
                        fontSize: page === "Train"? "0.95rem":"inherit"
                      }} onClick={page==="Front"?scrollToTrain:toTrain}> 
                      Train Oslyn
                    </Button>
                    <Button color="inherit" className={classes.aboutButton}
                        style={{
                          color: page === "About"?"white":"",
                          fontWeight: page === "About"?700:"normal",
                          fontSize: page === "About"? "0.95rem":"inherit"
                        }} onClick={toAbout}> 
                      About
                    </Button>
                  </div> :
                  <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu"
                        onClick={openDrawer}>
                    <MenuIcon />
                  </IconButton>:
              adminUser ?
              <div>
                <Button color="inherit">{adminUser?.username}</Button>
                <IconButton color="inherit"
                  onClick={() => {
                    Auth.signOut()
                      .then((data) => {
                        console.log(data)
                        setAdminUser(null)
                        // set admin user page = null
                        window.location.reload()
                      })
                      .catch((error) => {
                        console.log(error)
                      })
                  }}
                ><ExitToAppIcon /></IconButton>
              </div>:
              <div>
                <Button color="inherit">Login</Button>
              </div>
            }
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
    </React.Fragment>

    <React.Fragment key={anchor}>
      <SwipeableDrawer
        anchor={anchor}
        open={isDrawerOpen}
        onClose={closeDrawer}
        onOpen={openDrawer}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div
          className={classes.list}
          role="presentation"
          onClick={closeDrawer}
          onKeyDown={closeDrawer}
        >
          <List >
            <ListItem button key={"Features"} className={classes.drawerButton}
                onClick={toFeatures}>
              <ListItemIcon className={classes.drawerButton}>
                <StarIcon />
              </ListItemIcon>
              <ListItemText primary={"Features"} className={classes.drawerButton}/>
            </ListItem>
            <ListItem button key={"Demo"} className={classes.drawerButton}
                onClick={toDemo}>
              <ListItemIcon className={classes.drawerButton}>
                <Icon className="fas fa-guitar"/> 
              </ListItemIcon>
              <ListItemText primary={"Demo"} className={classes.drawerButton}/>
            </ListItem>
            <ListItem button key={"Train"} className={classes.drawerButton}
                onClick={toTrain}>
              <ListItemIcon className={classes.drawerButton}>
                <Icon className="fas fa-caret-square-right"/>
              </ListItemIcon>
              <ListItemText primary={"Train Oslyn"} className={classes.drawerButton}/>
            </ListItem>
            <Divider style={{background: "grey"}} />
            <ListItem button key={"About"} className={classes.drawerButton}
                onClick={toAbout}>
              <ListItemIcon className={classes.drawerButton}>
                <InfoIcon />
              </ListItemIcon>
              <ListItemText primary={"About"} className={classes.drawerButton}/>
            </ListItem>
          </List>
        </div>
      </SwipeableDrawer>
    </React.Fragment>
  </div>)
}