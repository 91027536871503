import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

//import Amplify, { API } from 'aws-amplify'
//import awsExports from "./aws-exports"
import env from './env'

//Amplify.configure(awsExports)
//API.configure(awsExports) // needed?

console.log(`%c
 .88888.         dP                      oo         
d8'   \`8b        88                                 
88     88.d8888b.88dP    dP88d888b.      dP.d8888b. 
88     88Y8ooooo.8888    8888'  \`88      8888'  \`88 
Y8.   .8P      888888.  .8888    88  dP  8888.  .88 
 \`8888P' \`88888P'dP\`8888P88dP    dP  88  dP\`88888P' 
                        .88                         
                    d8888P `, "background: #fffff; color: #652fff")
console.log("%c        Welcomes you!", "#fffff; color: #ff8a64")

if(window.location.host === "oslyn.io" || !env.DEBUG){
    if(!window.console) window.console = {};
    var methods = ["log", "debug", "warn", "info"];
    for(var i=0;i<methods.length;i++){
        console[methods[i]] = function(){};
    }
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
