import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { Grid, Button, IconButton, Container, Typography, Divider } from '@material-ui/core'

import music from '../img/Music-lovers.jpg'
import worship from '../img/Worship.jpg'
import technology from '../img/Technology-Enthusiast.jpg'

import ContactUs from './Privacy/ContactUs'

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "rgba(101,31,255,1)",
  },
  title: {
    color: "white"
  },
  headerSection: {
    background: "rgb(2,0,36)",
    background: "linear-gradient(0deg, rgba(35,35,45,1) 0%, rgba(28,0,90,1) 25%, rgba(101,31,255,1) 100%)"
  },
  headerLine1: {
    color: "white"
  },
  featureSection: {
    background: "rgba(35,35,45,1)",
    color: "white",
  },
  featureSectionTitle: {
    color: "white",
    fontSize: 40
  },
  column1: {
    backgroundImage: "linear-Gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ), url("+music+")",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
  },
  column2: {
    backgroundImage: "linear-Gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url("+worship+")",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
  },
  column3: {
    backgroundImage: "linear-Gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ), url("+technology+")",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
  },
  featureSectionTitle2: {
    color: "white",
    fontSize: 40
  },
  featureSectionTitle3: {
    color: "white",
    fontSize: 40
  },
  featureSectionParagraph: {
    color: "white",
    fontSize: 19,
    marginBottom: 20,
    transition: "opacity 500ms ease-in"
  },
  headerScrollDownButton: {
    color: "white",
    borderColor: "white",
    border: "solid",
    borderWidth: 1,
    top: -200
  },
})

const SM_SCREEN = 600
const MD_SCREEN = 960
const LG_SCREEN = 1280
const XL_SCREEN = 1920

class About extends Component {

  constructor(props) {
    super(props)
    this.handleMouseHover = this.handleMouseHover.bind(this);
    this.handleMouseHover2 = this.handleMouseHover2.bind(this);
    this.handleMouseHover3 = this.handleMouseHover3.bind(this);
    this.state = {
      width: 0, height: 0, opacity: 0, opacity2: 0, opacity3: 0
    }

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    this.featuresSectionRef = React.createRef()
    this.contactUsRef = React.createRef()
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({width: window.innerWidth, height: window.innerHeight})
    if (window.innerWidth>SM_SCREEN) 
      this.setState({opacity: 0, opacity2: 0, opacity3: 0})
    else
      this.setState({opacity: 100, opacity2: 100, opacity3: 100})
  }

  scrollToFeaturesSection = () => {
    if (this.state.width>SM_SCREEN) 
      window.scrollTo(0, this.featuresSectionRef.current.offsetTop-50)
    else
      window.scrollTo(0, this.featuresSectionRef.current.offsetTop+50)
  }

  scrollToContactUs() {
    if (this.state.width>SM_SCREEN) 
      window.scrollTo(0, this.contactUsRef.current.offsetTop-50)
    else
      window.scrollTo(0, this.contactUsRef.current.offsetTop+50)
  }

  handleMouseHover() {
    if (this.state.width>SM_SCREEN)  
      this.setState(this.toggleHoverState);
  }

  handleMouseHover2() {
    if (this.state.width>SM_SCREEN)  
      this.setState(this.toggleHoverState2);
  }

  handleMouseHover3() {
    if (this.state.width>SM_SCREEN) 
      this.setState(this.toggleHoverState3);
  }

  toggleHoverState(state) {
    if (this.state.opacity==0)
      return {
        opacity: 100
      };
    else
      return {
        opacity: 0
      };
  }

  toggleHoverState2(state) {
    if (this.state.opacity2==0)
      return {
        opacity2: 100
      };
    else
      return {
        opacity2: 0
      };
  }

  toggleHoverState3(state) {
    if (this.state.opacity3==0)
      return {
        opacity3: 100
      };
    else
      return {
        opacity3: 0
      };
  }

  render() {
    const { classes } = this.props

    return (<div className={classes.root}>
      <div className={classes.headerSection}>
        <Container maxWidth="sm" style={{paddingTop: 190, paddingBottom: 350}}>
            <Typography component="h1" variant="h2" align="left" color="textPrimary" gutterBottom 
                style={{textAlign: "center", fontSize: this.state.width>SM_SCREEN?50:30}} className={classes.headerLine1}>
                We are passionate about music and technology. 
            </Typography>
        </Container>
        <div style={{width: "100%", display: this.state.width<MD_SCREEN?"flex":"none", justifyContent: "center"}}>
              <IconButton variant="outlined" color="primary" className={classes.headerScrollDownButton}
                onClick={this.scrollToFeaturesSection.bind(this)}>
                <ExpandMoreIcon />
              </IconButton>
        </div>
      </div>
      <Grid container style={{width: "100%"}} className={classes.featureSection} ref={this.featuresSectionRef}>
          <Grid item onMouseEnter={this.handleMouseHover}
                onMouseLeave={this.handleMouseHover} item xs={12} sm={12} md={12} lg={4} xl={4} className={classes.column1} style={{paddingRight: this.state.width<SM_SCREEN?10:"", paddingLeft: this.state.width<SM_SCREEN?10:0}}>
            <Container maxWidth="sm" style={{paddingTop: 50, paddingBottom: 120, paddingLeft: this.state.width<LG_SCREEN && this.state.width>MD_SCREEN?60:""}}>
              <Typography component="h1" variant="h2" align="center" color="textPrimary" className={classes.featureSectionTitle}
                style={{textAlign: this.state.width<MD_SCREEN?"center":"center", fontSize:this.state.width<SM_SCREEN?30:"", paddingTop: 20}} gutterBottom >
                Music Lovers
              </Typography>
              
              <Typography varient="h5" align="center" color="textSecondary" className={classes.featureSectionParagraph}
                  style={{opacity: this.state.opacity, textAlign: this.state.width<MD_SCREEN?"center":"center", fontSize:this.state.width<SM_SCREEN?15:"", paddingTop: 50}}>
                We are musicians who've experienced the difficulties of finding bandmates for secondary instruments and using tools such as looper pedals that require manual intervention.
              </Typography>
            </Container>
          </Grid>
          <Grid item onMouseEnter={this.handleMouseHover2}
                onMouseLeave={this.handleMouseHover2} item xs={12} sm={12} md={12} lg={4} xl={4} className={classes.column2} style={{paddingRight: this.state.width<SM_SCREEN?20:"", paddingLeft: this.state.width<SM_SCREEN?20:""}}>
            <Container maxWidth="sm" style={{paddingTop: 50, paddingBottom: 120, paddingLeft: this.state.width<MD_SCREEN?0:25, paddingRight: this.state.width<MD_SCREEN?0:25}}>
              <Typography component="h1" variant="h2" align="center" color="textPrimary" className={classes.featureSectionTitle2}
                style={{textAlign: this.state.width<MD_SCREEN?"center":"center", fontSize:this.state.width<SM_SCREEN?30:"", paddingTop: 20}} gutterBottom >
                Worship Leaders
              </Typography>
            
              <Typography varient="h5" align="center" color="textSecondary" className={classes.featureSectionParagraph}
                  style={{opacity: this.state.opacity2, textAlign: this.state.width<MD_SCREEN?"center":"center", fontSize:this.state.width<SM_SCREEN?15:"", paddingTop: 50}}>
                We are also worship leaders who understand how churches and fellowships may lack the resources to enhance their worship music - for example, adding pads that underlie many Christian music.
              </Typography>
            </Container>
          </Grid>
          <Grid item onMouseEnter={this.handleMouseHover3}
                onMouseLeave={this.handleMouseHover3} item xs={12} sm={12} md={12} lg={4} xl={4} className={classes.column3} style={{paddingRight: this.state.width<SM_SCREEN?10:0, paddingLeft: this.state.width<SM_SCREEN?10:""}}>
            <Container maxWidth="sm" style={{paddingTop: 50, paddingBottom: 120, paddingLeft: this.state.width<LG_SCREEN && this.state.width>MD_SCREEN?60:""}}>
              <Typography component="h1" variant="h2" align="center" color="textPrimary" className={classes.featureSectionTitle3}
                style={{textAlign: this.state.width<MD_SCREEN?"center":"center", fontSize:this.state.width<SM_SCREEN?30:"", paddingTop: 20}} gutterBottom >
                Technology Enthusiasts
              </Typography>
              <Typography varient="h5" align="center" color="textSecondary" className={classes.featureSectionParagraph}
                  style={{opacity: this.state.opacity3, textAlign: this.state.width<MD_SCREEN?"center":"center", fontSize:this.state.width<SM_SCREEN?15:"", paddingTop: 50}}>
                Our goal is to use artificial intelligence to make it as easy and convenient as possible for everyone to make the best music anytime - whether that's during a spontaneous jam session or live performance.
              </Typography>
            </Container>
          </Grid>
      </Grid>
      <Grid container style={{width: "100%"}} className={classes.featureSection}>
        <Grid item xs={12} sm={8} md={12} lg={12} xl={12} style={{paddingRight: this.state.width<SM_SCREEN?10:"", paddingLeft: this.state.width<SM_SCREEN?10:""}}>
          <Container maxWidth="md" style={{paddingTop: 50, paddingBottom: 20, paddingLeft: this.state.width<LG_SCREEN && this.state.width>MD_SCREEN?60:"", paddingRight: this.state.width<MD_SCREEN?0:50}}>
            <Typography varient="h5" align="center" color="textSecondary" className={classes.featureSectionParagraph}
                style={{textAlign: this.state.width<MD_SCREEN?"center":"center", fontSize:this.state.width<SM_SCREEN?15:""}}>
              As a result, Oslyn was born to become your digital bandmate who will automatically play accompaniment.
            </Typography>
          </Container>
        </Grid>
        <Grid item xs={12}>
          <div style={{background: "rgba(35,35,45,1)", width: "100%", paddingTop:30}}>
            <Divider style={{color: "grey", background: "grey", height:2, borderRadius:1, marginLeft:this.state.width<MD_SCREEN?20:100, marginRight:this.state.width<MD_SCREEN?20:100}}/>
          </div>
          <Container maxWidth="md" style={{paddingTop:60}} ref={this.contactUsRef}>
            <Typography component="h1" varient="h2" align="center" color="textSecondary" className={classes.title}
                style={{textAlign: this.state.width<MD_SCREEN?"center":"center", fontSize:this.state.width<SM_SCREEN?35:40}}>
              Contact Us
            </Typography>
            <ContactUs />
            </Container>
        </Grid>
      </Grid>
    </div>)
  }
}

export default withStyles(styles, { withTheme: true })(About)