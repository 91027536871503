import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Amplify, { Auth, API, graphqlOperation, Storage } from 'aws-amplify'

import config from '../config.json'

import { Typography, CssBaseline, AppBar, Toolbar, Drawer, IconButton, Button, Divider, 
  List, ListItem, ListItemIcon, ListItemText, ListSubheader } from '@material-ui/core'

import AdminSignin from './Admin/AdminSignin'
import Dashboard from './Admin/Dashboard'
import Teachers from './Admin/Teachers'
import SoundLibrary from './Admin/SoundLibrary'
import SoundCategory from './Admin/SoundCategory'

import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import DashboardIcon from '@material-ui/icons/Dashboard'
import RecentActorsIcon from '@material-ui/icons/RecentActors'
import PeopleIcon from '@material-ui/icons/People'
import BarChartIcon from '@material-ui/icons/BarChart'
import GetAppIcon from '@material-ui/icons/GetApp'
import MailIcon from '@material-ui/icons/Mail'
import MusicNoteIcon from '@material-ui/icons/MusicNote'
import LabelIcon from '@material-ui/icons/Label'

import oslynLogo from './../img/logo-colour-white2.png'
import { AWSconfigure } from '../common/Configure'

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  TitleLogo:{
    height: 60, paddingTop: 20, paddingLeft: 20,
    "&:hover": {
      cursor: "pointer"
    }
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
})

const drawerWidth = 300;

class Admin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      waiting: true,
      user: null,
      drawerOpen: true,

      page: window.localStorage.getItem("AdminPage") || "Dashboard" // [Dashboard, Teachers, Sounds]
    }
  }

  async componentDidMount() {
    Amplify.configure({
      Auth: {
        mandatorySignId: true,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID,
        identityPoolId: config.cognito.IDENTITY_POOL_ID
      },
      Storage: {
        AWSS3: {
          bucket: 'oslyn-sound',
          region: 'us-east-2'
        }
      }
    })

    Storage.get('96778d6d-9d46-45c8-a58a-3057ca6a7a3b/background.jpeg')
      .then(result => console.log(result))
      .catch(err => console.log(err));

    try {
      // const session = await Auth.currentSession()
      const user = await Auth.currentAuthenticatedUser()
      this.setState({user: user, waiting: true})
    } catch(error) {
      console.log(error)
    }
  }

  async setLogin() {
    try {
      const user = await Auth.currentAuthenticatedUser()
      this.setState({user: user, waiting: true})
    } catch(error) {
      console.log(error)
    }
  }

  handleDrawerOpen() { this.setState({ drawerOpen: true }) }
  handleDrawerClose() { this.setState({ drawerOpen: false }) }
  goToFront() {
    window.history.pushState("", "Oslyn.IO | Musician at your fingertips", "/")
    window.location.reload()
  }
  goToDashboard() { 
    this.setState({page: "Dashboard"})
    window.localStorage.setItem("AdminPage", "Dashboard")
  }
  goToTeachers() {
    this.setState({page: "Teachers"})
    window.localStorage.setItem("AdminPage", "Teachers")
  }
  goToSoundLibrary() {
    this.setState({page: "Sounds"})
    window.localStorage.setItem("AdminPage", "Sounds")
  }
  gotToSoundCategory() {
    this.setState({page: "Category"})
    window.localStorage.setItem("AdminPage", "Category")
  }

  render() {
    const { classes } = this.props

    return (<div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={
          this.state.user?clsx(classes.appBar, this.state.drawerOpen && classes.appBarShift):""}>
        <Toolbar className={this.state.user?classes.toolbar:""}>
          {this.state.user?
            <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={this.handleDrawerOpen.bind(this)}
            className={clsx(classes.menuButton, this.state.drawerOpen && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>:""}
          <Typography variant="h6" className={classes.title} >
            <img className={classes.TitleLogo} onClick={this.goToFront.bind(this)} src={oslynLogo} alt="Oslyn.IO" />
          </Typography>
          {this.state.user ?
            <div>
              <Button color="inherit">{this.state.user.username}</Button>
              <IconButton color="inherit"
                onClick={() => {
                  Auth.signOut()
                    .then((data) => {
                      console.log(data)
                      this.setState({user: null})
                      // set admin user page = null
                      window.location.reload()
                    })
                    .catch((error) => {
                      console.log(error)
                    })
                }}
              ><ExitToAppIcon /></IconButton>
            </div>:
            <div>
              <Button color="inherit">Login</Button>
            </div>
          }
        </Toolbar>
      </AppBar>
      {this.state.user?<Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !this.state.drawerOpen && classes.drawerPaperClose),
        }}
        open={this.state.drawerOpen}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={this.handleDrawerClose.bind(this)}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem button onClick={this.goToDashboard.bind(this)}>
            <ListItemIcon><DashboardIcon /></ListItemIcon>
            <ListItemText primary={
              <Typography style={{fontWeight:this.state.page==="Dashboard"?600:500}}>Dashboard</Typography>
            } />
          </ListItem>
          <ListItem button onClick={this.goToTeachers.bind(this)}>
            <ListItemIcon><RecentActorsIcon /></ListItemIcon>
            <ListItemText primary={
              <Typography style={{fontWeight:this.state.page==="Teachers"?600:500}}>Oslyn Teachers</Typography>
            } />
          </ListItem>
          <ListItem button onClick={this.goToSoundLibrary.bind(this)}>
            <ListItemIcon>
              <MusicNoteIcon />
            </ListItemIcon>
            <ListItemText primary={
              <Typography style={{fontWeight:this.state.page==="Sounds"?600:500}}>Sound Library</Typography>
            } />
          </ListItem>
          <ListItem button onClick={this.gotToSoundCategory.bind(this)}>
            <ListItemIcon>
              <LabelIcon />
            </ListItemIcon>
            <ListItemText primary={
              <Typography style={{fontWeight:this.state.page==="Category"?600:500}}>Sound Category</Typography>
            } />
          </ListItem>
          <ListItem button disabled>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Customers" />
          </ListItem>
          <ListItem button disabled>
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary="Reports" />
          </ListItem>
        </List>
        <Divider />
        <List>{secondaryListItems}</List>
      </Drawer>:""}
      <main className={classes.content}>
        { this.state.user ? 
          <div>
            {this.state.page === "Dashboard"? <Dashboard />:""}
            {this.state.page === "Teachers"? <Teachers />:""}
            {this.state.page === "Sounds"? <SoundLibrary />:""}
            {this.state.page === "Category"? <SoundCategory />:""}
          </div>:
          <AdminSignin login={this.setLogin.bind(this)}/>
        }
      </main>
    </div>)
  }
}

export default withStyles(styles, { withTheme: true })(Admin)

async function downloadAllBetaTesters() {
  let query = `
      query listSubscribeProductUpdates {
        listBetaTesters (
          filter: {
            subscribeProductUpdates: { eq: true }
          }
        ){ items { 
          id name email subscribeProductUpdates subscribeProductOfferings
    	    android ios comment status accountHistory lastUpdate
        }}
      }`

  AWSconfigure("WEBAPP")
  let data = await API.graphql(graphqlOperation(query))
  console.log(data)
  if (data) {
    if (data.data.listBetaTesters){
      let items = data.data.listBetaTesters.items
      let csvContent = "data:text/csv;charset=utf-8,"+
        "Id,Name,Email,Product Updates,Product Offerings,Android,iOS,Comment,Status,Account History,Last Update\n"+ 
        items.map(e => `${e.id},${e.name},${e.email},${e.subscribeProductUpdates},${e.subscribeProductOfferings},${e.android},${e.ios},${e.comment},${e.status},${e.accountHistory},${e.lastUpdate}`)
        .join("\n")

        var encodedUri = encodeURI(csvContent)
        var link = document.createElement("a")
        link.setAttribute("href", encodedUri)
        link.setAttribute("download", `Beta Subscribers DB.csv`)
        document.body.appendChild(link)

        link.click()
    }
  } else return {error: "graphql failed to return a data object."}
}

async function downloadBetaTestersEmailList(option) {
  console.log(option)

  let query = ""
  if (option === "productUpdates")
    query = `
      query listSubscribeProductUpdates {
        listBetaTesters (
          filter: {
            subscribeProductUpdates: { eq: true }
          }
        ){ items { name email } }
      }`
  else if (option === "productOfferings")
    query = `
      query listSubscribeProductOfferings {
        listBetaTesters (
          filter: {
            subscribeProductOfferings: { eq: true }
          }
        ){ items { name email } }
      }`
  else if (option === "both")
    query = `
      query both {
        listBetaTesters (
          filter: {
            subscribeProductOfferings: { eq: true }
            subscribeProductUpdates: { eq: true }
          }
        ){ items { name email } }
      }
    `
  else return {error: "failed to download data. invalid DB option."}

  AWSconfigure("WEBAPP")
  let data = await API.graphql(graphqlOperation(query))
  console.log(data)
  if (data) {
    if (data.data.listBetaTesters){
      let items = data.data.listBetaTesters.items
      let csvContent = "data:text/csv;charset=utf-8,"
        + items.map(e => `${e.name},${e.email}`).join("\n")

        var encodedUri = encodeURI(csvContent)
        var link = document.createElement("a")
        link.setAttribute("href", encodedUri)
        link.setAttribute("download", `Beta Subscribers - ${option}.csv`)
        document.body.appendChild(link)

        link.click()
    }
  } else return {error: "graphql failed to return a data object."}
}

const secondaryListItems = (
  <div>
    <ListSubheader inset>Subscriber Mail Util</ListSubheader>
    <ListItem button onClick={downloadBetaTestersEmailList.bind(this, "productUpdates")}>
      <ListItemIcon>
        <MailIcon />
      </ListItemIcon>
      <ListItemText primary="Product Update List" />
    </ListItem>
    <ListItem button onClick={downloadBetaTestersEmailList.bind(this, "productOfferings")}>
      <ListItemIcon>
        <MailIcon />
      </ListItemIcon>
      <ListItemText primary="Product Offering List" />
    </ListItem>
    <ListItem button onClick={downloadBetaTestersEmailList.bind(this, "both")}>
      <ListItemIcon>
        <MailIcon />
      </ListItemIcon>
      <ListItemText primary="Entire Mail List" />
    </ListItem>
    <ListItem button onClick={downloadAllBetaTesters.bind(this)}>
      <ListItemIcon>
        <GetAppIcon />
      </ListItemIcon>
      <ListItemText primary="All Data Download" />
    </ListItem>
  </div>
);