import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

import { Grid, Button, IconButton, Container, Typography, withTheme, StylesProvider, Divider } from '@material-ui/core'

import oslynFeature from '../img/phone-mockup-pattern.png'
import oslynPhoneLive from '../img/phone-mockup-start-screen.png'
import oslynPhoneLoop from '../img/phone-mockup-loop.png'
import oslynHalfPhone from '../img/phone-mockup-pad-sounds.png'
import oslynRoomProfile from '../img/phone-mockup-room-profile.png'

import Subscribe from './Privacy/Subscribe'

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "rgba(61,63,75,1)",
  },
  title: {
    color: "white"
  },
  featureSection: {
    background: "rgb(89,91,103)",
  },
  featureSection2: {
    background: "rgb(56,58,70)",
  },
  featureSection3: {
    background: "rgba(35,35,45,1)",
  },
  featureSection4: {
    background: "#3d3f4b"
  },
  featureSectionTitle: {
    color: "#ffffff", //white
    fontSize: 40
  },
  featureSectionTitle2: {
    color: "#ff8a65", //orange
    fontSize: 40
  },
  featureSectionTitle3: {
    color: "#47dcff", //light-blue
    fontSize: 40
  },
  featureSectionTitle4: {
    color: "#ffc050", //gold
    fontSize: 40
  },
  featureSectionParagraph: {
    color: "white",
    fontSize: 19,
    marginBottom: 20
  },
  line: {
    borderBottom: 100,
    color: '#ffffff',
  },
  downloadBetaSecionParagraph: {
    color: "#eeeeee",
    fontSize: 10,
    marginBottom: 20,
    padding: 20
  },
})

const SM_SCREEN = 600
const MD_SCREEN = 960
const LG_SCREEN = 1280
const XL_SCREEN = 1920

class Features extends Component {

  constructor(props) {
    super(props)
    this.state = {
      width: 0, height: 0
    }

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({width: window.innerWidth, height: window.innerHeight})
  }

  render() {
    const { classes } = this.props

    return (<div className={classes.root}>
         <Grid container style={{width: "100%"}} className={classes.featureSection} ref={this.featuresSectionRef}>
            <Grid item xs={12} md={6} xl={6} style={{
              display: this.state.width>=MD_SCREEN?"none":"flex",
              backgroundImage: "url("+oslynFeature+")",
              backgroundSize: "100%",
              minHeight: this.state.width>MD_SCREEN?20:this.state.width*(8640/11520)
              }}>
            </Grid>
            <Grid item xs={false} sm={2} md={false} lg={1} xl={1} style={{display: this.state.width<LG_SCREEN && this.state.width>=MD_SCREEN?"none":"inherit"}}/>
            <Grid item xs={12} sm={8} md={6} lg={5} xl={5} style={{paddingRight: this.state.width<SM_SCREEN?10:"", paddingLeft: this.state.width<SM_SCREEN?10:""}}>
               <Container maxWidth="md" style={{paddingTop: 150, paddingBottom: 300, paddingLeft: this.state.width<LG_SCREEN && this.state.width>MD_SCREEN?60:""}}>
                  <Typography component="h1" variant="h2" align="left" color="textPrimary" className={classes.featureSectionTitle}
                    style={{textAlign: this.state.width<MD_SCREEN?"center":"left", fontSize:this.state.width<SM_SCREEN?30:""}} gutterBottom>
                    Getting music accompaniment has never been easier.
                  </Typography>
                  <Typography varient="h5" align="left" color="textSecondary" className={classes.featureSectionParagraph}
                    style={{textAlign: this.state.width<MD_SCREEN?"center":"left", fontSize:this.state.width<SM_SCREEN?15:""}}>
                    Oslyn is designed by musicians for musicians, making it easy for you to use and produce the sound and ambience you want.
                  </Typography>
               </Container>
            </Grid>
            <Grid item xs={12} md={6} xl={6} style={{
              display:this.state.width<MD_SCREEN?"none":"flex",
              backgroundImage: "url("+oslynFeature+")",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              minHeight: this.state.width>MD_SCREEN?20:this.state.width*(8640/11520)
              }}>
            </Grid>
         </Grid>
         <Grid container style={{width: "100%"}} className={classes.featureSection2} ref={this.featuresSectionRef}>
            <Grid item xs={12} sm={12} md={6} lg={5} xl={4} 
            style={{display: this.state.width>MD_SCREEN?"none":"flex", justifyContent: "center", background: this.state.width<MD_SCREEN?"rgb(56,58,70)":"inherit"}}>
              <img src={oslynPhoneLive} alt="Oslyn Phone" style={{
                width: this.state.width<440?400-(440-this.state.width):400, 
                marginTop: 50, height: this.state.width<440?717-(440-this.state.width)*(717/440):717}}/>
            </Grid>
            <Grid item xs={false} sm={2} md={false} lg={1} xl={1} style={{display: this.state.width<LG_SCREEN && this.state.width>MD_SCREEN?"none":"inherit"}}/>
            <Grid item xs={12} sm={8} md={6} lg={5} xl={5} style={{paddingRight: this.state.width<SM_SCREEN?10:"", paddingLeft: this.state.width<SM_SCREEN?10:""}}>
               <Container maxWidth="md" style={{paddingTop: 150, paddingBottom: 300, paddingLeft: this.state.width<LG_SCREEN && this.state.width>MD_SCREEN?60:""}}>
                  <Typography varient="h5" align="left" color="textSecondary" className={classes.featureSectionParagraph}
                    style={{textAlign: this.state.width<MD_SCREEN?"center":"left", fontSize:this.state.width<SM_SCREEN?15:"", display: this.state.width<MD_SCREEN?"none":""}}>
                    Press "START" and then begin playing your guitar. Oslyn will start accompanying you with the pad sound selected.
                  </Typography>
                  <Typography component="h1" variant="h2" align="left" color="textPrimary" className={classes.featureSectionTitle2}
                    style={{textAlign: this.state.width<MD_SCREEN?"center":"left", fontSize:this.state.width<SM_SCREEN?30:""}} gutterBottom>
                    Sustain
                  </Typography>
                  <Typography varient="h5" align="left" color="textSecondary" className={classes.featureSectionParagraph}
                      style={{textAlign: this.state.width<MD_SCREEN?"center":"left", fontSize:this.state.width<SM_SCREEN?15:""}}>
                    When you need Oslyn to hold the chord after you stop playing, press "SUSTAIN".
                  </Typography>
               </Container>
            </Grid>
            <Grid item xs={false} md={false} lg={1} xl={1}/>
            <Grid item xs={12} sm={12} md={6} lg={5} xl={4} 
            style={{display: "flex", justifyContent: "center", background: this.state.width<MD_SCREEN?"rgb(56,58,70)":"inherit"}}>
              <img src={oslynPhoneLive} alt="Oslyn Phone" style={{
                display:this.state.width<MD_SCREEN?"none":"inherit",
                width: this.state.width<440?400-(440-this.state.width):400, 
                marginTop: 50, height: this.state.width<440?717-(440-this.state.width)*(717/440):717}}/>
            </Grid>
         </Grid>
         <Grid container style={{width: "100%"}} className={classes.featureSection3} ref={this.featuresSectionRef}>
            <Grid item xs={false} md={false} lg={1} xl={1}/>
            <Grid item xs={12} sm={12} md={6} lg={5} xl={4} 
            style={{display: "flex", justifyContent: "center", background: this.state.width<MD_SCREEN?"rgba(35,35,45,1)":"inherit"}}>
              <img src={oslynPhoneLoop} alt="Oslyn Phone" style={{
                width: this.state.width<440?400-(440-this.state.width):400, 
                marginTop: 50, height: this.state.width<440?717-(440-this.state.width)*(717/440):717}}/>
            </Grid>
            <Grid item xs={false} sm={2} md={false} lg={1} xl={1} style={{display: this.state.width<LG_SCREEN && this.state.width>MD_SCREEN?"none":"inherit"}}/>
            <Grid item xs={12} sm={8} md={6} lg={5} xl={5} style={{paddingRight: this.state.width<SM_SCREEN?10:"", paddingLeft: this.state.width<SM_SCREEN?10:""}}>
               <Container maxWidth="md" style={{paddingTop: 150, paddingBottom: 300, paddingLeft: this.state.width<LG_SCREEN && this.state.width>MD_SCREEN?60:""}}>
                  <Typography component="h1" variant="h2" align="left" color="textPrimary" className={classes.featureSectionTitle3}
                    style={{textAlign: this.state.width<MD_SCREEN?"center":"left", fontSize:this.state.width<SM_SCREEN?30:""}} gutterBottom>
                    Loop
                  </Typography>
                  <Typography varient="h5" align="left" color="textSecondary" className={classes.featureSectionParagraph}
                    style={{textAlign: this.state.width<MD_SCREEN?"center":"left", fontSize:this.state.width<SM_SCREEN?15:""}}>
                    When you want Oslyn to continuously repeat multiple chords after you stop playing, press "LOOP" then play the chords you want to be repeated.
                  </Typography>
                  <Typography varient="h5" align="left" color="textSecondary" className={classes.featureSectionParagraph}
                    style={{textAlign: this.state.width<MD_SCREEN?"center":"left", fontSize:this.state.width<SM_SCREEN?15:""}}>
                    Loop is great for creating an ambience with soft background music. For example, when you're speaking or praying during worship.
                  </Typography>
               </Container>
           </Grid>
         <Grid container style={{width: "100%"}} className={classes.featureSection4} ref={this.featureSectionRef}>
            <Grid item xs={false} sm={2} md={false} lg={1} xl={1} style={{display: this.state.width<XL_SCREEN && this.state.width>MD_SCREEN?"none":"inherit", background: this.state.width<MD_SCREEN?"#3d3f4b":""}}/>
            <Grid item xs={12} sm={8} md={6} lg={6} xl={5} style={{
                  background: this.state.width<MD_SCREEN?"#3d3f4b":"", 
                  paddingRight: this.state.width<SM_SCREEN?10:"", 
                  paddingLeft: this.state.width<SM_SCREEN?10:""}}>
               <Container maxWidth="md" style={{paddingTop: 200, paddingBottom: 50, 
                 paddingLeft:this.state.width>MD_SCREEN && this.state.width<XL_SCREEN?180-((XL_SCREEN-this.state.width)*0.1):""}}>
                  <Typography component="h1" variant="h2" align="left" color="textPrimary" gutterBottom className={classes.featureSectionTitle4}
                  style={{textAlign: this.state.width<MD_SCREEN?"center":"inherit", fontSize:this.state.width<SM_SCREEN?30:""}}>
                    Pad Sounds
                  </Typography>
                  <Typography varient="h5" align="left" color="textSecondary" className={classes.featureSectionParagraph}
                    style={{textAlign: this.state.width<MD_SCREEN?"center":"inherit", fontSize:this.state.width<SM_SCREEN?15:""}}>
                    As Oslyn continues to grow,a variety of pad sounds and instruments will be available for you to choose from, allowing you to generate the desired sound and mood.
                  </Typography>
               </Container>
             </Grid>
            <Grid item xs={false} xl={1}/>
            <Grid item xs={false} md={6} xl={4} style={{display:this.state.width<MD_SCREEN?"none":"inherit"}}>
              <img src={oslynHalfPhone} alt="oslyn half phone" style={{
                width: this.state.width<1220 && this.state.width>MD_SCREEN?this.state.width-650:600, marginTop: 50}}/> {/** use (marginBottom -5) to fix visual bug - Dom*/}
            </Grid>
            <Grid item xs={false} sm={2} lg={false} style={{background: this.state.width<MD_SCREEN?"#3d3f4b":""}}/>
            <Grid item xs={12} style={{display: this.state.width>MD_SCREEN?"none":"flex", justifyContent: "center"}}>
              <img src={oslynHalfPhone} alt="oslyn half phone" 
                style={{width: this.state.width<660?600-(660-this.state.width):660, height: this.state.width<660?(751-(750 - 751/660 * this.state.width)):751,
                  maxWidth:this.state.width-20, marginTop: 50}}/> {/** use (marginBottom -5) to fix visual bug - Dom*/}
            </Grid> {/*style={{width: this.state.width<680?600-(680-this.state.width):680, marginTop: 50}}/> {/** use (marginBottom -5) to fix visual bug - Dom*/}*/}
         </Grid>
         {/* <Grid container style={{width: "100%"}} className={classes.featureSection3} ref={this.featuresSectionRef}>
            <Grid item xs={false} md={0} lg={1} xl={1}/>
            <Grid item xs={12} sm={12} md={6} lg={5} xl={4} 
              style={{display: "flex", justifyContent: "center", background: this.state.width<MD_SCREEN?"rgba(35,35,45,1)":"inherit"}}>
              <img src={oslynRoomProfile} alt="Oslyn Phone" style={{
                width: this.state.width<440?400-(440-this.state.width):400, 
                marginTop: 50, height: this.state.width<440?717-(440-this.state.width)*(717/440):717}}/>
            </Grid>
             <Grid item xs={0} sm={2} md={0} lg={1} xl={1} style={{display: this.state.width<LG_SCREEN && this.state.width>MD_SCREEN?"none":"inherit"}}/>
             <Grid item xs={12} sm={8} md={6} lg={5} xl={5} style={{paddingRight: this.state.width<SM_SCREEN?10:"", paddingLeft: this.state.width<SM_SCREEN?10:""}}>
               <Container maxWidth="md" style={{paddingTop: 150, paddingBottom: 300, paddingLeft: this.state.width<LG_SCREEN && this.state.width>MD_SCREEN?60:""}}>
                  <Typography component="h1" variant="h2" align="left" color="textPrimary" className={classes.featureSectionTitle2}
                    style={{textAlign: this.state.width<MD_SCREEN?"center":"left", fontSize:this.state.width<SM_SCREEN?30:""}} gutterBottom>
                    Room Profiles
                  </Typography>
                  <Typography varient="h5" align="left" color="textSecondary" className={classes.featureSectionParagraph}
                    style={{textAlign: this.state.width<MD_SCREEN?"center":"left", fontSize:this.state.width<SM_SCREEN?15:""}}>
                    You can make adjustments to get the perfect sound for each setting you're in and save your preferences as a room profile.
                  </Typography>
               </Container>
             </Grid>

          </Grid>   */}
          <div style={{background: "rgba(35,35,45,1)", width: "100%", paddingTop:30}}>
            <Divider style={{color: "grey", background: "grey", height:2, borderRadius:1, marginLeft:this.state.width<MD_SCREEN?20:100, marginRight:this.state.width<MD_SCREEN?20:100}}/>
          </div>
          <Grid container style={{width: "100%"}} className={classes.featureSection3} ref={this.featuresSectionRef}>
               <Container maxWidth="sm" style={{paddingTop: 100, paddingBottom: 30}}>
                  <Typography component="h1" variant="h2" align="center" color="textPrimary" className={classes.featureSectionTitle3}
                    style={{textAlign: this.state.width<MD_SCREEN?"center":"center", fontSize:this.state.width<SM_SCREEN?30:""}} gutterBottom>
                    Like what you see?
                  </Typography>
                  <Typography component="h1" variant="h2" align="center" color="textPrimary" className={classes.featureSectionTitle}
                    style={{textAlign: this.state.width<MD_SCREEN?"center":"center", fontSize:this.state.width<SM_SCREEN?30:""}} gutterBottom>
                    Download Oslyn Beta
                  </Typography>
                  {/*<Typography varient="h5" align="center" color="textSecondary" className={classes.featureSectionParagraph}
                    style={{textAlign: this.state.width<MD_SCREEN?"center":"center", fontSize:this.state.width<SM_SCREEN?15:""}}>
                    We are beta testing Oslyn. Sign up to download Oslyn and enroll in
                  </Typography>
                  <Typography varient="h5" align="center" color="textSecondary" className={classes.featureSectionParagraph}
                    style={{textAlign: this.state.width<MD_SCREEN?"center":"center", fontSize:this.state.width<SM_SCREEN?15:""}}>
                    the testing programme for Android and/or iOS.
                  </Typography>
                  <Container maxWidth="md" style={{paddingTop: 5, paddingLeft: this.state.width<LG_SCREEN && this.state.width>MD_SCREEN?60:""}}></Container>
                  <Typography varient="h5" align="center" color="textSecondary" className={classes.featureSectionParagraph}
                    style={{textAlign: this.state.width<MD_SCREEN?"center":"center", fontSize:this.state.width<SM_SCREEN?15:""}}>
                    Note: A Gmail email address must be used to sign up for Android.
                  </Typography>*/}
                  <Typography varient="h5" align="left" color="textSecondary" className={classes.featureSectionParagraph}
                      style={{textAlign: "center", fontSize:this.state.width<SM_SCREEN?15:""}}>
                    We are beta testing Oslyn. Sign up to download Oslyn and enroll in the testing programme for Android and/or iOS. 
                  </Typography>
                  <Typography varient="h5" align="left" color="textSecondary" className={classes.featureSectionParagraph}
                      style={{textAlign: "center", fontSize:this.state.width<SM_SCREEN?13:15, color: "#eeeeee"}}>
                    Note: A Gmail email address must be used to sign up for Android. 
                  </Typography>
                  <Subscribe />
                  <Typography varient="h5" align="left" color="textSecondary" className={classes.downloadBetaSecionParagraph} 
                    style={{textAlign: "center", fontSize:this.state.width<SM_SCREEN?9:10}}>
                  By becoming a beta tester, you agree to receive emails from Oslyn about updates and surveys to help us improve. 
                  You can unsubscribe anytime by updating your email preferences via a link in the footer of our emails.
                </Typography>
               </Container>
           </Grid>  
        </Grid>
    </div>)
  }
}

export default withStyles(styles, { withTheme: true })(Features)