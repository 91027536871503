import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const styles = theme => ({
  center: {
    position: "relative",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "white"
  },
})

class Error extends Component {

  constructor(props) {
    super(props)
    this.state = { height: 0 }

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({height: window.innerHeight})
  }

  render() {
    const { classes } = this.props
    return <div style={{height: this.state.height-377, backgroundColor: "rgba(35,35,45,1)"}}>
      <Typography component="h1" variant="h5" align="center" color="textPrimary"
        className={classes.center}>Opps .. Looks like this page doesn't exist</Typography>
    </div>
  }

}

export default withStyles(styles, { withTheme: true })(Error)