import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { Storage } from 'aws-amplify'

import { Box, Typography, Container, Grid, Paper, Link,
  TableContainer, Table, TableHead, TableRow, TableCell, TableBody, 
  Checkbox, Button, Fab, Zoom, TextField, IconButton,
  Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText,
  FormControl, InputLabel, OutlinedInput, InputAdornment, FormControlLabel } from '@material-ui/core'

import AudioPlayer from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css'

import PublishIcon from '@material-ui/icons/Publish'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import CloudDoneIcon from '@material-ui/icons/CloudDone'
import ErrorIcon from '@material-ui/icons/Error'

const styles = theme => ({
  appBarSpacer: theme.mixins.toolbar,
  root: {
    flexGrow: 1,
    backgroundColor: "rgba(101,31,255,1)",
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(4),
    right: theme.spacing(6),
  },
  fabColor: {
    color: "#23232d", // black
    backgroundColor: "#ff8a65", // orange
    transition: "color 0.8s ease, background-color 0.8s ease, transform 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important",
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: "#651fff", // purple
    },
  },
  firstForTextGrid: {
    paddingRight: 10,
    paddingLeft: 10
  },
  smallerPaddingForTextGrid: {
    paddingRight: 5,
    paddingLeft: 5,
    paddingBottom: 15
  },
  backgroundRoot: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  backgroundInput: {
    display: 'none',
  },
  backgroundImageButton: {
    color: theme.palette.common.white,
    backgroundColor: "#47dcff", // light blue
    transition: "color 0.8s ease, background-color 0.8s ease, transform 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important",
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: "#651fff", // purple
    },
  },
  audioContainer: {
    width: 300
  },
  statusIcon: {
    fontSize: "1.8em"
  }
})

const _CHORDS = ["C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "Bb", "B"]

class SoundTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
        "C": { name: "C", major: "C.mp3", minor: "Cm.mp3" },
        isNewUpload_C: false,
        isNewUpload_Cm: false,
        C_file: null,
        Cm_file: null,

        "C#": { name: "Cs", major: "Cs.mp3", minor: "Csm.mp3" },
        isNewUpload_Cs: false,
        isNewUpload_Csm: false,
        Cs_file: null,
        Csm_file: null,
        
        "D": { name: "D", major: "D.mp3", minor: "Dm.mp3" },
        isNewUpload_D: false,
        isNewUpload_Dm: false,
        D_file: null,
        Dm_file: null,
        
        "D#": { name: "Ds", major: "Ds.mp3", minor: "Dsm.mp3" },
        isNewUpload_Ds: false,
        isNewUpload_Dsm: false,
        Ds_file: null,
        Dsm_file: null,
        
        "E": { name: "E", major: "E.mp3", minor: "Em.mp3" },
        isNewUpload_E: false,
        isNewUpload_Em: false,
        E_file: null,
        Em_file: null,
        
        "F": { name: "F", major: "F.mp3", minor: "Fm.mp3" },
        isNewUpload_F: false,
        isNewUpload_Fm: false,
        F_file: null,
        Fm_file: null,
        
        "F#": { name: "Fs", major: "Fs.mp3", minor: "Fsm.mp3" },
        isNewUpload_Fs: false,
        isNewUpload_Fsm: false,
        Fs_file: null,
        Fsm_file: null,
        
        "G": { name: "G", major: "G.mp3", minor: "Gm.mp3" },
        isNewUpload_G: false,
        isNewUpload_Gm: false,
        G_file: null,
        Gm_file: null,
        
        "G#": { name: "Gs", major: "Gs.mp3", minor: "Gsm.mp3" },
        isNewUpload_Gs: false,
        isNewUpload_Gsm: false,
        Gs_file: null,
        Gsm_file: null,
        
        "A": { name: "A", major: "A.mp3", minor: "Am.mp3" },
        isNewUpload_A: false,
        isNewUpload_Am: false,
        A_file: null,
        Am_file: null,
        
        "Bb": { name: "Bb", major: "Bb.mp3", minor: "Bbm.mp3" },
        isNewUpload_Bb: false,
        isNewUpload_Bbm: false,
        Bb_file: null,
        Bbm_file: null,
        
        "B": { name: "B", major: "B.mp3", minor: "Bm.mp3" },
        isNewUpload_B: false,
        isNewUpload_Bm: false,
        B_file: null,
        Bm_file: null,
    }

    this.audioPlayer = {}
  }

  componentDidMount() {
    this.getChordsFromCloud(this.props.soundLibID)
  }

  async getChordsFromCloud(soundID) {
    if (soundID) {
      let listData = await Storage.list(`${soundID}/library/`)
      console.log(listData)

      for (let data of listData) {
        if (data.key.includes('.mp3')) {
          let fileChordName = data.key.split("/library/")[1].split(".mp3")[0]
          let chord = fileChordName.replace("s", "#").replace("m","")

          if (chord !== "") {
            let sound = await Storage.get(data.key)

            let newState = {}
            newState[`${fileChordName}_file`] = sound
            this.setState(newState, () => {
              console.log(this.audioPlayer[chord])
            })
          }
          
        } else console.log(`weird s3 data.key: ${data.key}`)
      }
    } else {
      console.log("NO SOUND ID WHEN FETCHING ALL CHORDS!")
      setTimeout(() => {
        this.getChordsFromCloud(this.props.soundLibID)
      }, 200)
    }

  }

  uploadChordLocal(chord, event) {
    if (event.target.files[0]){
      if (event.target.files[0].name.endsWith(".mp3")) {
        let newState = {}
        newState[`${chord}_file`] = URL.createObjectURL(event.target.files[0])
        newState[`isNewUpload_${chord}`] = true
        this.setState(newState, () => {
          console.log(this.state[`${chord}_file`])
          let properChord = chord.replace("s","#")
          this.audioPlayer[properChord].playAudioPromise()
        })
        console.log(event.target.files[0])
        console.log(`chord: ${chord}`)
        this.props.setChanged()
      } else alert('Please only submit .mp3!')
    } else alert('No Audio File uploaded! Talk to Dom!')
  }

  async uploadChordsToCloud() {
    for (let chord of _CHORDS) {
      if (this.props.soundLibID){
        if (this.state[`isNewUpload_${this.state[chord].name}`] && 
          this.state[`${this.state[chord].name}_file`]) { // MAJOR
            let blob = await fetch(this.state[`${this.state[chord].name}_file`]).then(r => r.blob())
            Storage.put(`${this.props.soundLibID}/library/${this.state[chord].major}`, blob, {
              progressCallback(progress) {
                console.log(`Uploaded: ${progress.loaded}/${progress.total}`)
              },
              contentType: 'audio/mpeg'
            })
            console.log(`uploading: ${this.state[chord].major}`)
            let newState = {}
            newState[`isNewUpload_${this.state[chord].name}`] = false
            this.setState(newState)
        }

        if (this.state[`isNewUpload_${this.state[chord].name}m`] && 
          this.state[`${this.state[chord].name}m_file`]) { // MINOR
            let blob = await fetch(this.state[`${this.state[chord].name}m_file`]).then(r => r.blob())
            Storage.put(`${this.props.soundLibID}/library/${this.state[chord].minor}`, blob, {
              progressCallback(progress) {
                console.log(`Uploaded: ${progress.loaded}/${progress.total}`)
              },
              contentType: 'audio/mpeg'
            })
            console.log(`uploading: ${this.state[chord].minor}`)
            let newState = {}
            newState[`isNewUpload_${this.state[chord].name}m`] = false
            this.setState(newState)
        }
      } else alert('SoundTable: no soundID provided. Talk to Dom.')
    }
  }

  render() {
    const { classes } = this.props

    return (<Grid container style={{paddingTop: 50, paddingBottom: 50, background: "rgb(250, 250, 250)"}}>
      <Grid item xs={12} className={classes.smallerPaddingForTextGrid}>
        <Table className={classes.table} aria-label="a dense table" size="small" style={{width: 1030}}>
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{borderRight: "1px solid grey"}}>Chord</TableCell>
              <TableCell align="center">Major (Player)</TableCell>
              <TableCell align="center" style={{borderRight: "1px dotted grey"}}>Status</TableCell>
              <TableCell align="center">Minor (Player)</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_CHORDS.map((chord) => (
              <TableRow key={chord}>
                <TableCell align="center" style={{borderRight: "1px solid grey"}}>{chord}</TableCell>
                <TableCell align="right" className={classes.tableCellContent}>
                  <div style={{display:"inline-flex"}}>
                    <AudioPlayer className={classes.audioContainer}
                      src={this.state[`${this.state[chord].name}_file`]}
                      onPlay={e => console.log("onPlay")}

                      showDownloadProgress={false}
                      showJumpControls={false}
                      autoPlayAfterSrcChange={false}
                      ref={instance => this.audioPlayer[chord] = instance }
                    />
                    <div className={classes.backgroundRoot} style={{display: "inline-block"}}>
                      <input accept="audio/*" className={classes.backgroundInput} 
                        id={`chord-upload-${chord}-major`} 
                        type="file" onChange={this.uploadChordLocal.bind(this, this.state[chord].name)}
                      />
                      <label htmlFor={`chord-upload-${chord}-major`}>
                        <Button color="primary" aria-label="upload sound" component="span" className={classes.backgroundImageButton} style={{height: "100%"}}>
                          <PublishIcon />
                        </Button>
                      </label>
                    </div>
                  </div>
                </TableCell>
                <TableCell align="center" padding="checkbox" style={{borderRight: "1px dotted grey"}}>
                  <Typography style={{paddingRight:5}}>{this.state[chord].major}</Typography>
                  {this.state[`${this.state[chord].name}_file`]? 
                    this.state[`isNewUpload_${this.state[chord].name}`]?
                      <CloudUploadIcon className={classes.statusIcon} style={{color: "#ffe082"}}/>:
                      <CloudDoneIcon className={classes.statusIcon} style={{color: "#81c784"}}/>:
                      <ErrorIcon className={classes.statusIcon} style={{color: "#e57373"}}/>
                  }
                </TableCell>
                <TableCell align="right" className={classes.tableCellContent}>
                  <div style={{display:"inline-flex"}}>
                    <AudioPlayer className={classes.audioContainer}
                      src={this.state[`${this.state[chord].name}m_file`]}
                      onPlay={e => console.log("onPlay")}

                      showDownloadProgress={false}
                      showJumpControls={false}
                      autoPlayAfterSrcChange={false}
                      ref={instance => this.audioPlayer[`${chord}m`] = instance }
                    />
                    <div className={classes.backgroundRoot} style={{display: "inline-block"}}>
                      <input accept="audio/*" className={classes.backgroundInput} 
                        id={`chord-upload-${chord}-minor`} 
                        type="file" onChange={this.uploadChordLocal.bind(this, `${this.state[chord].name}m`)}
                      />
                      <label htmlFor={`chord-upload-${chord}-minor`}>
                        <Button color="primary" aria-label="upload sound" component="span" className={classes.backgroundImageButton} style={{height: "100%"}}>
                          <PublishIcon />
                        </Button>
                      </label>
                    </div>
                  </div>
                </TableCell>
                <TableCell align="center" padding="checkbox">
                  <Typography style={{paddingRight:5}}>{this.state[chord].minor}</Typography>
                  {this.state[`${this.state[chord].name}m_file`]? 
                    this.state[`isNewUpload_${this.state[chord].name}m`]?
                      <CloudUploadIcon className={classes.statusIcon} style={{color: "#ffe082"}}/>:
                      <CloudDoneIcon className={classes.statusIcon} style={{color: "#81c784"}}/>:
                      <ErrorIcon className={classes.statusIcon} style={{color: "#e57373"}}/>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>)
  }
}

export default withStyles(styles, { withTheme: true })(SoundTable)