import React, { useEffect } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

import { Typography, Grid, Container } from '@material-ui/core'

import guitarBackground from '../../img/person-playing-brown-guitar.jpg'
import giveAwayContest from '../../img/ozzy-giveaway-04.png'

import Subscribe from '../Privacy/Subscribe'
import oslynStudioScreenshot from '../../img/oslyn-studio-screenshot-2.png'

const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: "rgba(101,31,255,1)"
    },
    headerSection: {
      backgroundImage: "linear-Gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url("+guitarBackground+")",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
    },
    headerLine1: {
      color: "white"
    },
    headerLine2: {
      color: "white"
    },
    featureSection: {
      background: "rgba(35,35,45,1)",
    },
    featureSectionTitle: {
      color: "#ffc050", //gold
      fontSize: 40
    },
    featureSectionParagraph: {
      color: "white",
      fontSize: 19,
      marginBottom: 20
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    fab: {
      position: 'absolute',
      bottom: theme.spacing(5),
      right: 0, left: 0, 
      marginLeft: "auto",
      marginRight: "auto"
    },
    playGameButton: {
      border: "solid 2px",
      boxShadow: "none",
      background: "none",
      transition: "background-color border 0.5s ease",
      "&:hover": {
        background: "#e8f5e9",
        color: "#43a047"
      }
    },
    pauseGameButton: {
      background: "none",
      color: "#d81b60",
      border: "solid 2px",
      boxShadow: "none",
      transition: "background-color border 0.5s ease",
      "&:hover": {
        background: "#fce4ec",
        color: "#ad1457"
      }
    },
    skipChordsButton: {
      border: "none",
      boxShadow: "none",
      background: "none",
    },
    chord: {
      fontSize: 50,
      color: "#757575",
    },
    activeChord: {
      fontSize: 60,
      fontWeight: 600,
      color: "#43a047"
    },
    pendingChord1: {
      fontSize: 50,
      color: "#81c784"
    },
    pendingChord2: {
  
    },
    downloadBetaSecionParagraph: {
      color: "#eeeeee",
      fontSize: 10,
      marginBottom: 20,
      padding: 20
    },
    giveawaySection: {
      background: "linear-gradient(135deg, #FFC050 0%, #FF8A65 100%)"
    },
    giveawaySectionTitle: {
      // color: "#ffc050", //gold
      fontSize: 30,
      fontWeight: 600,
      paddingBottom: 10
    },
    giveawaySectionTextArea: {
      color: "black",
      paddingTop: 80,
      paddingRight:20,
      paddingLeft: 20
    },
    giveawaySectionParagraph: {
      color: "black",
      fontSize: 17,
      paddingLeft: 20
    },
    giveawaySectionDetails: {
      fontSize: 12,
      paddingLeft: 20,
      paddingRight: 20
    },
    whatsinitformesection: {
      background: "#3d3f4b"
    }
  })
)

const SM_SCREEN = 600
const MD_SCREEN = 960
const LG_SCREEN = 1280
const XL_SCREEN = 1920

export interface TrainProps {
  page: string
}

export default function Train({ page }: TrainProps) {
  const classes = useStyles()

  const [width, setWidth] = React.useState(0)
  const [height, setHeight] = React.useState(0)
  useEffect(() => {
    updateWindowDimensions()
    window.addEventListener('resize', updateWindowDimensions)
    return () => window.removeEventListener('resize', updateWindowDimensions)
  }, [])

  const updateWindowDimensions = () => { setWidth(window.innerWidth); setHeight(window.innerHeight) }

  const featuresSectionRef = React.useRef(null)

  return (
    <div className={classes.root}>
      <div className={classes.headerSection}>
        <Container maxWidth="sm" style={{paddingTop: 100, paddingBottom: 250}}>
            <Typography component="h1" variant="h2" align="left" color="textPrimary" gutterBottom 
                style={{textAlign: "center", fontSize: width>SM_SCREEN?50:30}} className={classes.headerLine1}>
                Help Oslyn grow as a digital bandmate.
            </Typography>
            <Typography component="h5" align="center" color="textSecondary" 
                style={{fontSize: width>SM_SCREEN?20:15}} className={classes.headerLine2}>
                Oslyn uses artificial intelligence (AI) which means the more it listens, the more it learns to improve its accuracy and capability as your bandmate.
            </Typography>
        </Container>
      </div>
      {/*<Grid container style={{width: "100%"}} className={classes.giveawaySection} ref={this.giveAwaySectionRef}>
        <Grid item xs={12} md={6} className={classes.giveawaySectionTextArea}
          style={{paddingLeft: width >= MD_SCREEN ? 100 : 20 }}
        >
          <Typography component="h1" variant="h2" align="center" color="textPrimary" className={classes.giveawaySectionTitle}
            style={{textAlign: width<MD_SCREEN?"center":"center", fontSize:width<SM_SCREEN?30:"",
              paddingTop: width >= XL_SCREEN ? 80 : 0
            }} gutterBottom>
            Your chance to win a $50 Uber Eats eGift Card!
          </Typography>
          <Typography component="h5" align="center" color="textSecondary" className={classes.giveawaySectionParagraph}
            style={{textAlign: width<MD_SCREEN?"left":"left", fontSize:width<SM_SCREEN?15:""}}>
            Here’s how to enter the giveaway contest: 
          </Typography>
          <div style={{paddingLeft: 25, paddingRight: 25}}>
            <ol className={classes.giveawaySectionParagraph} style={{textAlign: width<MD_SCREEN?"left":"left", fontSize:width<SM_SCREEN?15:""}}>
              <li>Sign up to be a trainer for Oslyn <b style={{fontWeight: 600}}>below</b>.</li>
              <li>Follow the steps in the Oslyn Trainer Manual email that you will receive. 
                If you don’t receive an email after signing up, please email <a href="mailto:marketing@oslyn.io">marketing@oslyn.io</a>.</li>
              <li>The trainer with the most number of recordings submitted by the deadline will win the contest. 
                If there is a tie, the winner will be randomly selected.</li>
            </ol>
          </div>
          <Typography component="h5" align="center" color="textSecondary" className={classes.giveawaySectionDetails}
            style={{textAlign: width<MD_SCREEN?"center":"center", fontSize:width<SM_SCREEN?10:"", paddingBottom: 20}}>
            Giveaway contest closes on December 10, 2020 at 11:59 PM EST. 
            The winner will be contacted via Instagram direct message or email to receive the electronic Uber Eats gift card. 
            Open to Canadian residents only. By entering, entrants confirm they are 18+ years of age. To be eligible, 
            please refrain from editing your recordings and using effects. 
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} style={{paddingRight: width >= MD_SCREEN ? 100 : 20}}>
          <img src={giveAwayContest} alt="Oslyn Phone" style={{
              marginLeft: "auto", marginRight: "auto", display: "block",
              width: width<440?435-(440-width):435, 
              height: width<440?300-(440-width)*(300/440):300,
              marginTop: width >= MD_SCREEN? 150 : 50, marginBottom: width >= MD_SCREEN? 150 : 50}}/>
        </Grid>
          </Grid>*/}
      <Grid container style={{width: "100%"}} className={classes.featureSection}>
        <Grid item xs={1} sm={2} md={1} lg={1} xl={1} style={{display: width<LG_SCREEN && width>MD_SCREEN?"none":"inherit"}}/>
        <Grid item xs={12} sm={8} md={5} lg={3} xl={3} style={{paddingRight: width<SM_SCREEN?10:"", paddingLeft: width<SM_SCREEN?10:""}}>
          <Container maxWidth="md" style={{
              paddingTop: width>LG_SCREEN?200:width>MD_SCREEN?100:80, 
              paddingBottom: width>LG_SCREEN?200:width>MD_SCREEN?100:80, 
              paddingLeft: width<LG_SCREEN && width>MD_SCREEN?60:""
            }}>
            <Typography component="h1" variant="h2" align="left" color="textPrimary" className={classes.featureSectionTitle}
                style={{textAlign: "center", fontSize:width<SM_SCREEN?30:""}} gutterBottom>
              How can I help?
            </Typography>
            <Typography component="h5" align="center" color="textSecondary" 
              style={{fontSize: width>LG_SCREEN?17:15}} className={classes.headerLine2}>
                We are looking for trainers to help provide recordings of themselves singing and playing worship songs on guitar. 
                If you like to play worship songs on guitar during your free time or you’re a worship team leader/member who practices regularly, 
                then you can help train Oslyn while jamming or practicing without dedicating extra time.
            </Typography>
            <Typography component="h5" align="center" color="textSecondary" 
              style={{fontSize: width>LG_SCREEN?17:15, paddingTop: 20}} className={classes.headerLine2}>
                All you need to do is log into an easy-to-use webpage and record yourself while playing. 
                Onboarding training will be provided when you sign up as an Oslyn trainer.
            </Typography>
          </Container>
        </Grid>
        <Grid item xs={false} lg={1} xl={1}/>
        <Grid item xs={12} sm={12} md={7} lg={7} xl={6} style={{
            display: "flex", justifyContent: "center", background: width<MD_SCREEN?"#3d3f4b":"inherit", 
            paddingRight: width<XL_SCREEN && width>SM_SCREEN?30:0,
            paddingLeft: width<XL_SCREEN && width>SM_SCREEN?30:0
          }}>
          <img src={oslynStudioScreenshot} alt="Oslyn Studio ScreenShot" style={{
            width: "100%", height: "100%", objectFit: "contain",
            paddingTop: width>MD_SCREEN?50:20, paddingBottom:width>MD_SCREEN?0:20}}/>
        </Grid>
      </Grid>
      <Grid container style={{width: "100%"}} className={classes.whatsinitformesection}>
        <Container maxWidth="md" style={{paddingTop: 100, paddingBottom: 100}}>
            <Typography component="h1" variant="h2" align="left" color="textPrimary" gutterBottom 
                style={{textAlign: "center", fontSize: width>SM_SCREEN?50:30}} className={classes.headerLine1}>
                What's in it for me?
            </Typography>
            <Typography component="h5" align="left" color="textSecondary" 
                style={{fontSize: width>SM_SCREEN?18:15, paddingTop:10}} className={classes.headerLine2}>
                <ul>
                  <li style={{paddingBottom:10}}>
                    <b style={{color: "#ff8a65"}}>Become a pioneer</b>: Contribute to the development of AI application in music, 
                    helping musicians enhance their live performances and churches with limited resources elevate their worship experience.
                  </li>
                  <li style={{paddingBottom:10}}>
                    <b style={{color: "#47dcff"}}>Join a music community</b>: Meet other worship music enthusiasts. 
                    You can share and learn from each other the latest worship songs, or best practices and tips to grow as a musician. 
                  </li>
                  <li>
                    <b style={{color: "#ffc050"}}>Join the Oslyn family</b>: 
                    Meet and work alongside the Oslyn team to achieve this exciting goal together. 
                    We promise to make it fun and worthwhile!
                  </li>
                </ul>
            </Typography>
        </Container>
      </Grid>
      <Grid container style={{width: "100%"}} className={classes.featureSection} ref={featuresSectionRef}>
        <Container maxWidth="sm" style={{paddingTop: 100, paddingBottom: 40}}>
          <Typography component="h1" variant="h2" align="center" color="textPrimary" className={classes.featureSectionTitle}
            style={{textAlign: width<MD_SCREEN?"center":"center", fontSize:width<SM_SCREEN?30:""}} gutterBottom>
            Sign up to be a trainer for Oslyn
          </Typography>
          <Typography component="h5" align="center" color="textSecondary" className={classes.featureSectionParagraph}
            style={{textAlign: width<MD_SCREEN?"center":"center", fontSize:width<SM_SCREEN?15:""}}>
            We will email you instructions on what to record and how to submit your recording(s). The recording you provide is fully 
            confidential and will only be used for AI training purposes.
          </Typography>
          <Subscribe isOslynTeacher={true}/>
          <Typography component="h5" align="left" color="textSecondary" className={classes.downloadBetaSecionParagraph} 
              style={{textAlign: "center", fontSize:width<SM_SCREEN?9:10}}>
            By becoming a trainer, you agree to receive emails from Oslyn. You can unsubscribe anytime by updating your email preferences via a link in the footer of our emails.
          </Typography>
        </Container>
      </Grid> 
  </div>
  )
}