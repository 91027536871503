import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

import { Button, IconButton, Typography, FormControl, TextField, Checkbox, FormControlLabel,
  DialogTitle as MuiDialogTitle, DialogContent, Dialog, CircularProgress } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownwardOutlined'

import ozzyOtter from '../../img/ozzy-01.png'
import ozzySadOtter from '../../img/ozzy-error-03.png'

import { API } from 'aws-amplify'
import { AWSconfigure } from '../../common/Configure'

const styles = theme => ({
  subscribeSectionButton: {
    color: "white",
    borderColor: "white",
    padding:10,
    paddingRight: 40, paddingLeft: 40,
    borderRadius: 25,
    "&:hover": {
      color: "#ff8a65",
      borderColor: "#ff8a65",
    },
    transition: "color 0.5s ease, border-color 0.5s ease"
  },
  disabledSubscribeButton: {
    color: "#bdbdbd !important",
    borderColor: "#bdbdbd !important",
    background: "#424242 !important"
  },
  buttonProgress: {
    color: "#ffc050",
    position: 'relative',
    left: '-68px',
    marginLeft: -12,
    marginTop: 10,
  },

  // TextBoxes
  cssLabel: {
    color : '#eeeeee' // changes the lable color //#ff8a65
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': { // has something to do with the label when filling it out
      borderColor: `#ff8a65 !important`,
    }
  },
  cssFocused: {
    borderColor: '#ff8a65 !important'
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#ff8a65 !important'
  },
})

const SM_SCREEN = 600
const ERR_MSG_TIMEOUT = 5000

const PhoneOSCheckbox = withStyles({
  root: {
    color: "#eeeeee",
    '&$checked': {
      color: "#eeeeee", //rgba(35,35,45,1)
      "& .MuiIconButton-label": {
        position: "relative",
        zIndex: 0,
      },
      "& .MuiIconButton-label:after": {
        content: '""',
        left: 4,
        top: 4,
        height: 15,
        width: 15,
        position: "absolute",
        backgroundColor: "#ff8a65", //#ff8a65
        zIndex: -1
      },
    },
    "&$disabled": {
      color: "#9e9e9e",
      "& .MuiIconButton-root.Mui-disabled": {
        position: "relative",
        zIndex: 0,
      },
      "& .MuiIconButton-root.Mui-disabled:after": {
        content: '""',
        left: 4,
        top: 4,
        height: 15,
        width: 15,
        position: "absolute",
        backgroundColor: "#9e9e9e", //#ff8a65
        zIndex: -1
      },
    }
  },
  checked: {},
  disabled: {},
})((props) => <Checkbox color="default" {...props} />)

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class Subscribe extends Component {
  constructor(props) {
    super(props)
    this.state = {
      width: 0, height: 0,
      isOslynTeacher: props.isOslynTeacher || false,
      
      email: "",
      name:"",
      downloadIOS: false,
      downloadAndroid: false,

      savedDownloadIOS: false,
      savedDownloadAndroid: false,

      errorMsgName:"",
      errorMsgEmail:"",
      errorMsgPopup:"",

      subscribeThankyouOpen: false,
      loading: false
    }

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    console.log(props.isOslynTeacher)
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({width: window.innerWidth, height: window.innerHeight})
  }

  /**
    Prevent SQL or js scripting attacks
   */
  checkInputField(){
    let name = this.state.name
    let email = this.state.email

    console.log(this.state.downloadAndroid && this.state.downloadIOS)
    
    // Check violations on Name
    if (name === "" || name === null) {
      this.setState({errorMsgName: "Name cannot be empty!"})
      setTimeout(() => { this.setState({errorMsgName: ""}) }, ERR_MSG_TIMEOUT)
      return false
    } else {
      let format = /[`!@#$%^&*()_+=\[\]{};':"\\|,.<>\/?~]/;
      let containsSpecialChar = format.test(name)
      if (containsSpecialChar) {
        this.setState({errorMsgName: "Please do not use special characters in your name!"})
        setTimeout(() => { this.setState({errorMsgName: ""}) }, ERR_MSG_TIMEOUT)
        return false
      }
    }

    // Check violations on Email
    if (email === "" || email === null) {
      this.setState({errorMsgEmail: "Email cannot be empty!"})
      setTimeout(() => { this.setState({errorMsgEmail: ""}) }, ERR_MSG_TIMEOUT)
      return false
    } else {
      let format = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      let isEmailFormat = format.test(email)
      if (!isEmailFormat) {
        this.setState({errorMsgEmail: "Please use a valid Email!"})
        setTimeout(() => { this.setState({errorMsgEmail: ""}) }, ERR_MSG_TIMEOUT)
        return false
      }

      // If we wnat to Beta test Android, we need a gmail account.
      let gmailFormat = /^\w+([\.-]?\w+)*@gmail\.com$/
      if (this.state.downloadAndroid && !this.state.isOslynTeacher) {
        if (!gmailFormat.test(email)) {
          this.setState({errorMsgEmail: "Please use a valid Gmail Address OR uncheck the Android Beta test option"})
          setTimeout(() => { this.setState({errorMsgEmail: ""}) }, ERR_MSG_TIMEOUT)
          return false
        }
      }
    }

    if (!this.state.downloadAndroid && !this.state.downloadIOS && !this.state.isOslynTeacher) {
      this.setState({errorMsgEmail: "Please choose a device!"})
      setTimeout(() => { this.setState({errorMsgEmail: ""}) }, ERR_MSG_TIMEOUT)
      return false
    }

    return true
  }


  changeName(event){ 
    if (this.state.errorMsgName !== "") this.setState({errorMsgName:""})
    this.setState({name: event.target.value})
  }
  changeEmail(event){ 
    if (this.state.errorMsgEmail !== "") this.setState({errorMsgEmail:""})
    this.setState({email: event.target.value}) 
  }
  toggleDownloadIOS(){ this.setState({downloadIOS: !this.state.downloadIOS})}
  toggleDownloadAndroid(){ this.setState({downloadAndroid: !this.state.downloadAndroid, errorMsgEmail: ""}) }
  async submitSubscription() {
    this.setState({loading: true})
    var isOK = this.checkInputField()

    if (isOK) {
      var data = {error: "could not find isOslynTeacher."}
      if (!this.state.isOslynTeacher) {
        let params = {
          body: {
            email: this.state.email,
            name: this.state.name,
            android: this.state.downloadAndroid,
            ios: this.state.downloadIOS
          }
        }
        AWSconfigure("WEBAPP")
        data = await API.post('oslynWebappRestV1', '/beta/subscribe', params)
      } else {
        let params = {
          body: {
            email: this.state.email,
            name: this.state.name
          }
        }

        AWSconfigure("WEBAPP")
        data = await API.post('oslynWebappRestV1', '/train/invite2', params)
      }
      
      console.log(data)

      if ('error' in data) {
        if (typeof data.error === 'string' || data.error instanceof String)
          this.setState({errorMsgPopup: data.error})
        else {
          let error = JSON.stringify(data.error).slice(1,-1)
          if (error) this.setState({errorMsgPopup: error})
          else this.setState({errorMsgPopup: "500: Unknown server error!"})
        }
      } else { this.setState({errorMsgPopup:""}) }

      console.log("errorMsgPopup ", this.state.errorMsgPopup)
      
      this.setState({savedDownloadAndroid: this.state.downloadAndroid, savedDownloadIOS: this.state.downloadIOS})
      this.openSubscribeThankyou()
      this.setState({name: "", email: "", errorMsgName: "", errorMsgEmail: "", downloadIOS: false, downloadAndroid: false, loading: false})
    } else {
      console.log("failed textfield checks!")
      this.setState({loading: false})
    }
  }
  openSubscribeThankyou(){ this.setState({subscribeThankyouOpen: true}) }
  closeSubscribeThankyou() { this.setState({subscribeThankyouOpen: false}) }

  render() {
    const { classes } = this.props

    return (<div>
      <div>
        <FormControl style={{width: "100%", paddingRight: this.state.width<SM_SCREEN?10:50, paddingLeft: this.state.width<SM_SCREEN?10:50}}>
          <TextField
            label="Name"
            color="secondary"
            className={classes.textField}
            value={this.state.name}
            onChange={this.changeName.bind(this)}
            margin="normal"
            variant="outlined"
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              },
              inputMode: "numeric",
              style: { color: "white" }
            }}
            error={this.state.errorMsgName !== ""}
            helperText={this.state.errorMsgName}
          />
          <TextField
            label="Email"
            color="secondary"
            className={classes.textField}
            value={this.state.email}
            onChange={this.changeEmail.bind(this)}
            margin="normal"
            variant="outlined"
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              },
              inputMode: "numeric",
              style: { color: "white" }
            }}
            error={this.state.errorMsgEmail !== ""}
            helperText={this.state.errorMsgEmail}
          />
          {this.state.isOslynTeacher?"":<FormControlLabel style={{paddingLeft: 5}}
            control={<PhoneOSCheckbox checked={this.state.downloadAndroid} onClick={this.toggleDownloadAndroid.bind(this)}  name="subscribeAndroidCheckbox" />}
            label={<Typography style={{color: "white"}} >
              Android
            </Typography>}
          />}
          {this.state.isOslynTeacher?"":<FormControlLabel style={{paddingLeft: 5}} disabled={true}
            control={<PhoneOSCheckbox checked={this.state.downloadIOS} onClick={this.toggleDownloadIOS.bind(this)}  name="subscribeIOSCheckbox" />}
            label={<Typography style={{color: "#9e9e9e"}} >
              iOS <small>Coming Soon!</small>
            </Typography>}
          />}
        </FormControl>
        <div style={{display:"flex", justifyContent:"center", paddingTop:50}}>
          <Button variant="outlined" color="primary" onClick={this.submitSubscription.bind(this)} 
            className={classes.subscribeSectionButton} disabled={this.state.loading}
            classes={{disabled: classes.disabledSubscribeButton}}>
              Submit
          </Button>
          {this.state.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </div>
      <Dialog onClose={this.closeSubscribeThankyou.bind(this)} aria-labelledby="customized-dialog-title" open={this.state.subscribeThankyouOpen} scroll="body">
        <DialogTitle id="customized-dialog-title" onClose={this.closeSubscribeThankyou.bind(this)}
          style={{display:"flex", justifyContent:"flex-end", backgroundColor: this.state.errorMsgPopup?"#ffc7a2":"#84f2e7"}}>
          {" "}
        </DialogTitle>
        <DialogContent style={{background: this.state.errorMsgPopup?"linear-gradient(#ffc7a2 0%, #fd7289 100%)":"linear-gradient(#84f2e7 0%, #3cd0c2 100%)", paddingBottom: 35}}> {/** #63e1ff */}
          <Typography gutterBottom style={{display: "flex", justifyContent: "center"}}>
            <img src={this.state.errorMsgPopup?ozzySadOtter:ozzyOtter} style={{width: this.state.width-200, maxWidth: 200, height: this.state.width-200, maxHeight: 200, position:"relative", 
                top: this.state.width<SM_SCREEN?0:-10}} alt="ozzy the otter" />
          </Typography>
          <Typography component="h1" variant="h2" align="center">
            {this.state.errorMsgPopup?"Opps, something's wrong!":"Check your inbox!"}
          </Typography>
          {!this.state.errorMsgPopup?
            this.state.isOslynTeacher?
            <div style={{paddingTop: 30}}>
              <Typography gutterBottom style={{textAlign:"center"}}>
                Thank you for becoming an Oslyn trainer! You will receive an email with instructions on what to record and how to submit your recording(s) momentarily. 
              </Typography>
            </ div>:
            <div>
              <div style={{display: "flex", justifyContent: "center", paddingTop:30, paddingBottom: 30}}>
                <div style={{paddingTop:10, paddingBottom:2, paddingRight:10, paddingLeft:10, border:"solid", borderRadius:30, borderWidth: 1, borderColor: "#1fadcf"}}>
                  <ArrowDownwardOutlinedIcon style={{color: "#1fadcf"}}/>
                </div>
              </div>
              <Typography gutterBottom style={{textAlign:"center"}}>
                Thank you for signing up to download Oslyn and
                enrolling in the testing programme for {(this.state.savedDownloadAndroid && this.state.savedDownloadIOS)?
                  <b>Android and iOS</b>:this.state.savedDownloadAndroid?
                  <b>Android</b>:<b>iOS</b>
                }!
              </Typography>
              <Typography gutterBottom style={{textAlign:"center"}}>
                You will receive an email with a link to download Oslyn within 24 hrs.
              </Typography>
            </div>:
            <div>
              <Typography gutterBottom style={{textAlign:"center", paddingTop: 40}}>
                {this.state.errorMsgPopup}
              </Typography>
              <Typography gutterBottom style={{textAlign:"center"}}>
                Please contact us at <a href="mailto:support@oslyn.io">support@oslyn.io</a>
              </Typography>
            </div>
          }
        </DialogContent>
      </Dialog>
    </div>)
  }
}

export default withStyles(styles, { withTheme: true })(Subscribe)