import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { Auth, API, graphqlOperation } from 'aws-amplify'

import { Box, Typography, Container, Grid, Paper, Link,
  TableContainer, Table, TableHead, TableRow, TableCell, 
  TableBody, Checkbox, Button, Fab, Zoom, TextField,
  Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, IconButton} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'

import Sound from './SoundLibrary/Sound'
import { AWSconfigure } from '../../common/Configure'

const _ISPROD_ = window.location.host === "oslyn.io"

const styles = theme => ({
  appBarSpacer: theme.mixins.toolbar,
  root: {
    flexGrow: 1,
    backgroundColor: "rgba(101,31,255,1)",
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(4),
    right: theme.spacing(6),
  },
  fabColor: {
    color: theme.palette.common.white, //"#23232d", // black
    backgroundColor: "#ff8a65", // orange
    transition: "color 0.8s ease, background-color 0.8s ease, transform 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important",
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: "#651fff", // purple
    },
  },
  deleteButton: {
    marginLeft: 5,
    borderRadius: 4,
    color: theme.palette.common.white,
    backgroundColor: "#d32f2f", // 700 red
    transition: "color 0.3s ease, background-color 0.3s ease, transform 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important",
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: "#b71c1c", // 900 red
    },
  }
})

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://oslyn.io">
        Oslyn.io
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

class SoundLibrary extends Component {
  constructor(props) {
    super(props)
    this.state = { 
      height: 0, rows: [],
      open: false,
      
      user: null,
      libraryTitle: "",

      soundID: _ISPROD_ ? null : "c96fe3c7-665d-4351-a1e0-4067b3d78bfe"  // USE null once dev is done // "c96fe3c7-665d-4351-a1e0-4067b3d78bfe"
    }
    
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  removeSoundID() { this.setState({soundID: null}) }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
    this.getUser()
    this.getSoundLibraryInfo()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({width: window.innerWidth, height: window.innerHeight})
  }

  async getSoundLibraryInfo() {
    AWSconfigure("WEBAPP")
    let rawCount = await API.get('oslynWebappRestV1', '/oslyn/sound/count')

    let query = `
    query listSounds {
      listOslynSounds (limit: ${rawCount.data.Count+1}) {
        items {
          id title audioOwner isActive
        }
      }
    }`

    AWSconfigure("WEBAPP")
    let data = await API.graphql(graphqlOperation(query))
    console.log(data)
    if (data) {
      if (data.data.listOslynSounds) {
        let items = data.data.listOslynSounds.items
        let tempRows = []
        for (let i in items) {
          tempRows.push({
            id: items[i].id,
            name: items[i].title,
            email: items[i].audioOwner,
            isActive: items[i].isActive
          })
        }

        this.setState({rows: tempRows})
        console.log(tempRows)
      } else { alert("Not connected to AWS! Talk to Dom!") }
    } else { alert("Could not get OslynSound count! Talk to Dom!")}
  }

  async postNewSound() { 
    if (this.state.user && this.state.libraryTitle && this.state.libraryTitle !== "") {
      let params = {
        body: {
          title: this.state.libraryTitle,
          email: this.state.user
        }
      }
      AWSconfigure("WEBAPP")
      let data = await API.post('oslynWebappRestV1', '/oslyn/create-sound', params)
      if ('error' in data) { console.log(data.error); alert("Oh no, error! Click OK. Right-click > Inspect > Console. Take a screenshot") }
      else {
        console.log(data)
        if (data.soundID) {
          this.setState({soundID: data.soundID, open: false})
        } else { alert(`soundID not found! ${data.soundID}`) }
      }
    } else { alert(`user and/or title not found! ${this.state.user}`) }
    
  }
  handleClickOpen() { this.setState({ open: true }) }
  handleClose() { this.setState({ open: false }) }
  async getUser() { 
    let user = await Auth.currentAuthenticatedUser()
    if (user)
      this.setState({ user: user.attributes.email })
    console.log(user.attributes.email)
  }

  render() {
    const { classes } = this.props

    return (<div style={{background:"#fafafa"}}>
      <div className={classes.appBarSpacer} />
      { this.state.soundID? <Sound soundID={this.state.soundID} goBack={this.removeSoundID.bind(this)}/>:
      <Container maxWidth="lg" className={classes.container} style={{minHeight: this.state.height-64}}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="a dense table">{/*size="small"*/}
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell align="right">Name</TableCell>
                    <TableCell align="center">Audio Owner</TableCell>
                    <TableCell align="right">Active?</TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.rows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell align="right">{row.name}</TableCell>
                      <TableCell align="center">{row.email}</TableCell>
                      <TableCell align="right" padding="checkbox">
                        <Checkbox disabled checked={row.isActive} />
                      </TableCell>
                      <TableCell align="right" style={{display:"flex"}}>
                        <IconButton className={classes.deleteButton}>
                          <DeleteIcon />
                        </IconButton>
                        <Button className={classes.fabColor}
                          onClick={() => {this.setState({soundID: row.id})}}
                        >Edit</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Box pt={4}>
          <Copyright />
        </Box>
      </Container>}
      <Zoom in={!this.state.soundID} timeout={{enter: 500, exit: 500}}>
        <Fab className={clsx(classes.fab, classes.fabColor)}
          onClick={this.handleClickOpen.bind(this)}
        ><AddIcon /></Fab>
      </Zoom>
      <Dialog open={this.state.open} onClose={this.handleClose.bind(this)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create Sound Library</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You can change the tile of your Sound Library at any time! <br />
            Please Note: Once a sound library is created, it cannot be deleted. 
          </DialogContentText>
          <TextField
            className="admin-input"
            autoFocus
            margin="dense"
            id="name"
            label="Sound Library Name"
            type="text"
            fullWidth
            value={this.state.libraryTitle}
            onChange={(e) => { this.setState({libraryTitle: e.target.value}) }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose.bind(this)} color="secondary">
            Cancel
          </Button>
          <Button onClick={this.postNewSound.bind(this)} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>)
  }

}

export default withStyles(styles, { withTheme: true })(SoundLibrary)